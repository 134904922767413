import { React, useEffect } from "react";
import { Form, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getTableData } from "../../hooks/fetchHook";
import Cookies from "universal-cookie";
import { refreshMyKey } from "../../actions/ui";
import moment from "moment/moment";

const cookies = new Cookies();
const { Option } = Select;

export const MeasurementUnitListBox = ({
  endpoint = "measurementUnit",
  label = "Unidad de medida",
  fk = false,
  handler,
  initialValue = "",
}) => {
  const dispatch = useDispatch();
  //   const [data, setData] = useState([]);
  const realm = useSelector((state) => state.auth.realm);
  const refreshKey = useSelector((state) => state.ui.refreshKey);

  const handlerInput = (e) => {
    handler({
      target: {
        id: fk ? endpoint+'Fk' : endpoint,
        value: e,
      },
    });
  };

  useEffect(() => {
    if (cookies.get(endpoint) === undefined) {
      dispatch(getTableData(endpoint, realm))
        .then(async(response) => {
          for (let prop of response) {
            prop.key = prop.id;
          }
          await cookies.set(endpoint, response, {
            path: "/",
            expires: new Date(moment().add(1, "m").format()),
          });
          dispatch(refreshMyKey(refreshKey + 1));
        })
        .catch((err) => {
          console.log(err);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Form.Item label={label} name={endpoint} initialValue={initialValue}>
      <Select showSearch id={endpoint } onChange={(e) => handlerInput(e)}>
        {cookies.get(endpoint) !== undefined
          ? cookies.get(endpoint).map((mu) => {
              return (
                <Option key={mu.key} value={fk ? mu.key : mu.name}>
                  {mu.name}
                </Option>
              );
            })
          : null}
      </Select>
    </Form.Item>
  );
};
