import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Switch, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { clearProductInfo, openScreen } from '../../actions/ui';
import { addOrUpdate, getTableData } from '../../hooks/fetchHook';

export const EditProductScreen = () => {

    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const isLoading = useSelector(state => state.ui.loading);
    const editingProduct = useSelector(state => state.ui.editingProduct);
    const isSidebarActive = useSelector(state => state.ui.sidebar);
    const realm = useSelector(state => state.auth.realm);
    const [formValues, setFormValues] = useState(editingProduct);
    const [form] = Form.useForm();

    useEffect(() => {
        dispatch(getTableData('product', realm))
            .then((response) => {
                setData(response)
            })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    const layout = {
        labelCol: { span: 10 },
        wrapperCol: { span: 100 }
    };

    const handdleInputChange = ({ target }) => {
        setFormValues({
            ...formValues,
            [target.id]: target.value,
            realmFk: realm
        })
    };

    const handleSave = async () => {
        try {
            await form.validateFields()
                .then(() => {
                    dispatch(addOrUpdate(formValues, 'product'))
                    dispatch(clearProductInfo())
                    form.resetFields()
                })
        }
        catch (err) { }
    }

    const handdleDiscart = () => {
        form.resetFields()
        dispatch(clearProductInfo())
        dispatch(openScreen('uiOpenListProduct'))
    };

    const handdleExistence = (e) => {
        setFormValues({
            ...formValues,
            existence: e
        })
    };

    const handdleVisible = (e) => {
        setFormValues({
            ...formValues,
            visible: e
        })
    };

    const handdleStatus = (e) => {
        setFormValues({
            ...formValues,
            status: e
        })
    };

    const handdleSelect = (e) => {
        setFormValues({
            ...formValues,
            freightChargeFk: e
        })
    }

    let children = [];
    const { Option } = Select;
    const defaultChildren = []

    for (const prop of data) {
        children.push(<Option key={prop.id} value={prop.id}>{prop.name}</Option>)
    }

    if (editingProduct.id !== "") {
        for (const prop of children) {
            if (editingProduct.id === prop.props.value) {
                defaultChildren.push(prop.props.children)
            }
        }
    }

    return (
        <div>
            <div className={isSidebarActive ? '_OCS-overlay' : '_overlay'}></div>
            <div className='_header-container3'>
                <div className={isSidebarActive ? '_OCS-screen-header' : '_screen-header'}>
                    <h2>Editar Producto</h2>
                </div>
                
 <div className={isSidebarActive ? "_btn2-1_" : "_btn2_"}>
          <div className="_btns-container2">
            <Button className="_btn-primary _ant-add-btn " onClick={handdleDiscart} type="primary">
              Volver
            </Button>
            </div>
          </div>
            </div>
            <div className={isSidebarActive ? '_OCS-ant-card-body _OCS-ant-card-form-group' : '_ant-card-body _ant-card-form-group'}>
                <div className='_ant-card-header'>Información Básica</div>
                <Form
                    form={form}
                    layout='vertical'
                    {...layout}
                    name="deviceInfoForm"
                    initialValues={{
                        id: [editingProduct.id],
                        name: [editingProduct.name],
                        code: [editingProduct.code],
                        ordinal: [editingProduct.ordinal],
                        freightChargeFk: defaultChildren,
                    }}
                >
                    <Form.Item
                        label="ID"
                        name="id"
                    >
                        <Input disabled size='large' onChange={handdleInputChange} id="id" />
                    </Form.Item>

                    <Form.Item
                        label="Nombre"
                        name="name"
                    >
                        <Input size='large' onChange={handdleInputChange} id="name" />
                    </Form.Item>

                    <Form.Item
                        label="Código"
                        name="code"
                    >
                        <Input size='large' onChange={handdleInputChange} id="code" />
                    </Form.Item>

                    <Form.Item
                        label="Ordinal"
                        name="ordinal"
                        rules={[
                            {
                                required: true,
                                message: 'Favor ingresar Ordinal',
                            },
                        ]}
                    >
                        <Input size='large' onChange={handdleInputChange} id="ordinal" />
                    </Form.Item>

                    <Form.Item
                        label="Estatus"
                        name="status"
                        valuePropName="checked"
                    >
                        <Switch defaultChecked={editingProduct.status} onChange={handdleStatus} checkedChildren="Activo" unCheckedChildren="Inactivo"></Switch>
                    </Form.Item>

                    <Form.Item
                        label="Producto Aliado"
                        name="freightChargeFk"
                    >
                        <Select
                            showSearch
                            onChange={handdleSelect}
                            allowClear
                        >{children}</Select>
                    </Form.Item>
                </Form>
            </div>
            <div className={isSidebarActive ? '_OCS-ant-card-body _OCS-ant-card-form-group' : '_ant-card-body _ant-card-form-group'}>
                <div className='_ant-card-header'>Organización</div>
                <div className='_ant-form-wrapper'>
                    <div>
                        <Form
                            form={form}
                            {...layout}
                            layout='vertical'
                            name="deviceInfoForm"
                            initialValues={{
                                formatter: [editingProduct.formatter]
                            }}
                        >
                            <Form.Item
                                name="existence"
                                label="Disponible"
                                valuePropName="checked"
                            >
                                <Switch defaultChecked={editingProduct.existence} onChange={handdleExistence} />
                            </Form.Item>

                            <Form.Item
                                label="Formateador"
                                name="formatter"
                            >
                                <Input size='large' onChange={handdleInputChange} id="formatter" />
                            </Form.Item>
                        </Form>
                    </div>

                    <div>
                        <Form
                            form={form}
                            {...layout}
                            layout='vertical'
                            name="deviceInfoForm"
                        >
                            <Form.Item
                                label="Visible"
                                name="visible"
                                valuePropName="checked"
                            >
                                <Switch defaultChecked={editingProduct.visible} onChange={handdleVisible} />
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </div>
            <div  className={
            isSidebarActive
              ? "_OCS-ant-card-body _OCS-ant-card-form-group _btn-save-container"
              : "_ant-card-body _ant-card-form-group _btn-save-container"
          }>
          <div >  
          <Button
              className="_btn-primary _ant-add-btn "
              loading={isLoading}
              onClick={handleSave}
              type="primary"
            >
              Guardar
            </Button>
            </div>
        </div>
        </div>
    )
};