


export const mapType = (entero) => {

    switch (entero) {
        case 0:
            return "Conduce"
            
        case 1:
            return "Factura"
            
        case 2:
            return "Comprobante De Consumo"
            
        case 3:
            return "Transferencia De Inventario"
            

        case 4:
            return "Recibo"
            
        case 5:
            return "Pedido"
            
        case 6:
            return " Levantamiento"
           
        default:
        return "Tipo desconocido"

        // code block
    }

}