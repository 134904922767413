import React, { useEffect, useState } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Redirect
} from "react-router-dom";
import { HomeScreen } from '../components/ui/HomeScreen';
import { PrivateRoute } from './PrivateRoute';
import { PublicRoute } from './PublicRoute';
import { AuthRouter } from './AuthRouter';
import { useDispatch, useSelector } from 'react-redux';
import jwt_decode from "jwt-decode";
import { setBusinessName, setPermissions, setRealm, setUsername } from '../actions/auth';
import Cookies from "universal-cookie";

const cookies = new Cookies();
// var usuario = cookies.get("usuario");
// var token = cookies.get("token");


export const AppRouter = () => {

    const dispatch = useDispatch();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const username = useSelector(state => state.auth.username);
    // const token = localStorage.getItem('token')
    const token = cookies.get('token',{ path: "/" })

    if(token){
        const decoded = jwt_decode(token);
        dispatch(setPermissions(decoded.permissions))
        dispatch(setRealm(decoded.realm))
        dispatch(setUsername(decoded.Name))
        dispatch(setBusinessName(decoded.customer_name))
    }
    
    useEffect(()=>{

        if(token !== undefined && token !== null){
            setIsLoggedIn(true)
        }
        else {
            setIsLoggedIn(false)
        }
    },[dispatch, setIsLoggedIn, username, token])

    return (
        <Router>
            <Switch>
                <PublicRoute
                    path='/login'
                    component={AuthRouter}
                    isAuthenticated={isLoggedIn}
                />

                <PrivateRoute
                    exact
                    isAuthenticated={isLoggedIn}
                    path='/'
                    component={HomeScreen}
                />

                <Redirect to='/login'></Redirect>
            </Switch>
        </Router>
    )
}