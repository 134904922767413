import React from 'react';
import { ScreenSelector } from '../../hooks/ScreenSelector';
import { Navbar } from './Navbar';
import { Sidebar } from './Sidebar';

export const HomeScreen = () => {
    
    return(
        <div>
            <Navbar/>
            <Sidebar/>
            <ScreenSelector/>
        </div>
    )
}