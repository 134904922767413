import React, { useState } from 'react';
import { Button, DatePicker, Form, Input, Switch,Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { clearNCFRangeInfo, openScreen } from '../../actions/ui';
import { addOrUpdate } from '../../hooks/fetchHook';
import moment from 'moment';

export const AddNCFRangeScreen = () => {

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.ui.loading);
    const editingNCFRange = useSelector(state => state.ui.editingNCFRange);
    const isSidebarActive = useSelector(state => state.ui.sidebar);
    const realm = useSelector(state => state.auth.realm);
    const [formValues, setFormValues] = useState(editingNCFRange);
    const [form] = Form.useForm();

    const layout = {
        labelCol: { span: 10 },
        wrapperCol: { span: 100 }
    };

    const handleInputchange = ({ target }) => {
        setFormValues({
            ...formValues,
            [target.id]: target.value,
            realmFk: realm
        })
    };

    const handleSave = async () => {
        try {
            await form.validateFields()
                .then(() => {
                    dispatch(addOrUpdate(formValues, 'ncfrange'))
                    form.resetFields()
                    console.log(formValues)
                })
        }
        catch (err) { }
    }

    const handleDiscart = () => {
        form.resetFields()
        dispatch(clearNCFRangeInfo())
        dispatch(openScreen('uiOpenListNCFRange'))
    };

    const handdleStatus = (e) => {
        setFormValues({
            ...formValues,
            status: e
        })
    };
   
    const handleDateUntil = (date) => {
        setFormValues({
            ...formValues,
            validUntil: date
        })
    };


    const handdleTypeNcf = (e) => {
        setFormValues({
            ...formValues,
            typeNcf: e
        }) 
    }

    return (
        <div>
            <div className={isSidebarActive ? '_OCS-overlay' : '_overlay'}></div>
            <div className='_header-container3'>
                <div className={isSidebarActive ? '_OCS-screen-header' : '_screen-header'}>
                    <h2>Crear NCF</h2>
                </div>
                <div className={isSidebarActive ? "_btn2-1_" : "_btn2_"}>
          <div className="_btns-container2">
            <Button className="_btn-primary _ant-add-btn " onClick={handleDiscart} type="primary">
              Volver
            </Button>
            </div>
          

                </div>
            </div>
            <div className={isSidebarActive ? '_OCS-ant-card-body _OCS-ant-card-form-group' : '_ant-card-body _ant-card-form-group'}>
                <div className='_ant-card-header'>Información Básica</div>
                <Form
                    form={form}
                    layout='vertical'
                    {...layout}
                    name="NCFRangeForm"
                >


                    <Form.Item
                        label="Nombre"
                        name="name"
                    >
                        <Input size='large' onChange={handleInputchange} id="name"/>
                    </Form.Item>
                    
                    {/* <Form.Item
                        label="Tipo NCF"
                        name="typeNcf"
                    >
                        <Input
                            id='typeNcf'
                            onChange={handleInputchange}
                            size='large'
                        />
                    </Form.Item> */}

                    <Form.Item
                                label="Tipo NCF"
                                name="typeNcf"
                            >
                                <Select 
                                    placeholder="Seleccionar tipo NCF" 
                                    // size='large' 
                                    onChange={handdleTypeNcf}
                                    allowClear
                                >
                                    <Select.Option value={1}>Crédito Fiscal</Select.Option>
                                    <Select.Option value={2}>Consumidor Final</Select.Option>
                                    <Select.Option value={14}>Régimen Especial</Select.Option>
                                    <Select.Option value={15}>Gubernamental</Select.Option>
                                </Select>
                            </Form.Item>



                    <Form.Item
                        label="Secuencia Inicial"
                        name="startSequence"
                        rules={[
                            {
                                required: true,
                                message: 'Favor ingresar secuencia válida',
                            },
                        ]}
                    >
                        <Input
                            id='startSequence'
                            onChange={handleInputchange}
                            size='large'
                            type="number"
                        />
                    </Form.Item>
                    
                    <Form.Item
                        label="Secuencia Final"
                        name="finalSequence"
                        rules={[
                            {
                                required: true,
                                message: 'Favor ingresar secuencia válida',
                            },
                        ]}
                    >
                        <Input
                            id='finalSequence'
                            onChange={handleInputchange}
                            size='large'
                            type="number"
                        />
                    </Form.Item>

                    <Form.Item
                        label="Válido Hasta"
                        name="validUntil"
                        rules={[
                            {
                                required: true,
                                message: 'Favor ingresar fecha válida',
                            },
                        ]}
                    >
                        <DatePicker
                            onChange={handleDateUntil}
                            format="YYYY-MM-DD HH:mm:ss"
                            showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                        />
                    </Form.Item>
                </Form>
            </div>
            <div className={isSidebarActive ? '_OCS-ant-card-body _OCS-ant-card-form-group' : '_ant-card-body _ant-card-form-group'}>
                <div className='_ant-card-header'>Informacion del Recipiente</div>
                <div className='_ant-form-wrapper'>
                    <div>
                        <Form
                            form={form}
                            {...layout}
                            layout='vertical'
                            name="deviceInfoForm"
                        >
                            <Form.Item
                                label="Estatus"
                                name="status"
                                valuePropName="checked"
                            >
                                <Switch defaultChecked checkedChildren="Activo" unCheckedChildren="Inactivo" onChange={handdleStatus}></Switch>
                            </Form.Item>

                            <Form.Item
                                label="Prefijo de Impuesto"
                                name="taxPrefix"
                            >
                                <Input
                                    id='taxPrefix'
                                    onChange={handleInputchange}
                                    size='large'
                                />
                            </Form.Item>
                        </Form>
                    </div>

                    <div>
                        <Form
                            form={form}
                            {...layout}
                            layout='vertical'
                            name="NCFRangeForm"
                        >
                            <Form.Item
                                label="Ordinal"
                                name="ordinal"
                                
                                rules={[
                                    {
                                        required: true,
                                        message: 'Favor ingresar ordinal',
                                    },
                                ]}
                            >
                                <Input size='large' onChange={handleInputchange} id="ordinal" type="number"/>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </div>
            <div  className={
            isSidebarActive
              ? "_OCS-ant-card-body _OCS-ant-card-form-group _btn-save-container"
              : "_ant-card-body _ant-card-form-group _btn-save-container"
          }>
          <div >  
          <Button
              className="_btn-primary _ant-add-btn "
              loading={isLoading}
              onClick={handleSave}
              type="primary"
            >
              Crear
            </Button>
            </div>
        </div>
        </div>
    )
};