import React from 'react';
import { useSelector } from 'react-redux';

export const Restricted = ({permission, children }) => {
    const permissionList = useSelector(state => state.auth.permissions)
    
    switch(permission){
        case 'Unidades de Medida':
            if(permissionList[permission] > 0){
                return <>{children}</>
            }
                
        // eslint-disable-next-line no-fallthrough
        case 'Manejadores de Producto':
            if(permissionList[permission] > 0){
                return <>{children}</>
            }
            
        // eslint-disable-next-line no-fallthrough
        case 'Usuarios':
            if(permissionList[permission] > 0){
                return <>{children}</>
            }
            
        // eslint-disable-next-line no-fallthrough
        case 'Customer':
            if(permissionList[permission] > 0){
                return <>{children}</>
            }
            
            // eslint-disable-next-line no-fallthrough
        case 'Deliverys':
            if(permissionList[permission] > 0){
                return <>{children}</>
            }
            
            // eslint-disable-next-line no-fallthrough
        case 'Producto':
            if(permissionList[permission] > 0){
                return <>{children}</>
            }
            
            // eslint-disable-next-line no-fallthrough
        case 'Document':
            if(permissionList[permission] > 0){
                return <>{children}</>
            }
            
            // eslint-disable-next-line no-fallthrough
        case 'NCF':
            if(permissionList[permission] > 0){
                return <>{children}</>
            }
            
            // eslint-disable-next-line no-fallthrough
        case 'Descuento':
            if(permissionList[permission] > 0){
                return <>{children}</>
            }
            
            // eslint-disable-next-line no-fallthrough
        case 'Historico de precio':
            if(permissionList[permission] > 0){
                return <>{children}</>
            }
            
            // eslint-disable-next-line no-fallthrough
        case 'Impuestos':
            if(permissionList[permission] > 0){
                return <>{children}</>
            }
            
            // eslint-disable-next-line no-fallthrough
        case 'Métodos de Pago':
            if(permissionList[permission] > 0){
                return <>{children}</>
            }
            
            // eslint-disable-next-line no-fallthrough
        default:
            return null
    }
};












