import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import 'antd/dist/antd.css';
import {
  AiOutlineCheckCircle,
  AiOutlineCloseCircle,
  AiOutlineMore,
} from 'react-icons/ai';
import { Popover, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getTableData } from '../hooks/fetchHook';
import { ExportData } from '../components/xlsx/ExportData';
import { formatExport, formatToTable } from '../helpers/columnsFormat';


export const ListDevicesTable = () => {
  const dispatch = useDispatch()
  const [data, setData] = useState([]);
  const refreshKey = useSelector(state => state.ui.refreshKey);
  const realm = useSelector(state => state.auth.realm)
  
  useEffect(() => {
    dispatch(getTableData('device', realm))
    .then((response) => {
      for (let prop of response) {
        prop.key = prop.id
      }
      setData(response)
    })
    .catch(err => {
      console.log(err)
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, refreshKey]);

  const columns = [
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: 150,
      sorter: (a, b) => a.description.length - b.description.length
    },
    {
      width: 150,
      title: 'Estatus',
      dataIndex: 'status',
      key: 'status',
      render:(text) => {
        if(text === true) {
          return <div style={{ color: '#04d182' }}>Activo <AiOutlineCheckCircle size='15' /></div>
        }
        else {
          return <div style={{ color: '#ff6b72' }}>Inactivo <AiOutlineCloseCircle size='15' /></div>
        }
      },
      filters: [
        {
          text: 'Activo',
          value: true,
        },
        {
          text: 'Inactivo',
          value: false,
        },
      ],
      onFilter: (value, record) => record.status === value,
    },
    {
      dataIndex: 'id',
      key: 'id',
      width: 70,
      render(id) {
        return (
          <Popover
            className='_more-btn'
            content={
              <>
                <p>ID: {id}</p>
              </>
            }
          >
            {
              <Button><AiOutlineMore size='20' /></Button>
            }
          </Popover>

        );
      }
    }
  ];
 
  return (
    <div>
      <Table
        locale={{
          triggerAsc: 'Click para ordernar ascendente',
          triggerDesc: 'Click para ordernar descendente',
          cancelSort: 'Click para cancelar orden',
        }}
        onChange={ () => formatToTable(columns)}
        dataSource={data}
        columns={columns}
        scroll={{x: 400}}
        footer={() => {
          return(
            <>
              <ExportData columns={formatExport(columns)} data={data} tableName={'devices'} />
            </>
          )
        }}
      />
    </div>
  )
}