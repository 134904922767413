import React from 'react';
import { useSelector } from 'react-redux';

import { ListDevicesTable } from '../../antTables/ListDevicesTable';

export const ListDeviceScreen = () => {
    const isSidebarActive = useSelector(state => state.ui.sidebar);
    
    return (
        <div >
            <div id='meId' className={isSidebarActive? '_OCS-overlay': '_overlay'}></div>
            <div className={isSidebarActive? '_OCS-screen-header':'_screen-header'}>
                <h2>Lista de Dispositivos</h2> 
            </div>
            <div className={isSidebarActive? '_OCS-ant-card-body':'_ant-card-body'}>
                <div className={isSidebarActive? '_OCS-table-responsive':'_table-responsive'}>
                    <ListDevicesTable/>
                </div>
            </div>
        </div>
    )
}