import {React, useEffect, useState} from "react";
import {Form, Select} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {getTableData} from "../../hooks/fetchHook";
import Cookies from "universal-cookie";

const cookies = new Cookies();

export const CustomerListBox = ({
                                    endpoint = "customer",
                                    label = "Cliente",
                                    fk = true,
                                    handler,
                                    initialValue = "",
                                    name = "customerFK"
                                }) => {
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const realm = useSelector((state) => state.auth.realm);

    const x = (e) => {
        handler({
            target: {
                id: fk ? endpoint + 'Fk' : endpoint,
                value: e,
            },
        });
    };

    useEffect(() => {
        if (cookies.get(endpoint) === undefined) {
            dispatch(getTableData(endpoint, realm))
                .then(async (response) => {
                    setData(response);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, [dispatch, endpoint, realm]);

    const {Option} = Select;

    return (
        <Form.Item label={label} name={endpoint} initialValue={initialValue}>
            <Select
                showSearch
                id={endpoint}
                onChange={(e) => x(e)}
                filterOption={(input, option) =>
                    option.children?.toLowerCase().includes(input.toLowerCase())
                }
            >
                {data.map((item) => (
                    <Option key={item.id} value={item.id}>
                        {item.name}
                    </Option>
                ))}
            </Select>
        </Form.Item>
    );
};