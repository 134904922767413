/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import 'antd/dist/antd.css';
import {
    AiOutlineCheckCircle,
    AiOutlineCloseCircle,
    AiOutlineMore,
    AiOutlineEdit,
} from 'react-icons/ai';
import { Popover, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { taxToEdit, openScreen } from '../actions/ui';
import { getTableData } from '../hooks/fetchHook';


import { ExportData } from '../components/xlsx/ExportData';

import { formatExport, formatImport, formatToTable } from '../helpers/columnsFormat';
import { Restricted } from '../hooks/useRestricted';


export const ListTaxTable = () => {
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const realm = useSelector(state => state.auth.realm)

    const ActionType = Object.freeze({
        VIEW_DETAILS: {
            label: 'Editar',
            icon: <><AiOutlineEdit /></>,
            handler(item) {
                dispatch(openScreen('uiOpenEditTax'))
                dispatch(taxToEdit(item))
            }
        },
    });

    useEffect(() => {
        dispatch(getTableData('tax', realm))
        .then((response) => {
          for (let prop of response) {
            prop.key = prop.id
            prop.actions = [ActionType.VIEW_DETAILS]
          }
          setData(response)
        })
        .catch(err => {
            console.log(err)
          })
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [dispatch]);

    const columns = [
         {
             title: 'ID',
             width: 80,
             dataIndex: 'id',
             key: 'id',
             fixed: 'left',
             sorter: (a, b) => a.id - b.id
         },
        {
            title: 'Nombre',
            width: 100,
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => a.name.length - b.name.length
        },
        {
            title: 'Código',
            width: 90,
            dataIndex: 'code',
            key: 'code',
            sorter: (a, b) => a.code.length - b.code.length
        },
        {
            title: 'Estatus',
            width: 100,
            dataIndex: 'status',
            key: 'status',
            render: (text) => {
                if (text === true) {
                    return <div style={{ color: '#04d182' }}>Activo <AiOutlineCheckCircle size='15' /></div>
                }
                else {
                    return <div style={{ color: '#ff6b72' }}>Inactivo <AiOutlineCloseCircle size='15' /></div>
                }
            },
            filters: [
                {
                    text: 'Activo',
                    value: true,
                },
                {
                    text: 'Inactivo',
                    value: false,
                },
            ],
            onFilter: (value, record) => record.status === value,
        },
        {
            key: 'actions',
            width: 70,
            render(actionList, row) {
                return (
                    <Popover
                        className='_more-btn'
                        content={
                            <>
                                {actionList.actions.map((definition,index) => <div key={index} className="_ops-option" onClick={() => definition.handler(row)}>{definition.icon} {definition.label}</div>)}
                            </>
                        }
                    >
                        {
                            <Button><AiOutlineMore size='20' /></Button>
                        }
                    </Popover>
                );
            }
        }
    ];

    return (
        <div>
            <Table
                locale={{
                    triggerAsc: 'Click para ordernar ascendente',
                    triggerDesc: 'Click para ordernar descendente',
                    cancelSort: 'Click para cancelar orden',
                }}
                onChange={ () => formatToTable(columns)}
                dataSource={data}
                columns={formatToTable(columns)}
                scroll={{ x: 400 }}
                footer={() => {
                  return(
                    <>
                      <Restricted permission={'Impuestos'}>
                    {/* <ImportData columns={formatImport(columns)} tableName={'tax'} /> */}
                      </Restricted>
                      <ExportData columns={formatExport(columns)} data={data} tableName={'tax'} />
                    </>
                  )
                }}
            />
        </div>
    )
}