import React, { useEffect, useState } from 'react';
import { message, Table } from 'antd';
import 'antd/dist/antd.css';
import {
  AiOutlineCheckCircle,
  AiOutlineCloseCircle,
//  AiOutlineMore,
  AiOutlineEdit,
  AiOutlineDelete,
  AiOutlineUserAdd,
  AiOutlineMobile
} from 'react-icons/ai';
//import { Popover, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { deliveryGroupDeliveryToEdit, deliveryGroupDeviceToEdit, deliveryGroupToEdit, refreshMyKey, openScreen } from '../actions/ui';
import { deleteAssociation, deleteGroup, expandedTableA, expandedTableB, getGroups } from '../hooks/fetchHook';
import { Restricted } from '../hooks/useRestricted';


import { Popover, Button } from 'antd';
import {AiOutlineMore} from 'react-icons/ai';
import { ExportData } from '../components/xlsx/ExportData';
import { ImportData } from '../components/xlsx/ImportData';
import { formatExport, formatImport, formatToTable } from '../helpers/columnsFormat';
import { getTableData } from '../hooks/fetchHook';

import Cookies from "universal-cookie";

const cookies = new Cookies();


export const ListDeliveryGroupTable = () => {
  const refreshKey = useSelector(state => state.ui.refreshKey)
  const realm = useSelector(state => state.auth.realm)
  const [expandedTableDataA, setExpandedTableDataA] = useState([])
  const [expandedTableDataB, setExpandedTableDataB] = useState([])
  const [customers, setCustomers] = useState([]);

  const DeleteExpandedTableA = Object.freeze({
    DELETE: {
      label: 'Eliminar',
      icon: <><AiOutlineDelete /></>,
      handler(item, type) {
        deleteAssociation(item, type, 'deliveryroutegroupdelivery')
          .then(() => {
            expandedTableA(item, 'deliveryroutegroupdelivery')
              .then(res => {
                for (let prop of res) {
                  prop.key = prop.entityId
                  prop.actions = [DeleteExpandedTableA.DELETE]
                }
                setExpandedTableDataA(res)
              })
              .catch(err => {
                console.log(err)
              })
          })
          .catch(err => {
            console.log(err)
          })
        dispatch(refreshMyKey(refreshKey + 1))

      }
    }
  })
  const DeleteExpandedTableB = Object.freeze({
    DELETE: {
      label: 'Eliminar',
      icon: <><AiOutlineDelete /></>,
      handler(item, type) {
        deleteAssociation(item, type, 'deliveryroutegroupdevice')
          .then(() => {
            expandedTableB(item, 'deliveryroutegroupdevice')
              .then(res => {
                for (let prop of res) {
                  prop.key = prop.deviceFk
                  prop.actions = [DeleteExpandedTableB.DELETE]
                }
                setExpandedTableDataB(res)
              })
              .catch(err => {
                console.log(err)
              })
          })
          .catch(err => {
            console.log(err)
          })
        dispatch(refreshMyKey(refreshKey + 1))
      }
    }
  });

  const handdleExpand = (expanded, record) => {
    formatToTable(columns)
    if (expanded === true) {
      expandedTableA(record, 'deliveryroutegroupdelivery')
        .then(res => {
          if (res !== undefined) {
            for (let prop of res) {
              prop.key = prop.entityId
              prop.actions = [DeleteExpandedTableA.DELETE]
            }
          }
          setExpandedTableDataA(res)
        })
        .catch(err => {
          console.log(err)
        })

      expandedTableB(record, 'deliveryroutegroupdevice')
        .then(res => {
          if (res !== undefined) {
            for (let prop of res) {
              prop.key = prop.deviceFk
              prop.actions = [DeleteExpandedTableB.DELETE]
            }
          }
          setExpandedTableDataB(res)
        })
        .catch(err => {
          console.log(err)
        })
    }
  };

  const ActionType = Object.freeze({
    VIEW_DETAILS: {
      label: 'Editar',
      icon: <><AiOutlineEdit /></>,
      handler(item) {
        dispatch(openScreen('uiOpenEditDeliveryRouteGroup'))
        dispatch(deliveryGroupToEdit(item))
      }
    },
    ASOCIATE_CUST: {
      label: 'Agregar Delivery',
      icon: <AiOutlineUserAdd />,
      async handler(item) {
        dispatch(openScreen('uiOpenAssociateDeliveryRouteGroupDelivery'))
        dispatch(deliveryGroupDeliveryToEdit(item))
      }
    },
    ASOCIATE_DEVICE: {
      label: 'Agregar Dispositivo',
      icon: <AiOutlineMobile />,
      handler(item) {
        dispatch(openScreen('uiOpenAssociateDeliveryRouteGroupDevice'))
        dispatch(deliveryGroupDeviceToEdit(item))
      }
    },
    DELETE: {
      label: 'Eliminar',
      icon: <><AiOutlineDelete /></>,
      handler(item) {
        dispatch(deleteGroup(item, 'deliveryroutegroup'))
        dispatch(refreshMyKey(refreshKey + 1))
      }
    }
  });

  const dispatch = useDispatch()
  const [data, setData] = useState([])

  useEffect(() => {

    if (cookies.get('customer') === undefined) {
      dispatch(getTableData('customer', realm))
          .then(async (response) => {
              setCustomers(response);
          })
          .catch((err) => {
              console.log(err);
          });
    }

    dispatch(getGroups('deliveryroutegroup', realm))
      .then((response) => {
        for (let prop of response) {
          prop.key = prop.id
          prop.actions = [ActionType.VIEW_DETAILS, ActionType.ASOCIATE_CUST, ActionType.ASOCIATE_DEVICE, ActionType.DELETE]
        }
        setData(response)
      })
      .catch(err => {
        message.error('Error al encontrar la data, error: ', err)
      })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshKey]);

  const columns = [
    {
       title: 'ID',
       width: 40,
       fixed: 'left',
       dataIndex: 'id',
       key: 'id',
       sorter: (a, b) => a.id - b.id,
     },
    {
      title: 'Nombre',
      width: 100,
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.length - b.name.length
    },
    {
      title: 'Descripcion',
      width: 150,
      dataIndex: 'description',
      key: 'description',
      sorter: (a, b) => a.description.length - b.description.length
    },
    {
      title: 'Estatus',
      width: 100,
      dataIndex: 'status',
      key: 'status',
      filters: [
        {
          text: 'Activo',
          value: true,
        },
        {
          text: 'Inactivo',
          value: false,
        },
      ],
      onFilter: (value, record) => record.status === value,
      render: (text) => {
        if (text === true) {
          return <div style={{ color: '#04d182' }}>Activo <AiOutlineCheckCircle size='15' /></div>
        }
        else {
          return <div style={{ color: '#ff6b72' }}>Inactivo <AiOutlineCloseCircle size='15' /></div>
        }
      },
    },
    {
      key: 'actions',
       width: 70,
       render(actionList, row) {
         return (
           <Restricted permission={'Deliverys'}>
             <Popover
               className='_more-btn'
               content={
                 <>
                   {actionList.actions.map((definition,index) => <div key={index} className="_ops-option" onClick={() => definition.handler(row)}>{definition.icon} {definition.label}</div>)}
                 </>
              }
             >
               {
                 <Button><AiOutlineMore size='20' /></Button>
               }
           </Popover>
          </Restricted>
         );
       }
     }
  ];

  const expandedRowRender = (row) => {
    const AColumns = [
      //  {
      //    title: 'Grupo',
      //    dataIndex: 'groupFk',
      //    key: 'group',
      //    width: 40,
      //    fixed: 'left',
      //  },
      // {
      //   title: "ID Delivery",
      //   dataIndex: "entityId",
      //   key: "entityId",
      //   width: 70,
      // },
      {
        title: 'Delivery',
        dataIndex: 'entity',
        key: 'entity',
        width: 70,
        render: (entity) => {
          var c = customers.find(c => c.id === entity.customerFk)
          if (c) return `(${c.name}) ${entity.label}`
          return `${entity.id} - ${entity.label}`
        }
      },
      {
        key: "actions",
        width: 70,
        render(actionList, row) {
          return (
            <Restricted permission={"Deliverys"}>
              <>
                {actionList.actions.map((definition,index) => (
                  <div
                  key={index}
                    className="_ops-option"
                    onClick={() => definition.handler(row, "TableA")}
                  >
                    {definition.icon} {definition.label}
                  </div>
                ))}
              </>
            </Restricted>
          );
        },
      },
    ];

    const BColumns = [
      //  {
      //    title: 'Grupo',
      //    dataIndex: 'groupFk',
      //    key: 'group',
      //    width: 40,
      //    fixed: 'left',
      //  },
      // {
      //   title: "ID dispositivo",
      //   dataIndex: "deviceFk",
      //   key: "devicefk",
      //   width: 70,
      // },
      {
        title: 'Dispositivo',
        dataIndex: 'device',
        key: 'device',
        width: 70,
        render: (device) => {
          return device.description
        }
      },
      {
        key: "actions",
        width: 70,
        render(actionList, row) {
          return (
            <Restricted permission={"Deliverys"}>
              <>
                {actionList.actions.map((definition,index) => (
                  <div
                  key={index}
                    className="_ops-option"
                    onClick={() => definition.handler(row, "TableB")}
                  >
                    {definition.icon} {definition.label}
                  </div>
                ))}
              </>
            </Restricted>
          );
        },
      },
    ];

    return (
      <div>
        <Restricted permission={'Deliverys'}>
          <div style={{display:'flex'}}>
            {row.actions.map((definition,index) => <div key={index} className="_ops-option" onClick={() => definition.handler(row)}>{definition.icon} {definition.label}</div>)}
          </div>
        </Restricted>
        <Table
          scroll={{ x: 400 }}
          title={() => "Deliverys Asociados"}
          bordered
          columns={AColumns}
          dataSource={expandedTableDataA}
          pagination={false}
        />{" "}
        <Table
          scroll={{ x: 400 }}
          title={() => "Dispositivos Asociados"}
          bordered
          columns={BColumns}
          dataSource={expandedTableDataB}
          pagination={false}
        />
      </div>
    );
  };

  return (
    <div>
      <Table
        locale={{
          triggerAsc: 'Click para ordernar ascendente',
          triggerDesc: 'Click para ordernar descendente',
          cancelSort: 'Click para cancelar orden',
        }}
        onChange={ () => formatToTable(columns)}
        dataSource={data}
        expandable={{ expandedRowRender }}
        onExpand={handdleExpand}
        columns={formatToTable(columns)}
        scroll={{ x: 400 }}
        footer={() => {
          return(
            <>
              <Restricted permission={'Deliverys'}>
                <ImportData columns={formatImport(columns)} tableName={'deliveryroutegroup'} />
              </Restricted>
              <ExportData columns={formatExport(columns)} data={data} tableName={'deliveryroutegroup'} />
            </>
            )
          }}
      />
    </div>
  )
}