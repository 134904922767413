import React, {useEffect, useState} from "react";
import {Table} from "antd";
import "antd/dist/antd.css";
import {
    AiOutlineMore,
    AiOutlineEdit,
    AiOutlineDelete,
    AiOutlineCheckCircle,
    AiOutlineCloseCircle,
} from "react-icons/ai";
import {Popover, Button} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {
    deletePrice,
    priceToEdit,
    openScreen,
    refreshMyKey,
} from "../actions/ui";
import {Restricted} from "../hooks/useRestricted";
import {getTableData} from "../hooks/fetchHook";
import moment from "moment";

import {ExportData} from "../components/xlsx/ExportData";
import {ImportData} from "../components/xlsx/ImportData";
import {
    formatExport,
    formatImport,
    formatToTable,
} from "../helpers/columnsFormat";


export const ListPriceTable = () => {
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const refreshKey = useSelector((state) => state.ui.refreshKey);
    const realm = useSelector((state) => state.auth.realm);

    const ActionType = Object.freeze({
        VIEW_DETAILS: {
            label: "Editar",
            icon: (
                <>
                    <AiOutlineEdit/>
                </>
            ),
            handler(item) {
                dispatch(openScreen("uiOpenEditPrice"));
                dispatch(priceToEdit(item));
            },
        },
        DELETE: {
            label: "Eliminar",
            icon: (
                <>
                    <AiOutlineDelete/>
                </>
            ),
            async handler(item) {
                await dispatch(deletePrice(item));
                dispatch(refreshMyKey(refreshKey + 1));
            },
        },
    });

    useEffect(() => {
        dispatch(getTableData("price", realm))
            .then((response) => {
                for (let prop of response) {
                    prop.key = prop.id;
                    prop.actions = [ActionType.VIEW_DETAILS, ActionType.DELETE];
                    prop.validUntil = moment
                        .parseZone(prop.validUntil)
                        .local()
                        .format("YYYY-MM-DD HH:mm:ss");
                    prop.validFrom = moment
                        .parseZone(prop.validFrom)
                        .local()
                        .format("YYYY-MM-DD HH:mm:ss");
                }
                setData(response);


            })
            .catch((err) => {

                console.log(err);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, refreshKey]);

    const columns = [
        {
            title: "ID",
            width: 70,
            fixed: "left",
            dataIndex: "id",
            key: "id",
            sorter: (a, b) => a.id - b.id,
        },
        {
            title: "Monto",
            width: 160,
            dataIndex: "amount",
            key: "amount",
            sorter: (a, b) => a.amount - b.amount,
        },
        // {
        //   title: "Tipo",
        //   width: 160,
        //   dataIndex: "type",
        //   key: "type",
        //   sorter: (a, b) => a.type - b.type,
        // },
        {
            title: "Zona de Precios",
            width: 160,
            dataIndex: "zone",
            key: "zone",
            sorter: (a, b) => a.zone - b.zone,
        },
        {
            title: "Producto",
            width: 90,
            dataIndex: "productName",
            key: "productName",
            sorter: (a, b) => a.productName - b.productName,
        },


        {
            title: "Unidad de Medida",
            width: 160,
            dataIndex: "measurementUnit",
            key: "measurementUnit",
            sorter: (a, b) => a.measurementUnit.length - b.measurementUnit.length,
        },
        {
            title: "Válido Desde",
            width: 150,
            dataIndex: "validFrom",
            key: "validFrom",
            sorter: (a, b) => a.validFrom.length - b.validFrom.length,
        },
        {
            title: "Válido Hasta",
            width: 150,
            dataIndex: "validUntil",
            key: "validUntil",
            sorter: (a, b) => a.validUntil.length - b.validUntil.length,
        },
        {
            title: "Estatus",
            width: 100,
            dataIndex: "status",
            key: "status",
            filters: [
                {
                    text: "Activo",
                    value: true,
                },
                {
                    text: "Inactivo",
                    value: false,
                },
            ],
            onFilter: (value, record) => record.status === value,
            render: (text) => {
                if (text === true) {
                    return (
                        <div style={{color: "#04d182"}}>
                            Activo <AiOutlineCheckCircle size="15"/>
                        </div>
                    );
                } else {
                    return (
                        <div style={{color: "#ff6b72"}}>
                            Inactivo <AiOutlineCloseCircle size="15"/>
                        </div>
                    );
                }
            },
        },
        {
            key: "actions",
            width: 70,
            render(actionList, row) {
                return (
                    <Restricted permission={"Historico de precio"}>
                        <Popover
                            className="_more-btn"
                            content={
                                <>
                                    {
                                        row.actions !== undefined
                                            ? actionList.actions.map((definition, index) => <div key={index}
                                                                                                 className="_ops-option"
                                                                                                 onClick={() => definition.handler(row)}>{definition.icon} {definition.label}</div>)
                                            : null
                                    }
                                </>
                            }
                        >
                            {
                                <Button>
                                    <AiOutlineMore size="20"/>
                                </Button>
                            }
                        </Popover>
                    </Restricted>
                );
            },
        },
    ];

    return (
        <div>
            <Table
                locale={{
                    triggerAsc: "Click para ordernar ascendente",
                    triggerDesc: "Click para ordernar descendente",
                    cancelSort: "Click para cancelar orden",
                }}
                onChange={() => formatToTable(columns)}
                dataSource={data}
                columns={formatToTable(columns)}
                scroll={{x: 400}}
                footer={() => {
                    return (
                        <>
                            <Restricted permission={"Historico de precio"}>
                                <ImportData
                                    columns={formatImport(columns)}
                                    tableName={"price"}
                                />
                            </Restricted>
                            <ExportData
                                columns={formatExport(columns)}
                                data={data}
                                tableName={"price"}
                            />
                        </>
                    );
                }}
            />
        </div>
    );
};
