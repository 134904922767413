import React from "react";
import { Excel } from "antd-table-saveas-excel";
import {  Button } from 'antd';
import { PrinterOutlined } from '@ant-design/icons';

export const ExportData = (columns, data, tableName) => {
  const handleClick = () => {
    const excel = new Excel();

    excel
    .addSheet("test")
    .setTHeadStyle({
      background: "FFFFFFFF",
    })
    .addColumns(columns.columns)
    .addDataSource(columns.data, {
      str2Percent: true,
    })
    .saveAs(`Data ${columns.tableName}.xlsx`);
  };
  return (
    <>
      <Button icon={<PrinterOutlined />} type="primary" className="_btn-primary _ant-add-btn" onClick={handleClick}> Exportar </Button>
    </>
  );
};
