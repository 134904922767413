import React, { useState } from 'react';
import { Button, Form, Input, Switch } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { clearWarehouseGroupInfo, openScreen } from '../../actions/ui';
import { addOrUpdate } from '../../hooks/fetchHook';


export const AddWarehouseGroup = () => {

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.ui.loading);
    const isSidebarActive = useSelector(state => state.ui.sidebar);
    const [formValues, setFormValues] = useState();
    const realm = useSelector(state => state.auth.realm);
    const [form] = Form.useForm();

    const layout = {
        labelCol: { span: 10 },
        wrapperCol: { span: 100 }
    };

    const handdleInputChange = ({target}) => {
        setFormValues({
            ...formValues,
            [target.id]: target.value,
            realmFk: realm
        })
    };

    const handleSave = async () => {
        try{
            await form.validateFields()
            .then(() => {
                dispatch(addOrUpdate(formValues, 'warehousegroup'))
                form.resetFields()
            })
        }
        catch(err) {}
    }

    const handdleDiscard = () => {
        form.resetFields()
        dispatch(clearWarehouseGroupInfo())
        dispatch(openScreen('uiOpenListWarehouseGroup'))
    };

    const handdleStatus = (e) => {
        setFormValues({
            ...formValues,
            status: e
        })
    };

    return (
        <div>
            <div className={isSidebarActive? '_OCS-overlay': '_overlay'}></div>
            <div className='_header-container3'>
                <div className={isSidebarActive? '_OCS-screen-header':'_screen-header'}>
                    <h2>Crear Grupo de Almacenes</h2>
                
                </div>
          <div className={isSidebarActive ? "_btn2-1_" : "_btn2_"}>
        <div className="_btns-container2">
          <Button className="_btn-primary _ant-add-btn " onClick={handdleDiscard} type="primary">
            Volver
          </Button>
          </div>
    
        </div>
            </div>
            <div className={isSidebarActive? '_OCS-ant-card-body _OCS-ant-card-form-group':'_ant-card-body _ant-card-form-group'}>
                <div className='_ant-card-header'>Información Básica</div>
                <Form
                    form={form}
                    layout='vertical'
                    {...layout}
                    name="WarehouseGroupForm"
                >
                    <Form.Item
                        label="Nombre"
                        name="name"
                    >
                        <Input size='large' onChange={handdleInputChange} id="name"/>
                    </Form.Item>

                    <Form.Item
                        label="Estatus"
                        name="status"
                        valuePropName="checked"
                    >
                        <Switch defaultChecked checkedChildren="Activo" unCheckedChildren="Inactivo" onChange={handdleStatus}></Switch>
                    </Form.Item>

                    <Form.Item
                        label="Descripción"
                        name="description"
                    >
                        <Input.TextArea rows={3} onChange={handdleInputChange} id="description"/>
                    </Form.Item>
                </Form>
            </div>
            <div  className={
            isSidebarActive
              ? "_OCS-ant-card-body _OCS-ant-card-form-group _btn-save-container"
              : "_ant-card-body _ant-card-form-group _btn-save-container"
          }>
        <div >
          <Button
            className="_btn-primary _ant-add-btn "
            loading={isLoading}
            onClick={handleSave}
            type="primary"
          >
            Crear
          </Button>
        </div>
      </div>
        </div>
    )
};