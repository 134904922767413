import {MapContainer, TileLayer, Marker, Popup, useMapEvents} from 'react-leaflet'
import {useSelector} from 'react-redux';
import {useState} from 'react';
import {useEffect} from 'react';


import L from 'leaflet';

const icon = L.icon({
    iconSize: [25, 41],
    iconAnchor: [10, 41],
    popupAnchor: [2, -40],
    iconUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-icon.png",
    shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png",
});


export const Map = () => {

    function canConvertToNumber(value: string): Boolean {
        return !isNaN(parseFloat(value));
    };

    const parseXML = (xmlStr) => {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(xmlStr, "application/xml");

        // Validar si el documento XML es válido
        if (xmlDoc.querySelector("parsererror")) {
            console.error("Error parsing XML");
            return null;
        }

        var longitude = xmlDoc.getElementsByTagName("GPS_longitude")[0]?.textContent || "0.0";
        var latitude = xmlDoc.getElementsByTagName("GPS_latitude")[0]?.textContent || "0.0";
        var accuracy = xmlDoc.getElementsByTagName("GPS_accuracy")[0]?.textContent || "0";

        if (isNaN(longitude))
            longitude = 0.0
        if (isNaN(latitude))
            latitude = 0.0
        if (isNaN(accuracy))
            accuracy = 0.0
        
        return {longitude, latitude, accuracy};
    }


    let documentDetails = useSelector(state => state.ui.documentDetails);
    console.log(documentDetails.xmlExtraField)

    let longitudeMap = 0.0;
    let latitudeMap = 0.0;
    let accuracyMap = 0.0;

    let gpsData = parseXML(documentDetails.xmlExtraField);
    if (gpsData) {
        console.log(`Longitude: ${gpsData.longitude}`);
        if (canConvertToNumber(longitudeMap))
            longitudeMap = parseFloat(gpsData.longitude);
        console.log(`Latitude: ${gpsData.latitude}`);
        if (canConvertToNumber(latitudeMap))
            latitudeMap = parseFloat(gpsData.latitude);
        console.log(`Accuracy: ${gpsData.accuracy}`);
        if (canConvertToNumber(accuracyMap))
            accuracyMap = parseFloat(gpsData.accuracy);
    } else {
        console.error("Failed to extract GPS data");
    }
    if (gpsData && (longitudeMap === 0.0 || latitudeMap === 0.0))
        gpsData = false;


    function Centrar() {

        return gpsData ? <Marker position={[latitudeMap, longitudeMap]} icon={icon}>
            <Popup>{documentDetails.customer.name}</Popup>

        </Marker> : null
    }


    return (
        <div className="Map">
            {gpsData ? (
                <>
                    <h2>Ubicación del documento</h2>

                    <p>Click en el mapa para ver la ubicación de donde fue declarado el documento</p>

                    <MapContainer
                        className="leaflet-container"
                        center={[latitudeMap, longitudeMap]}
                        zoom={18}
                        scrollWheelZoom={false}
                    >
                        <TileLayer
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        <Centrar/>
                    </MapContainer>
                </>
            ) : (
                <p> Este documento no tiene Ubicación</p>
            )}
        </div>
    );


};