import { Button, Modal, message, Upload, Divider } from "antd";
import React, { useState } from "react";
import { FileExcelOutlined, InboxOutlined } from "@ant-design/icons";
import {Excel} from "antd-table-saveas-excel";
import { addOrUpdateFromImport } from '../../hooks/fetchHook';
import { refreshMyKey } from '../../actions/ui';
import { useDispatch, useSelector } from 'react-redux';
import readXlsxFile from 'read-excel-file'
// import { permissions } from "../../helpers/permissions";
import moment from "moment";
const { Dragger } = Upload;

export const ImportData = (columns, tableName) => {
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [dataBruta, setDataBruta] = useState([])
  var refreshKey = useSelector(state => state.ui.refreshKey);
  // const [data, setData] = useState([])
  const realm = useSelector(state => state.auth.realm);
  const dispatch = useDispatch();


  const props = {
    name: 'file',
    multiple: false,
    action: 'https://run.mocky.io/v3/d2f1feb9-8fff-4c16-8971-f6b105844c87',
  
    onChange(info) {
      const { status } = info.file;
  
      if (status !== 'uploading') {
      }
  
      if (status === 'done') {
        message.success(`${info.file.name} archivo cargado con éxito.`);

        readXlsxFile(info.fileList[0].originFileObj).then((rows) => {
          setDataBruta(rows)
        })
      } else if (status === 'error') {
        message.error(`${info.file.name} error en la carga de archivos.`);
      }
    },
  
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };

  const showModal = () => {
    setVisible(true);
  };

  const formatData = async () => {
    var dat = []
    try {
      dat = dataBruta.slice(1).map(arreglo => {
        const obj = {};
        obj.realmFk = realm
        obj.id = 0
        columns.columns.map(columna => columna.key).forEach((element, index) => {
          var value;
          switch (element) {
            case "id":
              value = arreglo[index]
              if (value === null || value === "") {
                value = 0
              }
              break;
            case "permission":
              // value = arreglo[index].toString().toLowerCase().split(",");
              // value.forEach((valor) => {
              //   if (
              //     !permissions
              //       .map((permission) => permission.label.toLowerCase().trim())
              //       .includes(valor.trim())
              //   ) {
              //     throw new Error("Permisos no validos");
              //   }
              // });
              // value = value.map((per) => {
              //   permissions.forEach((permission) => {
              //     if (permission.label.toLocaleLowerCase() === per) {
              //       per = permission.value;
              //     }
              //   });
  
              //   return per;
              // });

              value = 0 
              break;
            case "singlePayment":
              if (arreglo[index].toLowerCase() === "si" ) {
                value = true;
              } else if (arreglo[index].toLowerCase() === "no") {
                value = false;
              } else {
                throw new Error("Pagos unicos no validos");
              }
              break;

              case "referenceUsed":
              if (arreglo[index].toLowerCase() === "utilizado" ) {
                value = true;
              } else if (arreglo[index].toLowerCase() === "no utilizado") {
                value = false;
              } else {
                throw new Error("Frecuencias de usos no validos");
              }
              break;

            case "itsWarehouse":
            case "status":
              if (arreglo[index].toLowerCase() === "activo" || arreglo[index].toLowerCase() === "disponible") {
                value = true;
              } else if (arreglo[index].toLowerCase() === "inactivo") {
                value = false;
              } else {
                throw new Error("Estatus no validos");
              }
              break;

            case "ordinal":
            case "customerFk":
              value = parseInt(arreglo[index]);
              break;
            
            case "validUntil":
            case "validFrom":
              value = moment(new Date(arreglo[index]))
              
              break;
            default:
              value = arreglo[index];
              break;
          }
            obj[element] = value;
        });
        return obj
      })
    } catch (error) {
      message.error(error.message);
    }
    return dat
  }

  const handleOk = async() => {
    
    try {
      setConfirmLoading(true);
      formatData().then(async(data) => {
        await dispatch(addOrUpdateFromImport(data, columns.tableName))
        dispatch(refreshMyKey(refreshKey + 1))     
      })

    } catch (error) {
      message.error(error.message);
    }
    setConfirmLoading(false);

  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handlePlantilla = () => {
    const excel = new Excel();
    excel.addSheet("plantilla").setTHeadStyle({
      background: 'FFFFFFFF',
    }).addColumns(columns.columns).saveAs(`Plantilla ${columns.tableName}.xlsx`);
  };

  return (
    <>
      <Button
        icon={<FileExcelOutlined />}
        className="_btn-primary _discard-btn"
        type="primary"
        onClick={showModal}
      >
        Importar
      </Button>
      <Modal
        title="Importar data desde excel"
        visible={visible}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        okText='Subir a la API'
        cancelText='Cancelar'
      >
        <Button type="dashed" block onClick={handlePlantilla}>Descargar plantilla</Button>
        <Divider />
        <Dragger {...props} maxCount={1} accept='.xlsx'>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Haga clic o arrastre el archivo a esta área para cargarlo</p>
          <p className="ant-upload-hint">Soporte para archivos .xlsx</p>
        </Dragger>
      </Modal>
    </>
  );
};
