import React from 'react';
import {Provider} from 'react-redux';

import { store } from './store/store';
import { AppRouter } from './routers/AppRouter.js';

export const HanddoApp = () => {
    return(
        <Provider store= {store}>
            <AppRouter/>
        </Provider>
    )
}