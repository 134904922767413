import React, { useEffect, useState } from 'react';
import { message, Table } from 'antd';
import 'antd/dist/antd.css';
import {
  AiOutlineCheckCircle,
  AiOutlineCloseCircle,
  AiOutlineMore,
  AiOutlineEdit,
  AiOutlineDelete
} from 'react-icons/ai';
import { Popover, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { customerToEdit, deleteCustomer, openScreen, refreshMyKey } from '../actions/ui';
import { Restricted } from '../hooks/useRestricted';
import { getTableData } from '../hooks/fetchHook';


import { ExportData } from '../components/xlsx/ExportData';
import { ImportData } from '../components/xlsx/ImportData';
import { formatExport, formatImport, formatToTable } from '../helpers/columnsFormat';
import { FilterPanel } from "../components/ui/FilterPanel";
import { getEmptyFilters } from '../helpers/getEmptyFilters';



export const ListCustomersTable = () => {

  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const refreshKey = useSelector(state => state.ui.refreshKey);
  const realm = useSelector(state => state.auth.realm)

  const filterFields = [
    {
      id: "name",
      name: "Nombre",
      type: "string",
    },
    {
      id: "code",
      name: "Código",
      type: "string",
    },
    {
      id: "rnc",
      name: "RNC",
      type: "string",
    },
    {
      id: "addressCode",
      name: "Código Dirección",
      type: "string",
    },
  ]
  const [filters, setFilters] = useState(getEmptyFilters(filterFields));

  

  const ActionType = Object.freeze({
    VIEW_DETAILS: {
      label: 'Editar',
      icon: <><AiOutlineEdit /></>,
      handler(item) {
        dispatch(openScreen('uiOpenEditCustomer'))
        dispatch(customerToEdit(item))
      }
    },
    DELETE: {
      label: 'Eliminar',
      icon: <><AiOutlineDelete /></>,
      handler(item) {
        dispatch(deleteCustomer(item))
        .then(() => {
          getTableData('customer', realm)
            .then(res => {
              if (res !== undefined) {
                for (let prop of res) {
                  prop.key = prop.id
                  prop.actions = [ActionType.VIEW_DETAILS, ActionType.DELETE]
                }
              }
              setData(res)
            })
            .catch(err => {
              console.log(err)
            })
        })
        .catch(err => {
          console.log(err)
        })
      dispatch(refreshMyKey(refreshKey + 1))
      }
    }
  });

  useEffect(() => {
    dispatch(getTableData('customer', realm))
      .then((response) => {
        for (let prop of response) {
          prop.key = prop.id
          prop.actions = [ActionType.VIEW_DETAILS, ActionType.DELETE]
        }
        setData(response)
      })
      .catch(err => {
        message.error('Error al encontrar la data, error: ', err)
      })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, refreshKey, filters]);

  const columns = [
     {
       title: 'ID',
       width: 60,
       fixed: 'left',
       dataIndex: 'id',
       key: 'id',
       sorter: (a, b) => a.id - b.id
     },
    {
      title: 'Nombre',
      width: 100,
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.length - b.name.length
    },
    {
      title: 'Código',
      width: 100,
      dataIndex: 'code',
      key: 'code',
      sorter: (a, b) => a.code.length - b.code.length
    },
    {
      title: 'Abreviación',
      width: 100,
      dataIndex: 'abbreviation',
      key: 'abbreviation',
      sorter: (a, b) => a.abbreviation.length - b.abbreviation.length
    },
    {
      title: 'Número de Teléfono',
      width: 130,
      dataIndex: 'tel',
      key: 'tel',
    },
    {
      title: 'Código Dirección',
      width: 100,
      dataIndex: 'addressCode',
      key: 'addressCode',
    },
    {
      title: 'Dirección',
      width: 130,
      dataIndex: 'address',
      key: 'address',
    },
    {
      title: 'RNC',
      width: 100,
      dataIndex: 'rnc',
      key: 'rnc',
      sorter: (a, b) => a.rnc.length - b.rnc.length
    },
    {
      title: 'Tipo NCF',
      width: 130,
      dataIndex: 'typeNcf',
      key: 'typeNcf',
      render: (text) => {
        if (text === 1) {
          return "Crédito Fiscal"
        }
        if (text === 2) {
          return "Consumidor Final"
        }
        if (text === 14) {
          return "Régimen Especial"
        }
        if (text === 15) {
          return "Gubernamental"
        }
        else {
          return ""
        }
      }
    },
    {
      title: 'Concepto',
      width: 130,
      dataIndex: 'concept',
      key: 'concept',
      render: (text) => {
        if (text === 0) {
          return "Contado"
        }
        if (text === 1) {
          return "Crédito"
        }
        else {
          return ""
        }
      }
    },
    {
      title: 'Razón Social',
      width: 150,
      dataIndex: 'socialReason',
      key: 'socialReason',
      sorter: (a, b) => a.socialReason.length - b.socialReason.length
    },
    {
      title: 'Límite de Crédito',
      width: 150,
      dataIndex: 'creditLimit',
      key: 'creditLimit',
      sorter: (a, b) => a.creditLimit - b.creditLimit
    },
    {
      title: 'Rango de Precio',
      width: 150,
      dataIndex: 'priceArea',
      key: 'priceArea',
      sorter: (a, b) => a.priceArea - b.priceArea
    },
    
    {
      title: 'Tipo de Documento',
      width: 150,
      dataIndex: 'documentType',
      key: 'documentType',
      render: (text) => {
        if (text === 0) {
          return "Conduce"
        }
        if (text === 1) {
          return "Factura"
        }
        if (text === 3) {
          return "Translnv"
        }
        if (text === 4) {
          return "Recibo"
        }
        if (text === 5) {
          return "Pedido"
        }
      },
      sorter: (a, b) => a.DocumentType.length - b.DocumentType.length
    },
    {
      title: 'Disponible en Almacén',
      width: 150,
      dataIndex: 'itsWarehouse',
      key: 'itsWarehouse',
      render: (text) => {
        if (text === true) {
          return <div style={{ color: '#04d182' }}>Activo<AiOutlineCheckCircle size='15' /></div>
        } else {
          return <div style={{ color: '#ff6b72' }}>Inactivo<AiOutlineCloseCircle size='15' /></div>
        }
      },
      sorter: (a, b) => a.itsWarehouse.length - b.itsWarehouse.length
    },
    {
      title: 'Estatus',
      width: 100,
      dataIndex: 'status',
      key: 'status',
      filters: [
        {
          text: 'Activo',
          value: true,
        },
        {
          text: 'Inactivo',
          value: false,
        },
      ],
      onFilter: (value, record) => record.status === value,
      render: (text) => {
        if (text === true) {
          return <div style={{ color: '#04d182' }}>Activo <AiOutlineCheckCircle size='15' /></div>
        }
        else {
          return <div style={{ color: '#ff6b72' }}>Inactivo <AiOutlineCloseCircle size='15' /></div>
        }
      },
    },
    
    {
      key: 'actions',
      width: 70,
      render(actionList, row) {
        return (
          <Restricted permission={'Producto'}>
            <Popover
              className='_more-btn'
              content={
                <>
                  {
                    row.actions !== undefined 
                    ? actionList.actions.map((definition,index) => <div key={index} className="_ops-option" onClick={() => definition.handler(row)}>{definition.icon} {definition.label}</div>)
                    : null
                  }
                </>
              }
            >
              {
                <Button><AiOutlineMore size='20' /></Button>
              }
            </Popover>
          </Restricted>
        );
      }
    }
  ];

  return (
    <div>
      <FilterPanel
        setFilters={setFilters}
        filterFields={filterFields}
      />
      <Table
        locale={{
          triggerAsc: 'Click para ordernar ascendente',
          triggerDesc: 'Click para ordernar descendente',
          cancelSort: 'Click para cancelar orden',
        }}
        onChange={() => formatToTable(columns)}
        dataSource={data.filter((c) => {
            if (
                (c.name ?? "").toLowerCase().includes(filters.name.toLowerCase()) &&
                (c.code ?? "").toLowerCase().includes(filters.code.toLowerCase()) &&
                (c.rnc ?? "").toLowerCase().includes(filters.rnc.toLowerCase()) &&
                (c.addressCode ?? "").toLowerCase().includes(filters.addressCode.toLowerCase())
            ) {
              return c;
            }
          return null;
        })}
        columns={formatToTable(columns)}
        scroll={{ x: 400 }}
        footer={() => {
          return(
            <>
              <Restricted permission={'Producto'}>
                <ImportData columns={formatImport(columns)} tableName={'customer'} />
              </Restricted>
              <ExportData columns={formatExport(columns)} data={data} tableName={'customer'}  />
            </>
          )
        }}
      />
    </div>
  )
}