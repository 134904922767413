import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import 'antd/dist/antd.css';
import {
  AiOutlineCheckCircle,
  AiOutlineCloseCircle,
  AiOutlineEdit,
  AiOutlineDelete,
  /* AiOutlineMobile,
  AiOutlineUser */
} from 'react-icons/ai';

import { useDispatch, useSelector } from 'react-redux';
import {
  WarehouseGroupToEdit,
  // warehouseGroupWarehouseToEdit,
  // warehouseGroupDeviceToEdit,
  refreshMyKey,
  openScreen,
} from "../actions/ui";
import { deleteAssociation, deleteGroup, expandedTableA, expandedTableB, getGroups } from '../hooks/fetchHook';
import { Restricted } from '../hooks/useRestricted';


import { Popover, Button } from 'antd';
import {AiOutlineMore} from 'react-icons/ai';
import { ExportData } from '../components/xlsx/ExportData';
import { ImportData } from '../components/xlsx/ImportData';
import { formatExport, formatImport, formatToTable } from '../helpers/columnsFormat';


export const ListWarehouseGroupTable = () => {
  const dispatch = useDispatch()
  const [data, setData] = useState([])
  const refreshKey = useSelector(state => state.ui.refreshKey)
  const realm = useSelector(state => state.auth.realm)
  // eslint-disable-next-line no-unused-vars
  const [expandedTableDataA, setExpandedTableDataA] = useState([])
  // eslint-disable-next-line no-unused-vars
  const [expandedTableDataB, setExpandedTableDataB] = useState([])

  const DeleteExpandedTableA = Object.freeze({
    DELETE: {
      label: 'Eliminar',
      icon: <><AiOutlineDelete /></>,
      handler(item, type) {
        deleteAssociation(item, type, 'warehousegroupwarehouse')
          .then(() => {
            expandedTableA(item, 'warehousegroupwarehouse')
              .then(res => {
                for (let prop of res) {
                  prop.key = prop.entityId
                  prop.actions = [DeleteExpandedTableA.DELETE]
                }
                setExpandedTableDataA(res)
                
              })
              .catch(err => {
                console.log(err)
              })
          })
          .catch(err => {
            console.log(err)
          })
        dispatch(refreshMyKey(refreshKey + 1))

      }
    }
  })
  const DeleteExpandedTableB = Object.freeze({
    DELETE: {
      label: 'Eliminar',
      icon: <><AiOutlineDelete /></>,
      handler(item, type) {
        deleteAssociation(item, type, 'warehousegroupdevice')
          .then(() => {
            expandedTableB(item, 'warehousegroupdevice')
              .then(res => {
                for (let prop of res) {
                  prop.key = prop.deviceFk
                  prop.actions = [DeleteExpandedTableB.DELETE]
                }
                setExpandedTableDataB(res)
              })
              .catch(err => {
                console.log(err)
              })
          })
          .catch(err => {
            console.log(err)
          })
        dispatch(refreshMyKey(refreshKey + 1))
      }
    }
  });

  const handdleExpand = (expanded, record) => {
    formatToTable(columns)
    if (expanded === true) {
      expandedTableA(record, 'warehousegroupwarehouse')
      .then(res => {
        if (res !== undefined) {
          for (let prop of res) {
            prop.key = prop.entityId
            prop.actions = [DeleteExpandedTableA.DELETE]
          }
        }
        setExpandedTableDataA(res)
      })
      .catch(err => {
        console.log(err)
      })

    expandedTableB(record, 'warehousegroupdevice')
      .then(res => {
        if (res !== undefined) {
          for (let prop of res) {
            prop.key = prop.deviceFk
            prop.actions = [DeleteExpandedTableB.DELETE]
          }
        }
        setExpandedTableDataB(res)
      })
      .catch(err => {
        console.log(err)
      })
    }
  };

  const ActionType = Object.freeze({
    VIEW_DETAILS: {
      label: 'Editar',
      icon: <><AiOutlineEdit /></>,
      handler(item) {
        dispatch(openScreen('uiOpenEditWarehouseGroup'))
        dispatch(WarehouseGroupToEdit(item))
        
      }
    },
   /*  ASOCIATE_CUST: {
      label: 'Agregar Almacen',
      icon: <AiOutlineUser />,
      handler(item) {
        dispatch(openScreen('uiOpenAssociateWarehouseGroupWarehouse'))
        dispatch(warehouseGroupWarehouseToEdit(item))
       
      }
    }, */
   /*  ASOCIATE_DEVICE: {
      label: 'Agregar Dispositivo',
      icon: <AiOutlineMobile />,
      handler(item) {
        dispatch(openScreen('uiOpenAssociateWarehouseGroupDevice'))
        dispatch(warehouseGroupDeviceToEdit(item))
      }
    }, */
    
    DELETE: {
      label: 'Eliminar',
      icon: <><AiOutlineDelete /></>,
      async handler(item) {
        await dispatch(deleteGroup(item, 'warehousegroup'))
        dispatch(refreshMyKey(refreshKey + 1))
      }
    }
  });

  useEffect(() => {
    dispatch(getGroups('warehousegroup', realm))
      .then((response) => {
        for (let prop of response) {
          prop.key = prop.id
          prop.actions = [ ActionType.VIEW_DETAILS,ActionType.DELETE ]
        }
        setData(response)
      })
      .catch(err => {
        console.log(err)
      })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshKey]);

  const columns = [
     {
       title: 'ID',
       width: 40,
       fixed: 'left',
       dataIndex: 'id',
       key: 'id',
       sorter: (a, b) => a.id - b.id,
     },
    {
      title: 'Nombre',
      width: 100,
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.length - b.name.length
    },
    {
      title: 'Descripcion',
      width: 150,
      dataIndex: 'description',
      key: 'description',
      sorter: (a, b) => a.description.length - b.description.length
    },
    {
      title: 'Estatus',
      width: 100,
      dataIndex: 'status',
      key: 'status',
      filters: [
        {
          text: 'Activo',
          value: true,
        },
        {
          text: 'Inactivo',
          value: false,
        },
      ],
      onFilter: (value, record) => record.status === value,
      render: (text) => {
        if (text === true) {
          return <div style={{ color: '#04d182' }}>Activo <AiOutlineCheckCircle size='15' /></div>
        }
        else {
          return <div style={{ color: '#ff6b72' }}>Inactivo <AiOutlineCloseCircle size='15' /></div>
        }
      },
    },
     {
       key: 'actions',
       width: 70,
       render(actionList, row) {
         return (
           <Restricted permission={'Usuarios'}>
           <Popover
             className='_more-btn'
             content={
              <>
                {
                  row.actions !== undefined 
                  ? actionList.actions.map((definition,index) => <div key={index} className="_ops-option" onClick={() => definition.handler(row)}>{definition.icon} {definition.label}</div>)
                  : null
                }
              </>
             }
           >
             {
               <Button><AiOutlineMore size='20' /></Button>
             }
           </Popover>
          </Restricted>
         );
       }
     }
  ];

//   const expandedRowRender = (row) => {
//     const AColumns = [
    
//       {
//         title: 'Productos',
//         dataIndex: 'entity', 
//         key: 'entity',
//         width: 70,
//         render: (entity) => {
//           return entity.name
//         }
//       },
//       {
//         title: 'Cantidad',
//         dataIndex: 'entity', 
//         key: 'entity',
//         width: 70,
//         render: (entity) => {
//           return entity.name
//         }
//       },
//       {
//         key: 'actions',
//         width: 70,
//         render(actionList, row) {
//           return (
//             <Restricted permission={'Usuarios'}>
//               <>
//                 {actionList.actions.map((definition,index) => <div key={index} className="_ops-option" onClick={() => definition.handler(row, 'TableA')}>{definition.icon} {definition.label}</div>)}
//               </>
//             </Restricted>
//           );
//         }
//       }
//     ];

//  /*    const BColumns = [
    
//       {
//         title: 'Dispositivo',
//         dataIndex: 'device',
//         key: 'device',
//         width: 70,
//         render: (device) => {
//           return device.description
//         }
//       },
//       {
//         key: 'actions',
//         width: 70,
//         render(actionList, row) {
//           return (
//             <Restricted permission={'Usuarios'}>
//               <>
//                 {actionList.actions.map((definition,index) => <div key={index} className="_ops-option" onClick={() => definition.handler(row, 'TableB')}>{definition.icon} {definition.label}</div>)}
//               </>
//             </Restricted>
//           );
//         }
//       }
//     ]; */

//    /*  return (
//       <div>
//         <Restricted permission={'Usuarios'}>
//           <div style={{display: 'flex'}}>
//             {row.actions.map((definition,index) => <div key={index} className="_ops-option" onClick={() => definition.handler(row, 'TableB')}>{definition.icon} {definition.label}</div>)}
//           </div>
//         </Restricted> */
//        {/*  <Table
//           scroll={{ x: 400 }}
//           title={() => "Productos en el Almacen"}
//           bordered
//           columns={AColumns}
//           dataSource={expandedTableDataA}
//           pagination={false}
//         />{" "} */}

//         {/* <Table
//           scroll={{ x: 400 }}
//           title={() => "Dispositivos Asociados"}
//           bordered
//           columns={BColumns}
//           dataSource={expandedTableDataB}
//           pagination={false}
//         /> */}
//      {/*  </div>
//     ); */}
//   };

  return (
    <div>
      <Table
        locale={{
          triggerAsc: 'Click para ordernar ascendente',
          triggerDesc: 'Click para ordernar descendente',
          cancelSort: 'Click para cancelar orden',
        }}
        onChange={() => formatToTable(columns)}
        dataSource={data}
        /* expandable={{expandedRowRender}} */
        onExpand={handdleExpand}
        columns={formatToTable(columns)}
        scroll={{ x: 400 }}
        footer={() => {
          return(
            <>
              <Restricted permission={'Usuarios'}>
                <ImportData columns={formatImport(columns)} tableName={'warehousegroup'} />
              </Restricted>
              <ExportData columns={formatExport(columns)} data={data} tableName={'Warehousegroup'}  />
            </>
          )
        }}
      />
    </div>
  )
} 