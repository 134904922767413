import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Switch, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { clearWarehouseInfo, openScreen } from '../../actions/ui';
import { addOrUpdate } from '../../hooks/fetchHook';
import { getTableData } from '../../hooks/fetchHook';
import { MeasurementUnitListBox } from "./MeasurementUnitListBox";
import { WarehouseListBox } from './WarehouseListBox';




export const AddWarehouseScreen = () => {


  const [data, setData] = useState([]);


  const dispatch = useDispatch();

  const isLoading = useSelector(state => state.ui.loading);
  const editingWarehouse = useSelector(state => state.ui.editingWarehouse);
  const isSidebarActive = useSelector(state => state.ui.sidebar);
  const realm = useSelector(state => state.auth.realm);
  const [formValues, setFormValues] = useState(editingWarehouse);

  const [form] = Form.useForm();

  const layout = {
    labelCol: { span: 10 },
    wrapperCol: { span: 100 }
  };

  /*  const handdleInputChange = ({target}) => {
     setFormValues({
       ...formValues,
       [target.id]: target.value,
       realmFk: realm,
     });
   }; */

  const handleSave = async () => {
    try {
      await form.validateFields()
        .then(() => {
          dispatch(addOrUpdate(formValues, 'warehouse'))
          form.resetFields()
          console.log(formValues)

        })
    }
    catch (err) { }
  }

  const handdleDiscard = () => {
    form.resetFields()
    dispatch(clearWarehouseInfo())
    dispatch(openScreen('uiOpenListWarehouse'))
  };
  const handdleStatus = (e) => {
    setFormValues({
      ...formValues,
      status: e
    })
  };
  // const handleDateUntil1 = (date) => {
  //   setFormValues({
  //     ...formValues,
  //     validUntil2: date
  //   })
  // };
  // const handleDateUntil2 = (date) => {
  //   setFormValues({
  //     ...formValues,
  //     validUntil: date
  //   })
  // };


  const handdleSelect = (e) => {
    setFormValues({
      ...formValues,
      productFk: e
    })

  };

  const handdleInputChange = ({ target }) => {
    setFormValues({
      ...formValues,
      [target.id]: target.value,
      realmFk: realm,
    });
  };

    


  useEffect(() => {
    dispatch(getTableData('product', realm))
      .then((response) => {
        setData(response)



      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  let children = [];
  const { Option } = Select;

  for (const prop of data) {
    children.push(<Option key={prop.id}>{prop.name}</Option>)
  };

  return (
    <div>
      <div className={isSidebarActive ? "_OCS-overlay" : "_overlay"}></div>
      <div className="_header-container3">
        <div
          className={
            isSidebarActive ? "_OCS-screen-header" : "_screen-header"
          }
        >
          <h2>Asociar Producto con Almacen</h2>
        </div>
        <div className={isSidebarActive ? "_btn2-1_" : "_btn2_"}>
          <div className="_btns-container2">
            <Button className="_btn-primary _ant-add-btn " onClick={handdleDiscard} type="primary">
              Volver
            </Button>
          </div>

        </div>
      </div>
      <div
        className={
          isSidebarActive
            ? "_OCS-ant-card-body _OCS-ant-card-form-group"
            : "_ant-card-body _ant-card-form-group"
        }
      >
        <div className="_ant-card-header">Información Básica</div>
        <Form form={form} layout="vertical" {...layout} name="userGroupForm">


        <WarehouseListBox
          handler={handdleInputChange}
        />

         {/*  <Form.Item label="Nombre" name="name">
            <Input size="large" onChange={handdleInputChange} id="name" />
          </Form.Item> */}


          <Form.Item
            label="Productos"
            name="productfk"
            rules={[
              {
                required: true,
                message: 'Favor seleccionar producto',
              }
            ]}
          >
            <Select
              showSearch
              onChange={handdleSelect}
              allowClear
            >{children}</Select>
          </Form.Item>


          <Form.Item label="Cantidad" name="quantity">
            <Input size="large" type='number' onChange={handdleInputChange} id="quantity" />
          </Form.Item>

          <MeasurementUnitListBox
            handler={handdleInputChange}
            initialValue={editingWarehouse.measurementUnit}
          />

          {/* 
          <Form.Item
                        label="Unidades de medida"
                        name="MeasurementUnit"
                        rules={[
                            {
                                required: true,
                                message: 'Favor seleccionar Una Unidad de Medida',
                            }
                        ]}
                    >
                        <Select
                            onChange={handdleSelect}
                            allowClear
                            size='large'
                        >{measuremnt}</Select>
                    </Form.Item> */}

          <Form.Item label="Estatus" name="Status" valuePropName="checked">
            <Switch
              defaultChecked
              checkedChildren="Activo"
              unCheckedChildren="Inactivo"
              onChange={handdleStatus}
            ></Switch>
          </Form.Item>
        </Form>
      </div>

      <div className={
        isSidebarActive
          ? "_OCS-ant-card-body _OCS-ant-card-form-group _btn-save-container"
          : "_ant-card-body _ant-card-form-group _btn-save-container"
      }>
        <div >
          <Button
            className="_btn-primary _ant-add-btn "
            loading={isLoading}
            onClick={handleSave}
            type="primary"
          >
            Crear
          </Button>
        </div>
      </div>

    </div>
  );
}
