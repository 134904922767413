import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AiOutlinePlusCircle } from 'react-icons/ai';

import { openScreen } from '../../actions/ui';
import { ListDiscountTable } from '../../antTables/ListDiscountTable';
import { Restricted } from '../../hooks/useRestricted';

export const ListDiscountScreen = () => {
    const isSidebarActive = useSelector(state => state.ui.sidebar);

    const dispatch = useDispatch();
    const handleAddDiscount = () => {
        dispatch(openScreen('uiOpenAddDiscount'))
    };

    return (
        <div>
            <div className={isSidebarActive ? '_OCS-overlay' : '_overlay'}></div>
            <div className={isSidebarActive ? '_OCS-screen-header' : '_screen-header'}>
                <h2>Lista de Descuentos</h2>
            </div>
            <div className={isSidebarActive ? '_OCS-ant-card-body' : '_ant-card-body'}>
                <div>
                    <Restricted permission={'Descuento'}>
                        <button className="_btn-primary _ant-add-btn _ant-add-top-table-btn" onClick={handleAddDiscount}>
                            <AiOutlinePlusCircle /> Crear Descuento
                        </button>
                    </Restricted>
                </div>
                <div className={isSidebarActive ? '_OCS-table-responsive' : '_table-responsive'}>
                    <ListDiscountTable />
                </div>
            </div>
        </div>
    )
}