import React, { useState } from "react";
import { Button, Input, DatePicker } from "antd";
import { SearchOutlined, CloseOutlined } from "@ant-design/icons";
import { getEmptyFilters } from "../../helpers/getEmptyFilters";
import moment from 'moment';

export const FilterPanel = ({ setFilters, filterFields }) => {
  const filters = getEmptyFilters(filterFields);
  const [newFilters, setNewFilters] = useState({
    ...filters,
  });

  const onChange = (e) => {
    const { id, value } = e.target;
    setNewFilters({
      ...newFilters,
      [id]: value,
    });
  };

  const onChangeDate = (date, dateString, id) => {
    setNewFilters({
      ...newFilters,
      [id]: dateString,  // Guardas la fecha en formato ISO o como prefieras
    });
  };

  const onFilter = () => {
    setFilters(newFilters);
  };

  const onClear = () => {
    setFilters({
      ...filters,
    });
    setNewFilters({
      ...filters,
    });
  };

  const FilterButton = ({ text, icon, onClick }) => {
    return (
      <Button
        icon={icon}
        type="primary"
        className="_btn-primary "
        style={{ width: '100%', borderRadius: '.625rem' }}
        onClick={onClick}
      >
        {text}
      </Button>
    );
  };

  return (
    <>
      <div
        style={{
          marginBottom: 10,
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
          gap: "10px",  
          alignItems: "center",
        }}
      >
      {filterFields.map((c) => {
          return c.type === "date" ? (
            <DatePicker
              id={c.id}
              placeholder={c.name}
              style={{ width: "100%" }}
              onChange={(date, dateString) => onChangeDate(date, dateString, c.id)}
              key={c.id}
              value={newFilters[c.id] ? moment(newFilters[c.id]) : null} 
              
            />
          ) : (
            <Input
              size="large"
              id={c.id}
              placeholder={c.name}
              style={{ width: "100%" }}
              onChange={onChange}
              key={c.id}
              value={newFilters[c.id]}
            />
          );
        })}

        <FilterButton
          text="Filtrar"
          icon={<SearchOutlined />}
          onClick={onFilter}
        />
        <FilterButton
          text="Limpiar"
          icon={<CloseOutlined />}
          onClick={onClear}
        />
      </div>
    </>
  );
};
