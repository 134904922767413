import { Button } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { openScreen } from '../../actions/ui';
import { DocumentDetailsTable } from '../../antTables/DocumentDetails';
import { DocumentPayments } from '../../antTables/DocumentPayments';
import { DocumentDetails } from '../../descriptions/DocumentDetails';

import { Map } from './Map';

export const ListDocumentDetails = () => {
    const isSidebarActive = useSelector(state => state.ui.sidebar);

    let documentDetails = useSelector(state => state.ui.documentDetails);

    const dispatch = useDispatch();
    const handleDiscard = () => {
        dispatch(openScreen('uiOpenListDocument'))
    };

    return ( 
        <div>
            <div className={isSidebarActive ? '_OCS-overlay' : '_overlay'}></div>
            <div className='_header-container3'>
                <div className={isSidebarActive ? '_OCS-screen-header' : '_screen-header'}>
                    <h2>Detalles del documento</h2>
                </div>

                <div className={isSidebarActive ? "_btn2-1_" : "_btn2_"}>
          <div className="_btns-container2">
            <Button className="_btn-primary _ant-add-btn " onClick={handleDiscard} type="primary">
              Volver
            </Button>
            </div>
          </div>
            </div>
            <div className={isSidebarActive ? '_OCS-ant-card-body' : '_ant-card-body'}>
                <div className={isSidebarActive ? '_OCS-table-responsive' : '_table-responsive'}>
                    <DocumentDetails />
                </div>
            </div>


            <div className={isSidebarActive ? '_OCS-ant-card-body' : '_ant-card-body'}>
                <div className={isSidebarActive ? '_OCS-table-responsive' : '_table-responsive'}>
                    <DocumentDetailsTable />
                </div>

            </div>
            <div className={isSidebarActive ? '_OCS-ant-card-body' : '_ant-card-body'}>
                <div className={isSidebarActive ? '_OCS-table-responsive' : '_table-responsive'}>
                    <DocumentPayments />
                </div>
            </div>

            {
                documentDetails.customer !== undefined ? <div className={isSidebarActive ? '_OCS-ant-card-body' : '_ant-card-body'}>
                    <div className={isSidebarActive ? '_OCS-table-responsive' : '_table-responsive'}>
                        <Map></Map>
                    </div>
                </div> : <p></p>
            }
        </div>
    )
}