import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import 'antd/dist/antd.css';
import {
  AiOutlineCheckCircle,
  AiOutlineCloseCircle,
  AiOutlineMore,
  AiOutlineEdit,
  AiOutlineDelete
} from 'react-icons/ai';
import { Popover, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { deletePaymentMethod, paymentMethodToEdit, openScreen, refreshMyKey } from '../actions/ui';
import { Restricted } from '../hooks/useRestricted';
import { getTableData } from '../hooks/fetchHook';

import { ExportData } from '../components/xlsx/ExportData';
import { ImportData } from '../components/xlsx/ImportData';
import { formatExport, formatImport, formatToTable } from '../helpers/columnsFormat';



export const ListPaymentMethodTable = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const refreshKey = useSelector(state => state.ui.refreshKey);
  const realm = useSelector(state => state.auth.realm)

  const ActionType = Object.freeze({
    VIEW_DETAILS: {
      label: 'Editar',
      icon: <><AiOutlineEdit /></>,
      handler(item) {
        dispatch(openScreen('uiOpenEditPaymentMethod'))
        dispatch(paymentMethodToEdit(item))
      }
    },
    DELETE: {
      label: 'Eliminar',
      icon: <><AiOutlineDelete /></>,
      async handler(item) {
        await dispatch(deletePaymentMethod(item))
        dispatch(refreshMyKey(refreshKey + 1))
      }
    }
  });

  useEffect(() => {
    dispatch(getTableData('paymentmethod', realm))
    .then((response) => {
      for (let prop of response) {
        prop.key = prop.id
        prop.actions = [ActionType.VIEW_DETAILS, ActionType.DELETE]
      }
      setData(response)
    })
    .catch(err => {
      console.log(err)
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, refreshKey]);

  const columns = [
    {
      title: 'ID',
      width: 70,
      fixed: 'left',
      dataIndex: 'id',
      key: 'id',
      sorter: (a, b) => a.id - b.id
    },
    {
      title: 'Nombre',
      width: 100,
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.length - b.name.length
    },
    {
      title: 'Ordinal',
      width: 90,
      dataIndex: 'ordinal',
      key: 'ordinal',
      sorter: (a, b) => a.ordinal - b.ordinal
    },
    {
      title: 'Referencia de Uso',
      width: 150,
      dataIndex: 'referenceUsed',
      key: 'referenceUsed',
      filters: [
        {
          text: 'Utilizado',
          value: true,
        },
        {
          text: 'No Utilizado',
          value: false,
        },
      ],
      onFilter: (value, record) => record.referenceUsed === value,
      render: (text) => {
        if (text === true) {
          return <div style={{ color: '#04d182' }}>Utilizado <AiOutlineCheckCircle size='15' /></div>
        }
        else {
          return <div style={{ color: '#ff6b72' }}>No Utilizado <AiOutlineCloseCircle size='15' /></div>
        }
      }
    },
    {
      title: 'Pago Único',
      width: 100,
      dataIndex: 'singlePayment',
      key: 'singlePayment',
      filters: [
        {
          text: 'Si',
          value: true,
        },
        {
          text: 'No',
          value: false,
        },
      ],
      onFilter: (value, record) => record.singlePayment === value,
      render: (text) => {
        if (text === true) {
          return "Si"
        } else {
          return "No"
        }
      }
    },
    {
      title: 'Estatus',
      width: 100,
      dataIndex: 'status',
      key: 'status',
      render: (text) => {
        if (text === true) {
          return <div style={{ color: '#04d182' }}>Activo <AiOutlineCheckCircle size='15' /></div>
        }
        else {
          return <div style={{ color: '#ff6b72' }}>Inactivo <AiOutlineCloseCircle size='15' /></div>
        }
      },
      filters: [
        {
          text: 'Activo',
          value: true,
        },
        {
          text: 'Inactivo',
          value: false,
        },
      ],
      onFilter: (value, record) => record.status === value,
    },
    {
      key: 'actions',
      width: 70,
      render(actionList, row) {
        return (
          <Restricted permission={'Métodos de Pago'}>
            <Popover
              className='_more-btn'
              content={
                <>
                  {
                    row.actions !== undefined 
                    ? actionList.actions.map((definition,index) => <div key={index} className="_ops-option" onClick={() => definition.handler(row)}>{definition.icon} {definition.label}</div>)
                    : null
                  }
                </>
              }
            >
              {
                <Button><AiOutlineMore size='20' /></Button>
              }
            </Popover>
          </Restricted>
        );
      }
    }
  ];

  return (
    <div>
      <Table
        locale={{
          triggerAsc: 'Click para ordernar ascendente',
          triggerDesc: 'Click para ordernar descendente',
          cancelSort: 'Click para cancelar orden',
        }}
        onChange={ () => formatToTable(columns)}
        dataSource={data}
        columns={formatToTable(columns)}
        scroll={{ x: 400 }}
        footer={() => {
          return(
            <>
              <Restricted permission={'Métodos de Pago'}>
                <ImportData columns={formatImport(columns)} tableName={'paymentmethod'} />
              </Restricted>
              <ExportData columns={formatExport(columns)} data={data} tableName={'paymentmethod'} />
            </>
          )
        }}
      />
    </div>
  )
}