import React, { useEffect, useState } from 'react';
import { Button, Popover, Table } from 'antd';
import 'antd/dist/antd.css';
import { useDispatch, useSelector } from 'react-redux';
import { getTableData } from '../hooks/fetchHook';
import { discountToEdit, openScreen } from '../actions/ui';
import {
  AiOutlineCheckCircle,
  AiOutlineCloseCircle,
  AiOutlineMore,
  AiOutlineEdit,
} from 'react-icons/ai';
import { Restricted } from '../hooks/useRestricted';
import moment from 'moment';


import { ExportData } from '../components/xlsx/ExportData';
import { ImportData } from '../components/xlsx/ImportData';
import { formatExport, formatImport, formatToTable } from '../helpers/columnsFormat';
import { getEmptyFilters } from '../helpers/getEmptyFilters';
import { FilterPanel } from "../components/ui/FilterPanel";

export const ListDiscountTable = () => {
  const dispatch = useDispatch()
  const [data, setData] = useState([]);
  const refreshKey = useSelector(state => state.ui.refreshKey);
  const realm = useSelector(state => state.auth.realm)

  
  const filterFields = [
    {
      id: "customerName",
      name: "Nombre Cliente",
      type: "string",
    },

  ]
  const [filters, setFilters] = useState(getEmptyFilters(filterFields));

  useEffect(() => {
    dispatch(getTableData('discount', realm))
      .then((response) => {
        for (let prop of response) {
          prop.key = prop.remoteId
          prop.actions = [ActionType.VIEW_DETAILS]
          prop.validUntil = moment.parseZone(prop.validUntil).local().format("YYYY-MM-DD HH:mm:ss")
          prop.validFrom = moment.parseZone(prop.validFrom).local().format("YYYY-MM-DD HH:mm:ss")
        }
        setData(response)
      })
      .catch(err => {
        console.log(err)
      })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, refreshKey]);


  const ActionType = Object.freeze({
    VIEW_DETAILS: {
      label: 'Editar',
      icon: <><AiOutlineEdit /></>,
      handler(item) {
        dispatch(openScreen('uiOpenEditDiscount'))
        dispatch(discountToEdit(item))
      }
    },
  });

  const columns = [
     {
       title: 'ID',
       width: 70,
       fixed: 'left',
       dataIndex: 'id',
       key: 'id',
       sorter: (a, b) => a.id - b.id
     },
     {
      title: 'Dominio Cliente',
      width: 120,
      dataIndex: 'customerName',
      key: 'customerName',
      sorter: (a, b) => a.customerName - b.customerName
    },
    {
      title: 'Producto ',
      width: 90,
      dataIndex: 'productName',
      key: 'productName',
      sorter: (a, b) => a.productName.length - b.productName.length
    },
   
    {
      title: 'Unidad de Medida',
      width: 120,
      dataIndex: 'measurementUnit',
      key: 'measurementUnit',
      sorter: (a, b) => a.measurementUnit.length - b.measurementUnit.length,
    },
    {
      title: 'Porcentaje',
      width: 130,
      dataIndex: 'percentage',
      key: 'percentage',
      sorter: (a, b) => a.percentage - b.percentage
    },
    {
      title: 'Monto',
      width: 140,
      dataIndex: 'flat',
      key: 'flat',
      sorter: (a, b) => a.flat - b.flat
    },
    {
      title: 'Válido Desde',
      width: 150,
      dataIndex: 'validFrom',
      key: 'validFrom',
      sorter: (a, b) => a.validFrom.length - b.validFrom.length
    },
    {
      title: 'Válido Hasta',
      width: 150,
      dataIndex: 'validUntil',
      key: 'validUntil',
      sorter: (a, b) => a.validUntil.length - b.validUntil.length
    },
    {
      title: 'Estatus',
      width: 100,
      dataIndex: 'status',
      key: 'status',
      filters: [
        {
          text: 'Activo',
          value: true,
        },
        {
          text: 'Inactivo',
          value: false,
        },
      ],
      onFilter: (value, record) => record.status === value,
      render: (text) => {
        if (text === true) {
          return <div style={{ color: '#04d182' }}>Activo <AiOutlineCheckCircle size='15' /></div>
        }
        else {
          return <div style={{ color: '#ff6b72' }}>Inactivo <AiOutlineCloseCircle size='15' /></div>
        }
      },
    },
    {
      key: 'actions',
      width: 70,
      render(actionList, row) {
        return (
          <Restricted permission={'Descuento'}>
            <Popover
              className='_more-btn'
              content={
                <>
                  {actionList.actions.map((definition,index) => <div key={index} className="_ops-option" onClick={() => definition.handler(row)}>{definition.icon} {definition.label}</div>)}
                </>
              }
            >
              {
                <Button><AiOutlineMore size='20' /></Button>
              }
            </Popover>
          </Restricted>
        );
      }
    }
  ];

  return (
    <div>
      <FilterPanel
        setFilters={setFilters}
        filterFields={filterFields}
      />
      <Table
        locale={{
          triggerAsc: 'Click para ordernar ascendente',
          triggerDesc: 'Click para ordernar descendente',
          cancelSort: 'Click para cancelar orden',
        }}
        onChange={ () => formatToTable(columns)}
        dataSource={data.filter((c) => {

          if (
            c.customerName.toLowerCase().includes(filters.customerName.toLowerCase())
          ) {
            return c;
          }
        return null;
      })}
        columns={formatToTable(columns)}
        scroll={{ x: 400 }}
        footer={() => {
          return(
            <>
              <Restricted permission={'Descuento'}>
                <ImportData columns={formatImport(columns)} tableName={'discount'} />
              </Restricted>
              <ExportData columns={formatExport(columns)} data={data} tableName={'discount'} />
            </>
          )
        }}
      />
    </div>
  )
}