import React, { useEffect, useState } from "react";
import { Button, Form, Input, Switch, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { clearWarehouseInfo, openScreen } from "../../actions/ui";
import { addOrUpdate } from "../../hooks/fetchHook";
import { DatePicker } from "antd";
import moment from "moment";
import { getTableData } from "../../hooks/fetchHook";
import { MeasurementUnitListBox } from "./MeasurementUnitListBox";
import { WarehouseListBox } from './WarehouseListBox';

export const EditWarehouseScreen = () => {
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.ui.loading);
  const editingWarehouse = useSelector((state) => state.ui.Warehouse);
  const isSidebarActive = useSelector((state) => state.ui.sidebar);
  const realm = useSelector((state) => state.auth.realm);
  const [formValues, setFormValues] = useState(editingWarehouse);
  const [form] = Form.useForm();

  const layout = {
    labelCol: { span: 10 },
    wrapperCol: { span: 100 },
  };

  const handdleInputChange = ({ target }) => {
    setFormValues({
      ...formValues,
      [target.id]: target.value,
      realmFk: realm,
    });
  };

  const handleSave = async () => {
    try {
      await form.validateFields().then(() => {
        dispatch(addOrUpdate(formValues, "warehouse"));
      });
    } catch (err) {}
  };

  const handdleDiscart = () => {
    form.resetFields();
    dispatch(clearWarehouseInfo());
    dispatch(openScreen("uiOpenListWarehouse"));
  };

  const handdleStatus = (e) => {
    setFormValues({
      ...formValues,
      status: e,
    });
  };

  const handleDateUntil = (date) => {
    setFormValues({
      ...formValues,
      validUntil: date,
    });
  };


  const handdleSelect = (e) => {
    setFormValues({
        ...formValues,
        productFk : e
    })
    
  };

  useEffect(() => {
    dispatch(getTableData("product", realm)).then((response) => {
      setData(response);
      
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  let children = [];
  const { Option } = Select;

  for (const prop of data) {
    children.push(<Option key={prop.id}>{prop.name}</Option>);
  }

  return (
    <div>
      <div className={isSidebarActive ? "_OCS-overlay" : "_overlay"}></div>
      <div className="_header-container3">
        <div
          className={isSidebarActive ? "_OCS-screen-header" : "_screen-header"}
        >
          <h2>Editar Almacenes</h2>
        </div>
        <div className={isSidebarActive ? "_btn2-1_" : "_btn2_"}>
          <div className="_btns-container2">
            <Button className="_btn-primary _ant-add-btn " onClick={handdleDiscart} type="primary">
              Volver
            </Button>
            </div>
          </div>
      </div>
      <div
        className={
          isSidebarActive
            ? "_OCS-ant-card-body _OCS-ant-card-form-group"
            : "_ant-card-body _ant-card-form-group"
        }
      >
        <div className="_ant-card-header">Información Básica</div>
        <Form
          form={form}
          layout="vertical"
          {...layout}
          name="warehouseInfoForm"
          initialValues={{
            id: [editingWarehouse.id],
            name: [editingWarehouse.name],
            productFk: [editingWarehouse.productFk],
            quantity: [editingWarehouse.quantity],
            status: [editingWarehouse.status],
            created: moment(editingWarehouse.created),
            modified: moment(editingWarehouse.modified)
          }}
        >
          <Form.Item label="ID" name="id">
            <Input
              disabled
              size="large"
              onChange={handdleInputChange}
              id="id"
            />
          </Form.Item>

          <WarehouseListBox
            handler={handdleInputChange}
            initialValue={editingWarehouse.name}
          />

          {/* <Form.Item label="Nombre" name="name">
            <Input size="large" onChange={handdleInputChange} id="name" />
          </Form.Item> */}

          <Form.Item label="Cantidad" name="quantity">
            <Input
              size="large"
              onChange={handdleInputChange}
              id="quantity"
              type="number"
            />
          </Form.Item>

         

          <Form.Item
                        label="Productos"
                        name="productfk"
                        rules={[
                            {
                                required: true,
                                message: 'Favor seleccionar producto',
                            }
                        ]}
                        initialValue={editingWarehouse.productName}
                    >
                        <Select
                            onChange={handdleSelect}
                            allowClear
                            size='large'
                        >{children}</Select>
                    </Form.Item>



          <MeasurementUnitListBox
            handler={handdleInputChange}
            initialValue={editingWarehouse.measurementUnit}
          />

          <Form.Item
            label="Creado"
            name="created"
            /* hidden={true} */
          >
            <DatePicker
              onChange={handleDateUntil}
              format="YYYY-MM-DD HH:mm:ss"
              showTime={{ defaultValue: moment("00:00:00", "HH:mm:ss") }}
            />
          </Form.Item>

          <Form.Item
            label="Modificado"
            name="modified"
            /* hidden={true} */
          >
            <DatePicker
              onChange={handleDateUntil}
              format="YYYY-MM-DD HH:mm:ss"
              showTime={{ defaultValue: moment("00:00:00", "HH:mm:ss") }}
            />
          </Form.Item>

          <Form.Item label="Estatus" name="status" valuePropName="checked">
            <Switch
              defaultChecked={editingWarehouse.status}
              onChange={handdleStatus}
              checkedChildren="Activo"
              unCheckedChildren="Inactivo"
            ></Switch>
          </Form.Item>
        </Form>
      </div>
      <div  className={
            isSidebarActive
              ? "_OCS-ant-card-body _OCS-ant-card-form-group _btn-save-container"
              : "_ant-card-body _ant-card-form-group _btn-save-container"
          }>
          <div >  
          <Button
              className="_btn-primary _ant-add-btn "
              loading={isLoading}
              onClick={handleSave}
              type="primary"
            >
              Guardar
            </Button>
            </div>
        </div>
    </div>
  );
};
