import React, { useState } from 'react';
import { Button, Form, Input, Select, Switch } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { clearCustomerInfo, openScreen } from '../../actions/ui';
import { addOrUpdate } from '../../hooks/fetchHook';
import { MapInput } from './MapInput';
//import { toBeInTheDocument } from '@testing-library/jest-dom/dist/matchers';

export const AddCustomerScreen = () => {

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.ui.loading)
    const editingCustomer = useSelector(state => state.ui.editingCustomer)
    const isSidebarActive = useSelector(state => state.ui.sidebar);
    const realm = useSelector(state => state.auth.realm);
    const [formValues, setFormValues] = useState(editingCustomer)
    const [form] = Form.useForm();

    const layout = {
        labelCol: { span: 10 },
        wrapperCol: { span: 100 }
    };

    const handdleInputchange = ({ target }) => {
        setFormValues({
            ...formValues,
            [target.id]: target.value,
            realmFk: realm
            
        })
    };

    const handdleSave = async () => {
        try{
            await form.validateFields()
            .then(() => {
                dispatch(addOrUpdate(formValues, 'customer'))
                form.resetFields()
            })
        }
        catch(err) {}
    }

    const handdleDiscart = () => {
        form.resetFields()
        dispatch(clearCustomerInfo())
        dispatch(openScreen('uiOpenListCustomer'))
    };

    const handdleStatus = (e) => {
        setFormValues({
            ...formValues,
            status: editingCustomer.status === ""? false : e
        })
    }
    const handdleWarehouse = (e) => {
        setFormValues({
            ...formValues,
            itsWarehouse: editingCustomer.itsWarehouse === ""? false : e
        })
    }

    const handdleTypeNcf = (e) => {
        setFormValues({
            ...formValues,
            typeNcf: e
        }) 
    }
    const handdleConcept = (e) => {
        setFormValues({
            ...formValues,
            concept: e
        })
    }
    const handdleDocumentType = (e) => {
        setFormValues({
            ...formValues,
            documentType: e
        })
    }


    return (
        <div>
            <div className={isSidebarActive ? '_OCS-overlay' : '_overlay'}></div>
            <div className='_header-container3'>
                <div className={isSidebarActive ? '_OCS-screen-header' : '_screen-header'}>
                    <h2>Crear Cliente</h2>
                </div>
                <div className={isSidebarActive ? "_btn2-1_" : "_btn2_"}>
          <div className="_btns-container2">
            <Button className="_btn-primary _ant-add-btn " onClick={handdleDiscart} type="primary">
              Volver
            </Button>
            </div>
          </div>
            </div>
            <div className={isSidebarActive ? '_OCS-ant-card-body _OCS-ant-card-form-group' : '_ant-card-body _ant-card-form-group'}>
                <div className='_ant-card-header'>Información Básica</div>
                <Form
                    form={form}
                    layout='vertical'
                    {...layout}
                    name="deviceInfoForm"
                >
                    <Form.Item
                        label="Nombre"
                        name="name"
                    >
                        <Input size='large' onChange={handdleInputchange} id="name"  />
                    </Form.Item>

                    <Form.Item
                        label="Código"
                        name="code"
                    >
                        <Input size='large' onChange={handdleInputchange} id="code" type="number"/>
                    </Form.Item>

                    <Form.Item
                        label="Abreviación"
                        name="abbreviation"
                    >
                        <Input size='large' onChange={handdleInputchange} id="abbreviation" />
                    </Form.Item>

                    <Form.Item
                        label="Número de Teléfono"
                        name="tel"
                    >
                        <Input size='large' onChange={handdleInputchange} id="tel" type="number"/>
                    </Form.Item>

                    <Form.Item
                        label="Código Dirección"
                        name="addressCode"
                    >
                        <Input size='large' onChange={handdleInputchange} id="addressCode" />
                    </Form.Item>

                    <Form.Item
                        label="Dirección"
                        name="address"
                    >
                        <Input size='large' onChange={handdleInputchange} id="address" />
                    </Form.Item>

                    <Form.Item
                        label="RNC"
                        name="rnc"
                    >
                        <Input size='large' onChange={handdleInputchange} id="rnc" type="number" />
                    </Form.Item>
                </Form>
            </div>
            <div className={isSidebarActive ? '_OCS-ant-card-body _OCS-ant-card-form-group' : '_ant-card-body _ant-card-form-group'}>
                <div className='_ant-card-header'>Organización</div>
                <div className='_ant-form-wrapper'>
                    <div>
                        <Form
                            form={form}
                            {...layout}
                            layout='vertical'
                            name="deviceInfoForm"
                        >
                            <Form.Item
                                name="status"
                                label="Estatus"
                            >
                                <Switch checkedChildren="Activo" unCheckedChildren="Inactivo" onChange={handdleStatus} />
                            </Form.Item>

                            <Form.Item
                                label="Tipo NCF"
                                name="typeNcf"
                            >
                                <Select 
                                    placeholder="Seleccionar tipo NCF" 
                                    size='large' 
                                    onChange={handdleTypeNcf}
                                    allowClear
                                >
                                    <Select.Option value={1}>Crédito Fiscal</Select.Option>
                                    <Select.Option value={2}>Consumidor Final</Select.Option>
                                    <Select.Option value={14}>Régimen Especial</Select.Option>
                                    <Select.Option value={15}>Gubernamental</Select.Option>
                                </Select>
                            </Form.Item>

                            <Form.Item
                                label="Concepto"
                                name="concept"
                            >
                                <Select 
                                    placeholder="Seleccionar Concepto" 
                                    size='large' 
                                    onChange={handdleConcept}
                                    allowClear
                                >
                                    <Select.Option value={0}>Contado</Select.Option>
                                    <Select.Option value={1}>Crédito</Select.Option>
                                </Select>
                            </Form.Item>
                            
                            <Form.Item
                                label="Razón Social"
                                name="socialReason"
                            >
                                <Input size='large' onChange={handdleInputchange} id="socialReason" />
                            </Form.Item>
                            
                            <Form.Item
                                label="Límite de Crédito"
                                name="creditLimit"
                            >
                                <Input size='large' onChange={handdleInputchange} id="creditLimit" type="number"/>
                            </Form.Item>
                            
                            <Form.Item
                                label="Rango de Precio"
                                name="priceArea"
                            >
                                <Input size='large' onChange={handdleInputchange} id="priceArea" type="number"/>
                            </Form.Item>
                        </Form>
                    </div>

                    <div>
                        <Form
                            form={form}
                            {...layout}
                            layout='vertical'
                            name="deviceInfoForm"
                        >
                            {/* <Form.Item
                                label="Descuentos"
                                name="discountCount"
                            >
                                <Input size='large' onChange={handdleInputchange} id="discountCount" />
                            </Form.Item> */}

                            <Form.Item
                                label="Tipo de Documento"
                                name="documentType"
                            >
                                <Select 
                                    placeholder="Seleccionar Documento"
                                    size='large' 
                                    onChange={handdleDocumentType}
                                    allowClear
                                >
                                    <Select.Option value={0}>Conduce</Select.Option>
                                    <Select.Option value={1}>Factura</Select.Option>
                                    <Select.Option value={3}>Trans LNV</Select.Option>
                                    <Select.Option value={4}>Recibo</Select.Option>
                                    <Select.Option value={5}>Pedido</Select.Option>
                                </Select>
                            </Form.Item>

                            <Form.Item
                                name="itsWarehouse"
                                label="Disponible en Almacén"
                            >
                                <Switch defaultChecked onChange={handdleWarehouse} />
                            </Form.Item>

                            {/* <Form.Item
                                label="Latitud"
                                name="latitud"
                            >
                                <Input size='large' onChange={handdleInputchange} id="latitud" />
                            </Form.Item>
                            
                            <Form.Item
                                label="Longitud"
                                name="longitud"
                            >
                                <Input size='large' onChange={handdleInputchange} id="longitud" />
                            </Form.Item>
                            
                            <Form.Item
                                label="Radio"
                                name="radio"
                            >
                                <Input size='large' onChange={handdleInputchange} id="radio" />
                            </Form.Item> */}
                        </Form>
                    </div>
                </div>
                <div>
                    <MapInput handler={setFormValues} formValues={formValues} />
                </div>
            </div>
            <div  className={
            isSidebarActive
              ? "_OCS-ant-card-body _OCS-ant-card-form-group _btn-save-container"
              : "_ant-card-body _ant-card-form-group _btn-save-container"
          }>
          <div>  
          <Button
              className="_btn-primary _ant-add-btn "
              loading={isLoading}
              onClick={handdleSave}
              type="primary"
            >
              Crear
            </Button>
            </div>
        </div>
        </div>
    )
};