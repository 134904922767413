import { fetchWithToken } from '../helpers/fetch.js';
import { message } from 'antd';
import { documentDetails, loading } from '../actions/ui.js';

export const getTableData = (endpoint, realm) => {

    return async (dispatch) => {
        dispatch(loading(true))

        try {
            const req = await fetchWithToken(`${endpoint}/${realm}`);
            const res = await req.json();
            dispatch(loading(false))

            return res.response;
        }
        catch (err) {
            dispatch(loading(false))
            message.error('No se pudo encontrar la data, error: ', err)
        }
    }
};

export const getGroups = (endpoint, realm) => {
    return async (dispatch) => {
        dispatch(loading(true))

        try {
            const req = await fetchWithToken(`${endpoint}/${realm}`);
            const res = await req.json();
            dispatch(loading(false))

            return res.response
        }
        catch (err) {
            dispatch(loading(false))
            message.error('No se pudo encontrar la data, error: ', err)
        }
    }
};

export const addOrUpdate = (record, endpoint) => {
    delete record.actions
    if (record.permission) {
        let userPermissionSum = 0;
        for (const prop of record.permission) {
            userPermissionSum += (prop * 1)
        }
        record.permission = userPermissionSum
    }
    if(record.validUntil){
        record.validUntil = record.validUntil.utc().format("YYYY-MM-DD HH:mm:ss")
    }
    if(record.validFrom){
        record.validFrom = record.validFrom.utc().format("YYYY-MM-DD HH:mm:ss")
    }

    return async (dispatch) => {
        dispatch(loading(true))
        if (record.id !== 0) {
            try {
                const req = await fetchWithToken(`${endpoint}/addorupdate`, record, 'POST');
                const res = await req.json();
                dispatch(loading(false))

                if (!!!res.result) {
                    message.warning('No se pudo proceder con la accion, favor verificar campos requeridos')
                } else {
                    message.success('Listo')
                }
            }
            catch (err) {
                dispatch(loading(false))
                message.error('No se pudo proceder con la peticion, error: ', err)
            }
        }
        else {
            record.id = 0
            try {
                const req = await fetchWithToken(`${endpoint}/addorupdate`, record, 'POST');
                const res = await req.json();
                dispatch(loading(false))

                if (!!!res.result) {
                    message.warning('No se pudo proceder con la accion, favor verificar campos requeridos')
                } else {
                    message.success('Listo')
                }
            }
            catch (err) {
                dispatch(loading(false))
                message.error('No se pudo proceder con la peticion, error: ', err)
            }
        }
    }
}

export const addOrUpdateFromImport = (records, endpoint) => {

    records.forEach((record) =>{
        if (record.permission) {
            let userPermissionSum = 0;
            for (const prop of record.permission) {
                userPermissionSum += (prop * 1)
            }
            record.permission = userPermissionSum
        }
        if(record.validUntil){
            record.validUntil = record.validUntil.utc().format("YYYY-MM-DD HH:mm:ss")
        }
        if(record.validFrom){
            record.validFrom = record.validFrom.utc().format("YYYY-MM-DD HH:mm:ss")
        }
    })


    return async (dispatch) => {
        dispatch(loading(true))
        try {
            const req = await fetchWithToken(`${endpoint}/addorupdatefromimport`, records, 'POST');
            const res = await req.json();
            dispatch(loading(false))

            if (!!!res.result) {
                message.warning('No se pudo proceder con la accion, favor verificar campos requeridos')
            } else {
                message.success('Listo')
            }
        }
        catch (err) {
            dispatch(loading(false))
            console.log(err)
            message.error('No se pudo proceder con la peticion, error: ', err)
        }
        
    }
}

export const deleteGroup = (record, endpoint) => {
    const body = {
        id: record.id,
        name: record.name,
        description: record.description,
        realmFk: record.realmFk,
        status: record.status
    }

    return async () => {
        try {
            const req = await fetchWithToken(`${endpoint}/delete`, body, 'POST');
            const res = await req.json();

            if (!!!res.result) {
                message.warning('No se pudo proceder con la acción')
            } else {
                message.success('Listo')
            }
        }
        catch (err) {
            message.error('Error al eliminar grupo, error: ', err)
        }
    }
};

export const expandedTableA = async (record, endpoint) => {
    const parameters = {
        id: record.id || record.groupFk,
        realmFk: record.realmFk
    }

    try {
        const req = await fetchWithToken(`${endpoint}/bygroup/${parameters.realmFk}/${parameters.id}`)
        const res = await req.json()

        return res.response
    }
    catch (err) {
        message.error('Error al encontrar relaciones al grupo, error: ', err)
    }
};

export const expandedTableB = async (record, endpoint) => {
    const parameters = {
        id: record.id || record.groupFk,
        realmFk: record.realmFk
    }

    try {
        const req = await fetchWithToken(`${endpoint}/${parameters.realmFk}/${parameters.id}`);
        const res = await req.json();

        return res.response
    }
    catch (err) {
        message.error('Error al encontrar relaciones al grupo, error: ', err)
    }
};

export const assocToGroup = (record, endpoint) => {
    delete record.actions

    return async (dispatch) => {
        dispatch(loading(true))
        try {
            const req = await fetchWithToken(`${endpoint}/add`, record, 'POST');
            const res = await req.json();
            dispatch(loading(false))

            if (!!!res.result) {
                message.warning('No se pudo proceder con la accion, favor verificar campos requeridos')
            } else {
                message.success('Listo')
            }
        }
        catch (err) {
            dispatch(loading(false))
            message.error('No se pudo proceder con la asociación, error: ', err)
        }
    }
};

export const deleteAssociation = async (record, type, endpoint) => {
    if (type === 'TableA') {
        const body = {
            groupFk: record.groupFk,
            entityId: record.entityId,
            realmFk: record.realmFk
        }

        try {
            const req = await fetchWithToken(`${endpoint}/delete`, body, 'POST');
            const res = await req.json();

            if (!!!res.result) {
                message.warning('No se puede eliminar la relacion seleccionada')

            } else {
                message.success('Listo')
            }
        }
        catch (err) {
            message.error('Error al intentar eliminar asociación, error:', err)
        }
    }
    if (type === 'TableB') {
        const body = {
            groupFk: record.groupFk,
            deviceFk: record.deviceFk,
            realmFk: record.realmFk
        }

        try {
            const req = await fetchWithToken(`${endpoint}/delete`, body, 'POST')
            const res = await req.json()

            if (!!!res.result) {
                message.warning('No se puede eliminar la relacion seleccionada')
            } else {
                message.success('Listo')
            }
        }
        catch (err) {
            message.error('Error al intentar eliminar asociación, error:', err)
        }
    }
};

export const getDocumentDetails = (endpoint, record) => {

   return async(dispatch) => {
    try{
        const req = await fetchWithToken(`${endpoint}/${record.realmFk}/${record.deviceFk}/${record.remoteId}`);
        const res = await req.json();

        dispatch(documentDetails(res.response))
        // return res.response
    }
    catch(err){
        message.error('Error al encontrar detalles, error: ', err)
    }
   }
} ;

export const getProductDetails = (endpoint, record) => {

    return async(dispatch) => {
     try{
         const req = await fetchWithToken(`${endpoint}/${record.realmFk}/${record.deviceFk}/${record.remoteId}`);
         const res = await req.json();
 
         dispatch(documentDetails(res.response))
         // return res.response
     }
     catch(err){
         message.error('Error al encontrar detalles, error: ', err)
     }
    }
 } ;