export const types = {

    uiOpenDashboard: '[UI] Open Dashboard',

    uiOpenListDevice: '[UI] Open List Device',
    uiOpenAddDevice: '[UI] Open Add Device',
    uiOpenEditDevice: '[UI] Open Edit Device',

    uiOpenListUser: '[UI] Open List User',
    uiOpenAddUser: '[UI] Open Add User',
    uiOpenEditUser: '[UI] Open Edit User',

    uiOpenListDelivery: '[UI] Open List Delivery',
    uiOpenAddDelivery: '[UI] Open Add Delivery',
    uiOpenEditDelivery: '[UI] Open Edit Delivery',

    uiOpenListGroup: '[UI] Open List Group',
    uiOpenAddGroup: '[UI] Open Add Group',
    uiOpenEditGroup: '[UI] Open Edit Group',

    uiOpenListCustomer: '[UI] Open List Customer',
    uiOpenAddCustomer: '[UI] Open Add Customer',
    uiOpenEditCustomer: '[UI] Open Edit Customer',

    uiOpenListCustomerGroup: '[UI] Open List Customer Group',
    uiOpenAddCustomerGroup: '[UI] Open Add Custoemr Group ',
    uiOpenEditCustomerGroup: '[UI] Open Edit Customer Group',

    uiOpenAssociateCustomerGroupCustomer: '[UI] Open Associate Customer Group Customer',
    uiOpenAssociateCustomerGroupDevice: '[UI] Open Associate Customer Group Device',

    uiOpenListDeliveryRouteGroup: '[UI] Open List Delivery Group',
    uiOpenAddDeliveryRouteGroup: '[UI] Open Add Delivery Group',
    uiOpenEditDeliveryRouteGroup: '[UI] Open Edit Delivery Group',
    
    uiOpenAssociateDeliveryRouteGroupDelivery: '[UI] Open Associate Delivery Group Delivery',
    uiOpenAssociateDeliveryRouteGroupDevice: '[UI] Open Associate Delivery Group Device',

    uiOpenListPaymentMethodGroup: ['[UI] Open List Payment Method Group'],
    uiOpenAddPaymentMethodGroup: ['[UI] Open Add Payment Method Group'],
    uiOpenEditPaymentMethodGroup: ['[UI] Open Edit Payment Method Group'],
    
    uiOpenAssociatePaymentMethodGroupPaymentMethod: '[UI] Open Associate Payment Method Group Payment Method',
    uiOpenAssociatePaymentMethodGroupDevice: '[UI] Open Associate Payment Method Group Device',

    uiOpenListDiscount: '[UI] Open List Discount',
    uiOpenAddDiscount: '[UI] Open Add Discount',
    uiOpenEditDiscount: '[UI] Open Edit Discount',

    uiOpenListProductGroup: '[UI] Open List Product Group',
    uiOpenAddProductGroup: '[UI] Open Add Product Group',
    uiOpenEditProductGroup: '[UI] Open Edit Product Group',

    uiOpenAssociateProductGroupProduct: '[UI] Open Associate Product Group Product',
    uiOpenAssociateProductGroupDevice: '[UI] Open Associate Product Group Device',

    uiOpenListUserGroup: '[UI] Open List User Group',
    uiOpenAddUserGroup: '[UI] Open Add User Group',
    uiOpenEditUserGroup: '[UI] Open Edit User Group',

    uiOpenAssociateUserGroupUser: '[UI] Open Associate User Group User',
    uiOpenAssociateUserGroupDevice: '[UI] Open Associate User Group Device',

    uiOpenListDocument: '[UI] Open List Document',
    uiOpenAddDocument: '[UI] Open Add Document',
    uiOpenEditDocument: '[UI] Open Edit Document',
    uiOpenDocumentDetails: '[UI] Open Document Details',

    
    
    
    uiOpenListNCFRange: '[UI] Open List NCF Range',
    uiOpenAddNCFRange: '[UI] Open Add NCF Range',
    uiOpenEditNCFRange: '[UI] Open Edit NCF Range',
    
    uiOpenListNCFRangeGroup: '[UI] Open List NCF Range Group',
    uiOpenAddNCFRangeGroup: '[UI] Open Add NCF Range Group',
    uiOpenEditNCFRangeGroup: '[UI] Open Edit NCF Range Group',
    
    uiOpenAssociateNCFRangeGroupNCFRange: '[UI] Open Associate NCF Range Group NCF Range',
    uiOpenAssociateNCFRangeGroupDevice: '[UI] Open Associate NCF Range Group Device',
    
    uiOpenListPaymentMethod: '[UI] Open List Payment Method',
    uiOpenAddPaymentMethod: '[UI] Open Add Payment Method',
    uiOpenEditPaymentMethod: '[UI] Open Edit Payment Method',
    
    uiOpenListProduct: '[UI] Open List Product',
    uiOpenAddProduct: '[UI] Open Add Product',
    uiOpenEditProduct: '[UI] Open Edit Product',
    
    uiOpenListPrice: '[UI] Open List Price',
    uiOpenAddPrice: '[UI] Open Add Price',
    uiOpenEditPrice: '[UI] Open Edit Price',
    
    uiOpenListTax: '[UI] Open List Tax',
    uiOpenAddTax: '[UI] Open Add Tax',
    uiOpenEditTax: '[UI] Open Edit Tax',
    
    uiSidebarActive: '[UI] Sidebar active',
    
    uiCloseCurrentScreen: '[UI] Close current screen',
    
    editingUserInfo: '[USER] User Info',
    clearUserInfo: '[USER] Clear User Info',
    
    editingDeliveryInfo: '[DELIVERY] Delivery Info',
    clearDeliveryInfo: '[DELIVERY] Delivery Info',
    
    editingGroupInfo: '[GROUP] Group Info',
    clearGroupInfo: '[GROUP] Group Info',
    
    editingCustomerInfo: '[CUSTOMER] Customer Info',
    clearCustomerInfo: '[CUSTOMER] Customer Info',
    
    editingDocumentInfo: '[DOCUMENT] Document Info',
    clearDocumentInfo: '[DOCUMENT] Document Info',
    
    editingNCFRangeInfo: '[NCF RANGE] NCF Range Info',
    clearNCFRangeInfo: '[NCF RANGE] NCF Range Info',
    
    editingPaymentMethodInfo: '[PAYMENT METHOD] Payment Method Edit',
    clearPaymentMethodInfo: '[PAYMENT METHOD] Payment method Info Clear',
    
    editingDiscountInfo: '[DISCOUNT] Discount Edit',
    clearDiscountInfo: '[DISCOUNT] Discount Info Clear',
    
    editingProductInfo: '[PRODUCT] Product Edit',
    clearProductInfo: '[PRODUCT] Product Info Clear',
    
    editingPriceInfo: '[PRICE] Price Edit',
    clearPriceInfo: '[PRICE] Price Info Clear',
    
    editingTaxInfo: '[TAX] Tax Edit',
    clearTaxInfo: '[TAX] Tax Info Clear',
    
    
    editingCustomerGroupInfo: '[CUSTOMER GROUP] Customer Group Edit',
    clearedCustomerGroupInfo: '[CUSTOMER GROUP] Customer Group Info Clear',
    
    editingCustomerGroupCustomerInfo: '[CUSTOMER GROUP CUSTOMER] Customer Group Customer Edit',
    clearedCustomerGroupCustomerInfo: '[CUSTOMER GROUP CUSTOMER] Customer Group Customer Info Clear',
    
    editingCustomerGroupDeviceInfo: '[CUSTOMER GROUP DEVICE] Customer Group Device Edit',
    clearedCustomerGroupDeviceInfo: '[CUSTOMER GROUP DEVICE] Customer Group Device Info Clear',
    
    editingDeliveryGroupInfo: '[DELIVERY GROUP] Delivery Group Edit',
    clearedDeliveryGroupInfo: '[DELIVERY GROUP] Delivery Group Info Clear',
    
    editingDeliveryGroupDeliveryInfo: '[DELIVERY GROUP DELIVERY] Delivery Group Delivery Edit',
    clearedDeliveryGroupDeliveryInfo: '[DELIVERY GROUP DELIVERY] Delivery Group Delivery Info Clear',
    
    editingDeliveryGroupDeviceInfo: '[DELIVERY GROUP DEVICE] Delivery Group Device Edit',
    clearedDeliveryGroupDeviceInfo: '[DELIVERY GROUP DEVICE] Delivery Group Device Info Clear',
    
    editingPaymentMethodGroupInfo: '[PAYMENT METHOD GROUP] Payment Method Group Edit',
    clearedPaymentMethodGroupInfo: '[PAYMENT METHOD GROUP] Payment Method Group Info Clear',
    
    editingPaymentMethodGroupPaymentMethodInfo: '[PAYMENT METHOD GROUP PAYMENT METHOD] Payment Method Group Payment Method Edit',
    clearedPaymentMethodGroupPaymentMethodInfo: '[PAYMENT METHOD GROUP PAYMENT METHOD] Payment Method Group Payment Method Info Clear',
    
    editingPaymentMethodGroupDeviceInfo: '[PAYMENT METHOD GROUP DEVICE] Payment Method Group Device Edit',
    clearedPaymentMethodGroupDeviceInfo: '[PAYMENT METHOD GROUP DEVICE] Payment Method Group Device Info Clear',
    
    editingNCFRangeGroupInfo: '[NCF RANGE GROUP] NCF Range Group Edit',
    clearedNCFRangeGroupInfo: '[NCF RANGE GROUP] NCF Range Group Info Clear',
    
    editingNCFRangeGroupNCFRangeInfo: '[NCF RANGE GROUP NCF RANGE] NCF Range Group NCF Range Edit',
    clearedNCFRangeGroupNCFRangeInfo: '[NCF RANGE GROUP NCF RANGE] NCF Range Group NCF Range Info Clear',
    
    editingNCFRangeGroupDeviceInfo: '[NCF RANGE GROUP DEVICE] NCF Range Group Device Edit',
    clearedNCFRangeGroupDeviceInfo: '[NCF RANGE GROUP DEVICE] NCF Range Group Device Info Clear',
    
    editingProductGroupInfo: '[PRODUCT] Product Group Edit',
    clearedProductGroupInfo: '[PRODUCT] Product Group Info Clear',
    
    editingProductGroupProductInfo: '[PRODUCT] Product Group Product Edit',
    clearedProductGroupProductInfo: '[PRODUCT] Product Group Product Info Clear',
    
    editingProductGroupDeviceInfo: '[PRODUCT] Product Group Device Edit',
    clearedProductGroupDeviceInfo: '[PRODUCT] Product Group Device Info Clear',
    
    editingUserGroupInfo: '[USER] User Group Edit',
    clearedUserGroupInfo: '[USER] User Group Info Clear',
    
    editingUserGroupUserInfo: '[USER] user Group User Edit',
    clearedUserGroupUserInfo: '[USER] user Group User Info Clear',
    
    editingUserGroupDeviceInfo: '[USER] User Group Device Edit',
    clearedUserGroupDeviceInfo: '[USER] User Group Device Info Clear',
    
    uiLoading: '[LOADING] Loading',
    
    deleteDevice: '[DEVICE] Delete Device',
    
    deleteUser: '[USER] Delete User',
    
    deleteGroup: '[GROUP] Delete Group',
    
    deleteCustomer: '[CUSTOMER] Delete Customer',
    
    deleteDocument: '[DOCUMENT] Delete Document',
    
    deleteNCFRange: '[NCF RANGE] Delete NCF Range',
    
    deletePaymentMethod: '[PAYMENT METHOD] Delete Payment Method',
    
    deleteProduct: '[PRODUCT] Delete Product',
    
    deletePrice: '[PRICE] Delete Price',
    
    deleteTax: '[TAX] Delete Tax',
    
    deleteDelivery: '[TAX] Delete Delivery',
    
    deleteCustomerGroup: '[CUSTOMER GROUP] Delete Customer Group',
    
    
    refreshKey: '[REFRESH KEY] Refresh Key',
    login: '[AUTH] Login',
    logout: '[AUTH] Logout',
    setUsername: '[AUTH] Set Username',
    setPermissions: '[AUTH] Set Permissions',
    setRealm: '[AUTH] Set Realm',
    setBusinessName: '[AUTH] Set Business Name',
    documentDetails: '[DOCUMENT] Set Document Details',
    uiLogout: '[UI] UI Logout',
    
    //Tipos
    uiOpenListMeasurementUnit: '[UI] Open List Measurement Unit',
    uiOpenAddMeasurementUnit: '[UI] Open Add Measurement Unit',
    uiOpenEditMeasurementUnit: '[UI] Open Edit Measurement Unit',

    
    deleteMeasurementUnitInfo: '[MEASUREMENT UNIT] Delete Measurment Unit',
    editingMeasurementUnitInfo: '[MEASUREMENT UNIT] Measurment Unit Edit',
    clearMeasurementUnitInfo: '[MEASUREMENT UNIT] Measurment Unit Info Clear',

    uiOpenListWarehouse: '[UI] Open List Warehouse',
    uiOpenAddWarehouse: '[UI] Open Add Warehouse',
    uiOpenEditWarehouse: '[UI] Open Edit Warehouse',

    deleteWarehouseInfo: '[WAREHOUSE] Delete Warehouse',
    editingWarehouseInfo: '[WAREHOUSE] Warehouse Edit',
    clearWarehouseInfo: '[WAREHOUSE] Warehouse Info Clear',


    uiOpenListWarehouseGroup: '[UI] Open List WarehouseGroup',
    uiOpenAddWarehouseGroup: '[UI] Open Add WarehouseGroup',
    uiOpenEditWarehouseGroup: '[UI] Open Edit WarehouseGroup',

    deleteWarehouseGroupInfo: '[WAREHOUSEGROUP] Delete WarehouseGroup',
    editingWarehouseGroupInfo: '[WAREHOUSEGROUP] WarehouseGroup Edit',
    clearWarehouseGroupInfo: '[WAREHOUSEGROUP] WarehouseGroup Info Clear',
    /* editingWarehouseGroupDeviceInfo: '[WAREHOUSEGROUP] WarehouseGroup Group Device Edit', */
    
    uiOpenAssociateWarehouseGroupWarehouse: '[UI] Open Associate Warehouse Group Warehouse',

    editingWarehouseGroupWarehouseInfo: '[WAREHOUSE GROUP WAREHOUSE] Warehouse Group Warehouse Edit',
    clearedWarehouseGroupWarehouseInfo: '[WAREHOUSE GROUP WAREHOUSE] Warehouse Group Warehouse Info Clear',
   

    
    uiOpenAssociateWarehouseGroupDevice: '[UI] Open Associate Warehouse Group Device',
    
    editingWarehouseGroupDeviceInfo: '[WAREHOUSE GROUP DEVICE] Warehouse Group Device Edit',
    clearedWarehouseGroupDeviceInfo: '[WAREHOUSE GROUP DEVICE] Warehouse Group Device Info Clear',


    uiOpenProductDetails: '[UI] Open Product Details',
    productDetails: '[PRODUCT] Set Product Details',
   
};