import React, { useState, useEffect } from 'react';
import { Button, DatePicker, Form, Input, Switch,Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { clearDiscountInfo, openScreen } from '../../actions/ui';
import { addOrUpdate } from '../../hooks/fetchHook';
import moment from 'moment';
import { MeasurementUnitListBox } from './MeasurementUnitListBox';
import { CustomerListBox } from './CustomerListBox';
import { getTableData } from '../../hooks/fetchHook';

export const EditDiscountScreen = () => {

    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const isLoading = useSelector(state => state.ui.loading);
    const editingDiscount = useSelector(state => state.ui.editingDiscount);
    const isSidebarActive = useSelector(state => state.ui.sidebar);
    const realm = useSelector(state => state.auth.realm);
    const [formValues, setFormValues] = useState({
      ...editingDiscount,
      validFrom:moment(editingDiscount.validFrom),
      validUntil: moment(editingDiscount.validUntil),

    });
    const [form] = Form.useForm();

    const layout = {
        labelCol: { span: 10 },
        wrapperCol: { span: 100 }
    };

    const handleInputchange = ({ target }) => {
        setFormValues({
            ...formValues,
            [target.id]: target.value,
            realmFk: realm
        })
    };

    const handleSave = async () => {
        try {
            await form.validateFields()
                .then(() => {
                    dispatch(addOrUpdate(formValues, 'discount'))
                    dispatch(clearDiscountInfo())
                    form.resetFields()
                })
        }
        catch (err) { }
    }

    const handleDiscart = () => {
        form.resetFields()
        dispatch(clearDiscountInfo())
        dispatch(openScreen('uiOpenListDiscount'))
    };

    const handdleStatus = (e) => {
        setFormValues({
            ...formValues,
            status: e
        })
    };

    const handleDateFrom = (date, dateString) => {
        setFormValues({
            ...formValues,
            validFrom: date,
        })
    }

    const handleDateUntil = (date, dateString) => {
        setFormValues({
            ...formValues,
            validUntil: date,
        })
    }

    const handdleSelect = (e) => {
        setFormValues({
            ...formValues,
            productFk: e
        })

    };
    
    useEffect(() => {
        dispatch(getTableData('product', realm))
            .then((response) => {
                setData(response)



            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    let children = [];
    const { Option } = Select;

    for (const prop of data) {
        children.push(<Option key={prop.id}>{prop.name}</Option>)
    };

    return (
        <div>
            <div className={isSidebarActive ? '_OCS-overlay' : '_overlay'}></div>
            <div className='_header-container3'>
                <div className={isSidebarActive ? '_OCS-screen-header' : '_screen-header'}>
                    <h2>Editar Descuento</h2>
                </div>
                <div className={isSidebarActive ? "_btn2-1_" : "_btn2_"}>
          <div className="_btns-container2">
            <Button className="_btn-primary _ant-add-btn " onClick={handleDiscart} type="primary">
              Volver
            </Button>
            </div>
          </div>
            </div>
            <div className={isSidebarActive ? '_OCS-ant-card-body _OCS-ant-card-form-group' : '_ant-card-body _ant-card-form-group'}>
                <div className='_ant-card-header'>Información Básica</div>
                <Form
                    form={form}
                    layout='vertical'
                    {...layout}
                    name="discountForm"
                    initialValues={{
                        id: [editingDiscount.id],
                    }}
                >
                    <Form.Item
                        label="ID"
                        name="id"
                    >
                        <Input
                            disabled
                            id='id'
                            onChange={handleInputchange}
                            size='large'
                        />
                    </Form.Item>

                    <CustomerListBox
                        handler={handleInputchange}
                        initialValue={editingDiscount.customerName}
                    />

                    {/* <Form.Item
                        label="ID Producto"
                        name="productFk"
                        rules={[
                            {
                                required: true,
                                message: 'Favor ingresar ID del Cliente',
                            },
                        ]}
                    >
                        <Input
                            id='productFk'
                            onChange={handleInputchange}
                            size='large'
                        />
                    </Form.Item> */}

                    <Form.Item
                        label="Producto"
                        name="productFk"
                        rules={[
                            {
                                required: true,
                                message: 'Favor seleccionar producto',
                            }
                        ]}
                        initialValue={editingDiscount.productName}
                        >
                        <Select
                            showSearch
                            onChange={handdleSelect}
                            allowClear
                        >{children}</Select>
                    </Form.Item>

                    <Form.Item
                        label="Válido Desde"
                        name="validFrom"
                        required
                    >
                        <DatePicker
                            onChange={handleDateFrom}
                            format="YYYY-MM-DD HH:mm:ss"
                            defaultValue={moment(editingDiscount.validFrom)}
                            showTime
                        />
                    </Form.Item>

                    <Form.Item
                        label="Válido Hasta"
                        name="validUntil"
                        required
                    >
                        <DatePicker
                            onChange={handleDateUntil}
                            format="YYYY-MM-DD HH:mm:ss"
                            defaultValue={moment(editingDiscount.validUntil)}
                            showTime
                        />
                    </Form.Item>
                </Form>
            </div>
            <div className={isSidebarActive ? '_OCS-ant-card-body _OCS-ant-card-form-group' : '_ant-card-body _ant-card-form-group'}>
                <div className='_ant-card-header'>Informacion del Recipiente</div>
                <div className='_ant-form-wrapper'>
                    <div>
                        <Form
                            form={form}
                            {...layout}
                            layout='vertical'
                            name="deviceInfoForm"
                        >
                            <Form.Item
                                label="Estatus"
                                name="status"
                                valuePropName='checked'
                            >
                                <Switch defaultChecked onChange={handdleStatus} checkedChildren="Activo" unCheckedChildren="Inactivo"></Switch>
                            </Form.Item>

                            <MeasurementUnitListBox handler={handleInputchange} initialValue={editingDiscount.measurementUnit} />

                        </Form>
                    </div>

                    <div>
                        <Form
                            form={form}
                            {...layout}
                            layout='vertical'
                            name="discountForm"
                            initialValues={{
                                flat: [editingDiscount.flat],
                                percentage: [editingDiscount.percentage]
                            }}
                        >
                            <Form.Item
                                label="Monto"
                                name="flat"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Favor ingresar porcentaje',
                                    },
                                ]}
                            >
                                <Input size='large' onChange={handleInputchange} id="flat" />
                            </Form.Item>

                            <Form.Item
                                label="Procentaje"
                                name="percentage"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Favor ingresar porcentaje',
                                    },
                                ]}
                            >
                                <Input
                                    id='percentage'
                                    onChange={handleInputchange}
                                    size='large'
                                />
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </div>
            <div  className={
            isSidebarActive
              ? "_OCS-ant-card-body _OCS-ant-card-form-group _btn-save-container"
              : "_ant-card-body _ant-card-form-group _btn-save-container"
          }>
          <div >  
          <Button
              className="_btn-primary _ant-add-btn "
              loading={isLoading}
              onClick={handleSave}
              type="primary"
            >
              Guardar
            </Button>
            </div>
        </div>
        </div>
    )
};