import { types } from '../types/types';

const initialState = {
    openScreen: 'uiOpenDashboard',
    closeScreen: false,
    deleting: false,
    sidebar: false,
    editingUser: {
        code: "",
        id: 0,
        name: "",
        password: "",
        permission: "",
        realmFk: "",
        status: true,
        userName: "",
    },
    editingDelivery: {
        id: 0,
        customerFk: 0,
        status: true,
        ordinal: 0,
        message: "",
        attended: "",
        process: 0,
        label: "",
        realmFk: ""
    },
    editingGroup: {
        id: 0,
        groupName: "",
        shortName: "",
        createdAt: "",
        members: "",
        activeMembers: "",
    },
    editingCustomer: {
        abbreviation: "",
        address: "",
        addressCode: "",
        code: "",
        concept: "",
        creditLimit: "",
        discountCount: "",
        documentType: "",
        id: 0,
        itsWarehouse: true,
        latitud: "",
        longitud: "",
        name: "",
        priceArea: "",
        radio: "",
        realmFk: "",
        rnc: "",
        socialReason: "",
        status: true,
        tel: "",
        typeNcf: "",
    },
    editingDocument: {
        id: 0,
        name: "",
        department: "",
        noPages: "",
        type: "",
        createdAt: ""
    },
    editingPaymentMethod: {
        id: 0,
        name: "",
        ordinal: 0,
        strategyHandler: "",
        referenceUsed: true,
        singlePayment: true,
        realmFk: "",
        status: true
    },
    editingProduct: {
        id: 0,
        code: "",
        existence: true,
        formatter: "",
        freightChargeFk: "",
        name: "",
        ordinal: "",
        realmFk: "",
        status: true,
        visible: true,
    },
    editingPrice: {
        id: 0,
        realmFk: "",
        validFrom: "",
        validUntil: "",
        amount: 0,
        productFk: 0,
        zone: 0,
        measurementUnit: "",
        status: true,
        type: 0
    },
    editingTax: {
        id: 0,
        name: "",
        code: "",
        realmFk: "",
        status: true,
    },
    editingCustomerGroup: {
        id: 0,
        name: "",
        realmFk: "",
        description: "",
        status: true,
    },
    editingCustomerGroupCustomer: {
        groupFk: "",
        realmFk: ""
    },
    editingCustomerGroupDevice: {
        groupFk: "",
        realmFk: ""
    },
    editingNCFRange: {
        id: 0,
        typeNcf: 0,
        validUntil: "",
        startSequence: 0,
        finalSequence: 0,
        ordinal: 0,
        taxPrefix: "",
        status: true
    },
    editingNCFRangeGroup: {
        id: 0,
        name: "",
        realmFk: "",
        description: "",
        status: true,
    },
    editingNCFRangeGroupNCFRange: {
        groupFk: "",
        realmFk: ""
    },
    editingNCFRangeGroupDevice: {
        groupFk: "",
        realmFk: ""
    },
    editingDeliveryGroup: {
        id: 0,
        name: "",
        realmFk: "",
        description: "",
        status: true,
    },
    editingDeliveryGroupDelivery: {
        groupFk: "",
        realmFk: ""
    },
    editingDeliveryGroupDevice: {
        groupFk: "",
        realmFk: ""
    },
    editingPaymentMethodGroup: {
        id: 0,
        name: "",
        realmFk: "",
        description: "",
        status: true,
    },
    editingPaymentMethodGroupPaymentMethod: {
        groupFk: "",
        realmFk: ""
    },
    editingPaymentMethodGroupDevice: {
        groupFk: "",
        realmFk: ""
    },
    editingDiscount: {
        id: 0,
        customerFk: 0,
        productFk: 0,
        measurementUnit: "",
        percentage: 0,
        flat: 0,
        validFrom: "",
        validUntil: "",
        realmFk: "",
        status: true
    },
    editingProductGroup: {
        id: 0,
        name: "",
        realmFk: "",
        description: "",
        status: true,
    },
    editingProductGroupProduct: {
        groupFk: "",
        realmFk: ""
    },
    editingProductGroupDevice: {
        groupFk: "",
        realmFk: ""
    },
    editingUserGroup: {
        id: 0,
        name: "",
        realmFk: "",
        description: "",
        status: true,
    },
    editingUserGroupUser: {
        groupFk: "",
        realmFk: ""
    },
    editingUserGroupDevice: {
        groupFk: "",
        realmFk: ""
    },
    //Ejemplo entidad
    editingMeasurementUnit: {
        id: 0,
        name: "",
        realmFk: "",
        status: true,
    },
  

    //Almacenes
    editingWarehouse: {
        id: 0,
        name: "",
        realmFk: "",
        status: true,
    },  



    editingWarehouseGroup: {
        id: 0,
        name: "",
        realmFk: "",
        status: true,
    }, 
    refreshKey: 0,
    loading: false,
    

}



export const uiReducer = (state = initialState, action) => {
    
    switch (action.type) {
        case types.uiOpenDashboard:
            return {
                ...state,
                openScreen: action.payload,
                closeScreen: false
            }

            case types.uiLogout:
            return {
                state: initialState
            }

        case types.uiOpenListDevice:
            return {
                ...state,
                openScreen: action.payload,
                closeScreen: false
            }

        case types.uiOpenAddDevice:
            return {
                ...state,
                openScreen: action.payload,
                closeScreen: false
            }

        case types.uiOpenEditDevice:
            return {
                ...state,
                openScreen: action.payload,
                closeScreen: false
            }

        case types.uiOpenListUser:
            return {
                ...state,
                openScreen: action.payload,
                closeScreen: false
            }

        case types.uiOpenAddUser:
            return {
                ...state,
                openScreen: action.payload,
                closeScreen: false
            }

        case types.uiOpenEditUser:
            return {
                ...state,
                openScreen: action.payload,
                closeScreen: false
            }

        case types.uiOpenListDelivery:
            return {
                ...state,
                openScreen: action.payload,
                closeScreen: false
            }

        case types.uiOpenAddDelivery:
            return {
                ...state,
                openScreen: action.payload,
                closeScreen: false
            }

        case types.uiOpenEditDelivery:
            return {
                ...state,
                openScreen: action.payload,
                closeScreen: false
            }

        case types.uiOpenListGroup:
            return {
                ...state,
                openScreen: action.payload,
                closeScreen: false
            }

        case types.uiOpenAddGroup:
            return {
                ...state,
                openScreen: action.payload,
                closeScreen: false
            }

        case types.uiOpenEditGroup:
            return {
                ...state,
                openScreen: action.payload,
                closeScreen: false
            }

        case types.uiOpenListCustomer:
            return {
                ...state,
                openScreen: action.payload,
                closeScreen: false
            }

        case types.uiOpenAddCustomer:
            return {
                ...state,
                openScreen: action.payload,
                closeScreen: false
            }

        case types.uiOpenEditCustomer:
            return {
                ...state,
                openScreen: action.payload,
                closeScreen: false
            }

        case types.uiOpenListCustomerGroup:
            return {
                ...state,
                openScreen: action.payload,
                closeScreen: false
            }

        case types.uiOpenAddCustomerGroup:
            return {
                ...state,
                openScreen: action.payload,
                closeScreen: false
            }

        case types.uiOpenEditCustomerGroup:
            return {
                ...state,
                openScreen: action.payload,
                closeScreen: false
            }

        case types.uiOpenAssociateCustomerGroupCustomer:
            return {
                ...state,
                openScreen: action.payload,
                closeScreen: false
            }

        case types.uiOpenAssociateCustomerGroupDevice:
            return {
                ...state,
                openScreen: action.payload,
                closeScreen: false
            }

        case types.uiOpenListDeliveryRouteGroup:
            return {
                ...state,
                openScreen: action.payload,
                closeScreen: false
            }

        case types.uiOpenAddDeliveryRouteGroup:
            return {
                ...state,
                openScreen: action.payload,
                closeScreen: false
            }

        case types.uiOpenEditDeliveryRouteGroup:
            return {
                ...state,
                openScreen: action.payload,
                closeScreen: false
            }

        case types.uiOpenAssociateDeliveryRouteGroupDelivery:
            return {
                ...state,
                openScreen: action.payload,
                closeScreen: false
            }

        case types.uiOpenAssociateDeliveryRouteGroupDevice:
            return {
                ...state,
                openScreen: action.payload,
                closeScreen: false
            }

        case types.uiOpenListPaymentMethodGroup:
            return {
                ...state,
                openScreen: action.payload,
                closeScreen: false
            }

        case types.uiOpenAddPaymentMethodGroup:
            return {
                ...state,
                openScreen: action.payload,
                closeScreen: false
            }

        case types.uiOpenEditPaymentMethodGroup:
            return {
                ...state,
                openScreen: action.payload,
                closeScreen: false
            }

        case types.uiOpenAssociatePaymentMethodGroupPaymentMethod:
            return {
                ...state,
                openScreen: action.payload,
                closeScreen: false
            }

        case types.uiOpenAssociatePaymentMethodGroupDevice:
            return {
                ...state,
                openScreen: action.payload,
                closeScreen: false
            }

        case types.uiOpenListNCFRangeGroup:
            return {
                ...state,
                openScreen: action.payload,
                closeScreen: false
            }

        case types.uiOpenAddNCFRangeGroup:
            return {
                ...state,
                openScreen: action.payload,
                closeScreen: false
            }

        case types.uiOpenEditNCFRangeGroup:
            return {
                ...state,
                openScreen: action.payload,
                closeScreen: false
            }

        case types.uiOpenAssociateNCFRangeGroupNCFRange:
            return {
                ...state,
                openScreen: action.payload,
                closeScreen: false
            }

        case types.uiOpenAssociateNCFRangeGroupDevice:
            return {
                ...state,
                openScreen: action.payload,
                closeScreen: false
            }

        case types.uiOpenListProductGroup:
            return {
                ...state,
                openScreen: action.payload,
                closeScreen: false
            }

        case types.uiOpenAddProductGroup:
            return {
                ...state,
                openScreen: action.payload,
                closeScreen: false
            }

        case types.uiOpenEditProductGroup:
            return {
                ...state,
                openScreen: action.payload,
                closeScreen: false
            }

        case types.uiOpenAssociateProductGroupProduct:
            return {
                ...state,
                openScreen: action.payload,
                closeScreen: false
            }

        case types.uiOpenAssociateProductGroupDevice:
            return {
                ...state,
                openScreen: action.payload,
                closeScreen: false
            }

        case types.uiOpenListUserGroup:
            return {
                ...state,
                openScreen: action.payload,
                closeScreen: false
            }

        case types.uiOpenAddUserGroup:
            return {
                ...state,
                openScreen: action.payload,
                closeScreen: false
            }

        case types.uiOpenEditUserGroup:
            return {
                ...state,
                openScreen: action.payload,
                closeScreen: false
            }

        case types.uiOpenAssociateUserGroupUser:
            return {
                ...state,
                openScreen: action.payload,
                closeScreen: false
            }

        case types.uiOpenAssociateUserGroupDevice:
            return {
                ...state,
                openScreen: action.payload,
                closeScreen: false
            }

        case types.uiOpenListDocument:
            return {
                ...state,
                openScreen: action.payload,
                closeScreen: false
            }

        case types.uiOpenAddDocument:
            return {
                ...state,
                openScreen: action.payload,
                closeScreen: false
            }

        case types.uiOpenEditDocument:
            return {
                ...state,
                openScreen: action.payload,
                closeScreen: false
            }

        case types.uiOpenDocumentDetails:
            return {
                ...state,
                openScreen: action.payload,
                closeScreen: false
            }

        case types.uiOpenListNCFRange:
            return {
                ...state,
                openScreen: action.payload,
                closeScreen: false
            }

        case types.uiOpenAddNCFRange:
            return {
                ...state,
                openScreen: action.payload,
                closeScreen: false
            }

        case types.uiOpenEditNCFRange:
            return {
                ...state,
                openScreen: action.payload,
                closeScreen: false
            }

        case types.uiOpenListPaymentMethod:
            return {
                ...state,
                openScreen: action.payload,
                closeScreen: false
            }

        case types.uiOpenAddPaymentMethod:
            return {
                ...state,
                openScreen: action.payload,
                closeScreen: false
            }

        case types.uiOpenEditPaymentMethod:
            return {
                ...state,
                openScreen: action.payload,
                closeScreen: false
            }

        case types.uiOpenListDiscount:
            return {
                ...state,
                openScreen: action.payload,
                closeScreen: false
            }

        case types.uiOpenAddDiscount:
            return {
                ...state,
                openScreen: action.payload,
                closeScreen: false
            }

        case types.uiOpenEditDiscount:
            return {
                ...state,
                openScreen: action.payload,
                closeScreen: false
            }

        case types.uiOpenListProduct:
            return {
                ...state,
                openScreen: action.payload,
                closeScreen: false
            }

        case types.uiOpenAddProduct:
            return {
                ...state,
                openScreen: action.payload,
                closeScreen: false
            }

        case types.uiOpenEditProduct:
            return {
                ...state,
                openScreen: action.payload,
                closeScreen: false
            }

        case types.uiOpenListTax:
            return {
                ...state,
                openScreen: action.payload,
                closeScreen: false
            }

        case types.uiOpenAddTax:
            return {
                ...state,
                openScreen: action.payload,
                closeScreen: false
            }

        case types.uiOpenEditTax:
            return {
                ...state,
                openScreen: action.payload,
                closeScreen: false
            }

        case types.uiOpenListPrice:
            return {
                ...state,
                openScreen: action.payload,
                closeScreen: false
            }

        case types.uiOpenAddPrice:
            return {
                ...state,
                openScreen: action.payload,
                closeScreen: false
            }

        case types.uiOpenEditPrice:
            return {
                ...state,
                openScreen: action.payload,
                closeScreen: false
            }

        case types.uiCloseCurrentScreen:
            return {
                ...state,
                closeScreen: action.payload
            }

        case types.uiSidebarActive:
            return {
                ...state,
                sidebar: action.payload
            }

        case types.uiLoading:
            return {
                ...state,
                loading: action.payload
            }

        case types.editingUserInfo: {
            return {
                ...state,
                editingUser: action.payload
            }
        }
        case types.deleteUser: {
            return {
                ...state
            }
        }
        case types.clearUserInfo: {
            return {
                ...state,
                editingUser: action.payload
            }
        }
        case types.deleteDelivery: {
            return {
                ...state
            }
        }
        case types.editingGroupInfo: {
            return {
                ...state,
                editingGroup: action.payload
            }
        }

        case types.deleteGroup: {
            return {
                ...state
            }
        }
        case types.clearGroupInfo: {
            return {
                ...state,
                editingGroup: action.payload
            }
        }
        case types.editingCustomerInfo: {
            return {
                ...state,
                editingCustomer: action.payload
            }
        }
        case types.deleteCustomer: {
            return {
                ...state
            }
        }
        case types.clearCustomerInfo: {
            return {
                ...state,
                editingCustomer: action.payload
            }
        }

        case types.editingDocumentInfo: {
            return {
                ...state,
                editingDocument: action.payload
            }
        }

        case types.deleteDocument: {
            return {
                ...state
            }
        }
        case types.clearDocumentInfo: {
            return {
                ...state,
                editingDocument: action.payload
            }
        }

        case types.editingNCFRangeInfo: {
            return {
                ...state,
                editingNCFRange: action.payload
            }
        }
        case types.deleteNCFRange: {
            return {
                ...state
            }
        }
        case types.clearNCFRangeInfo: {
            return {
                ...state,
                editingNCFRange: action.payload
            }
        }
        case types.editingPaymentMethodInfo: {
            return {
                ...state,
                editingPaymentMethod: action.payload
            }
        }
        case types.clearPaymentMethodInfo: {
            return {
                ...state,
                editingPaymentMethod: action.payload
            }
        }

        case types.editingDiscountInfo: {
            return {
                ...state,
                editingDiscount: action.payload
            }
        }
        case types.clearDiscountInfo: {
            return {
                ...state,
                editingDiscount: action.payload
            }
        }

        case types.editingProductInfo: {
            return {
                ...state,
                editingProduct: action.payload
            }
        }
        case types.clearProductInfo: {
            return {
                ...state,
                editingProduct: action.payload
            }
        }

        case types.editingPriceInfo: {
            return {
                ...state,
                editingPrice: action.payload
            }
        }
        case types.clearPriceInfo: {
            return {
                ...state,
                editingPrice: action.payload
            }
        }

        case types.editingTaxInfo: {
            return {
                ...state,
                editingTax: action.payload
            }
        }
        case types.clearTaxInfo: {
            return {
                ...state,
                editingTax: action.payload
            }
        }
        case types.editingDeliveryInfo: {
            return {
                ...state,
                editingDelivery: action.payload
            }
        }
        case types.clearDeliveryInfo: {
            return {
                ...state,
                editingDelivery: action.payload
            }
        }

        case types.editingCustomerGroupInfo: {
            return {
                ...state,
                editingCustomerGroup: action.payload
            }
        }
        case types.clearedCustomerGroupInfo: {
            return {
                ...state,
                editingCustomerGroup: action.payload
            }
        }
        case types.editingCustomerGroupCustomerInfo: {
            return {
                ...state,
                editingCustomerGroupCustomer: action.payload
            }
        }
        case types.clearedCustomerGroupCustomerInfo: {
            return {
                ...state,
                editingCustomerGroupCustomer: action.payload
            }
        }

        case types.editingCustomerGroupDeviceInfo: {
            return {
                ...state,
                editingCustomerGroupDevice: action.payload
            }
        }
        case types.clearedCustomerGroupDeviceInfo: {
            return {
                ...state,
                editingCustomerGroupDevice: action.payload
            }
        }

        case types.editingNCFRangeGroupInfo: {
            return {
                ...state,
                editingNCFRangeGroup: action.payload
            }
        }
        case types.clearedNCFRangeGroupInfo: {
            return {
                ...state,
                editingNCFRangeGroup: action.payload
            }
        }
        case types.editingNCFRangeGroupNCFRangeInfo: {
            return {
                ...state,
                editingNCFRangeGroupNCFRange: action.payload
            }
        }
        case types.clearedNCFRangeGroupNCFRangeInfo: {
            return {
                ...state,
                editingNCFRangeGroupNCFRange: action.payload
            }
        }

        case types.editingNCFRangeGroupDeviceInfo: {
            return {
                ...state,
                editingNCFRangeGroupDevice: action.payload
            }
        }
        case types.clearedNCFRangeGroupDeviceInfo: {
            return {
                ...state,
                editingNCFRangeGroupDevice: action.payload
            }
        }

        case types.editingDeliveryGroupInfo: {
            return {
                ...state,
                editingDeliveryGroup: action.payload
            }
        }
        case types.clearedDeliveryGroupInfo: {
            return {
                ...state,
                editingDeliveryGroup: action.payload
            }
        }

        case types.editingDeliveryGroupDeliveryInfo: {
            return {
                ...state,
                editingDeliveryGroupDelivery: action.payload
            }
        }
        case types.clearedDeliveryGroupDeliveryInfo: {
            return {
                ...state,
                editingDeliveryGroupDelivery: action.payload
            }
        }

        case types.editingDeliveryGroupDeviceInfo: {
            return {
                ...state,
                editingDeliveryGroupDevice: action.payload
            }
        }

        case types.clearedDeliveryGroupDeviceInfo: {
            return {
                ...state,
                editingDeliveryGroupDevice: action.payload
            }
        }

        case types.editingPaymentMethodGroupInfo: {
            return {
                ...state,
                editingPaymentMethodGroup: action.payload
            }
        }

        case types.clearedPaymentMethodGroupInfo: {
            return {
                ...state,
                editingPaymentMethodGroup: action.payload
            }
        }

        case types.editingPaymentMethodGroupPaymentMethodInfo: {
            return {
                ...state,
                editingPaymentMethodGroupPaymentMethod: action.payload
            }
        }
        case types.clearedPaymentMethodGroupPaymentMethodInfo: {
            return {
                ...state,
                editingPaymentMethodGroupPaymentMethod: action.payload
            }
        }

        case types.editingPaymentMethodGroupDeviceInfo: {
            return {
                ...state,
                editingPaymentMethodGroupDevice: action.payload
            }
        }
        case types.clearedPaymentMethodGroupDeviceInfo: {
            return {
                ...state,
                editingPaymentMethodGroupDevice: action.payload
            }
        }

        case types.editingProductGroupInfo: {
            return {
                ...state,
                editingProductGroup: action.payload
            }
        }

        case types.clearedProductGroupInfo: {
            return {
                ...state,
                editingProductGroup: action.payload
            }
        }

        case types.editingProductGroupProductInfo: {
            return {
                ...state,
                editingProductGroupProduct: action.payload
            }
        }
        case types.clearedProductGroupProductInfo: {
            return {
                ...state,
                editingProductGroupProduct: action.payload
            }
        }

        case types.editingProductGroupDeviceInfo: {
            return {
                ...state,
                editingProductGroupDevice: action.payload
            }
        }

        case types.clearedProductGroupDeviceInfo: {
            return {
                ...state,
                editingProductGroupDevice: action.payload
            }
        }

        case types.editingUserGroupInfo: {
            return {
                ...state,
                editingUserGroup: action.payload
            }
        }

        case types.clearedUserGroupInfo: {
            return {
                ...state,
                editingUserGroup: action.payload
            }
        }

        case types.editingUserGroupUserInfo: {
            return {
                ...state,
                editingUserGroupUser: action.payload
            }
        }
        case types.clearedUserGroupUserInfo: {
            return {
                ...state,
                editingUserGroupUser: action.payload
            }
        }

        case types.editingUserGroupDeviceInfo: {
            return {
                ...state,
                editingUserGroupDevice: action.payload
            }
        }

        case types.clearedUserGroupDeviceInfo: {
            return {
                ...state,
                editingUserGroupDevice: action.payload
            }
        }
        case types.refreshKey: {
            return {
                ...state,
                refreshKey: action.payload
            }
        }
        
        case types.documentDetails: {
            return {
                ...state,
                documentDetails: action.payload
            }
        }

        //Rutas
        case types.uiOpenListMeasurementUnit: {
            return {
                ...state,
                openScreen: action.payload,
                closeScreen: false
            }
        }

        case types.uiOpenAddMeasurementUnit: {
            return {
                ...state,
                openScreen: action.payload,
                closeScreen: false
            }
        }

        case types.uiOpenEditMeasurementUnit: {
            return {
                ...state,
                openScreen: action.payload,
                closeScreen: false
            }
        }

        case types.editingMeasurementUnitInfo: {
            return {
                ...state,
                MeasurementUnit: action.payload
            }
        }
        case types.clearMeasurementUnitInfo: {
            return {
                ...state,
                MeasurementUnit: action.payload
            }
        }
        // Almacenes

        case types.uiOpenListWarehouse: {
            return {
                ...state,
                openScreen: action.payload,
                closeScreen: false
            }
        }
        
        case types.uiOpenAddWarehouse: {
            return {
                ...state,
                openScreen: action.payload,
                closeScreen: false
            }
        }
        
        case types.uiOpenEditWarehouse: {
            return {
                ...state,
                openScreen: action.payload,
                closeScreen: false
            }
        }
        
        case types.editingWarehouseInfo: {
            return {
                ...state,
                Warehouse: action.payload
            }
        }
        case types.clearWarehouseInfo: {
            return {
                ...state,
                Warehouse: action.payload
            }
        }

        /**Grupo de Almacenes */



        
        case types.uiOpenListWarehouseGroup: {
            return {
                ...state,
                openScreen: action.payload,
                closeScreen: false
            }
        }
        
        case types.uiOpenAddWarehouseGroup: {
            return {
                ...state,
                openScreen: action.payload,
                closeScreen: false
            }
        }
        
        case types.uiOpenEditWarehouseGroup: {
            return {
                ...state,
                openScreen: action.payload,
                closeScreen: false
            }
        }
        
        case types.editingWarehouseGroupInfo: {
            return {
                ...state,
                WarehouseGroup: action.payload
            }
        }
        case types.clearWarehouseGroupInfo: {
            return {
                ...state,
                WarehouseGroup: action.payload
            }
        }
        case types.uiOpenAssociateWarehouseGroupWarehouse:
            return {
                ...state,
                openScreen: action.payload,
                closeScreen: false
            }



            case types.editingWarehouseGroupWarehouseInfo: {
                return {
                    ...state,
                    editingWarehouseGroupWarehouse: action.payload
                }
            }



            case types.uiOpenAssociateWarehouseGroupDevice:
                return {
                    ...state,
                    openScreen: action.payload,
                    closeScreen: false
                }

                case types.editingWarehouseGroupDeviceInfo: {
                    return {
                        ...state,
                      
                        editingWarehouseGroupDevice: action.payload
                    }
                }

                    // Productos Detalles

                case types.uiOpenProductDetails:
                    return {
                        ...state,
                        openScreen: action.payload,
                        closeScreen: false
                    }

                    case types.productDetails: {
                        return {
                            ...state,
                            productDetails: action.payload
                        }
                    }

        default:
            return state
    }
}