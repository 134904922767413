import React from 'react';
import { useSelector } from "react-redux";

import { Dashboard } from '../components/ui/Dashboard.js';

import { ListDeviceScreen } from '../components/ui/ListDeviceScreen.js';

import { ListUserScreen } from '../components/ui/ListUserScreen.js';
import { AddUserScreen } from '../components/ui/AddUserScreen.js';
import { EditUserScreen } from '../components/ui/EditUserScreen.js';

import { ListUserGroup } from '../components/ui/ListUserGroup.js';
import { AddUserGroup } from '../components/ui/AddUserGroup.js';
import { EditUserGroup } from '../components/ui/EditUserGroup.js';
import { AssociateUserGroupUser } from '../components/ui/AssociateUserGroupUser.js';
import { AssociateUserGroupDevice } from '../components/ui/AssociateUserGroupDevice.js';

import { ListDeliveryScreen } from '../components/ui/ListDeliveryScreen.js';
import { AddDeliveryScreen } from '../components/ui/AddDeliveryScreen.js';
import { EditDeliveryScreen } from '../components/ui/EditDeliveryScreen.js';

import { ListDeliveryGroup } from '../components/ui/ListDeliveryGroup.js';
import { AddDeliveryGroup } from '../components/ui/AddDeliveryGroup.js';
import { EditDeliveryGroup } from '../components/ui/EditDeliveryGroup.js';
import { AssociateDeliveryGroupDeliveryScreen } from '../components/ui/AssociateDeliveryGroupDeliveryScreen.js';
import { AssociateDeliveryGroupDeviceScreen } from '../components/ui/AssociateDeliveryGroupDeviceScreen.js';

import { ListCustomerScreen } from "../components/ui/ListCustomerScreen.js";
import { AddCustomerScreen } from '../components/ui/AddCustomerScreen.js';
import { EditCustomerScreen } from '../components/ui/EditCustomerScreen.js';

import { ListCustomerGroup } from '../components/ui/ListCustomerGroup.js';
import { AddCustomerGroup } from '../components/ui/AddCustomerGroup.js';
import { EditCustomerGroup } from '../components/ui/EditCustomerGroup.js';
import { AssociateCustomerGroupCustomerScreen } from '../components/ui/AssociateCustomerGroupCustomerScreen.js';
import { AssociateCustomerGroupDeviceScreen } from '../components/ui/AssociateCustomerGroupDeviceScreen.js';

import { ListDocumentScreen } from '../components/ui/ListDocumentScreen.js';
import { ListDocumentDetails } from '../components/ui/listDocumentDetails.js';

import { ListNCFRangeScreen } from '../components/ui/ListNCFRangeScreen.js';
import { AddNCFRangeScreen } from '../components/ui/AddNCFRangeScreen.js';
import { EditNCFRangeScreen } from '../components/ui/EditNCFRangeScreen.js';

import { ListNCFRangeGroup } from '../components/ui/ListNCFRangeGroup.js';
import { AddNCFRangeGroup } from '../components/ui/AddNCFRangeGroup.js';
import { EditNCFRangeGroup } from '../components/ui/EditNCFRangeGroup.js';
import { AssociateNCFRangeGroupNCFRangeScreen } from '../components/ui/AssociateNCFRangeGroupNCFRange.js';
import { AssociateNCFRangeGroupDeviceScreen } from '../components/ui/AssociateNCFRangeGroupDevice.js';


import { ListPaymentMethodScreen } from '../components/ui/ListPaymentMethodScreen.js';
import { AddPaymentMethodScreen } from '../components/ui/AddPaymentMethodScreen.js';
import { EditPaymentMethodScreen } from '../components/ui/EditPaymentMethodScreen.js';

import { ListPaymentMethodGroup } from '../components/ui/ListPaymentMethodGroup.js';
import { AddPaymentMethodGroup } from '../components/ui/AddPaymentMethodGroup.js';
import { EditPaymentMethodGroup } from '../components/ui/EditPaymentMethodGroup.js';
import { AssociatePaymentMethodGroupPaymentMethodScreen } from '../components/ui/AssociatePaymentMethodGroupPaymentMethodScreen.js';
import { AssociatePaymentMethodGroupDeviceScreen } from '../components/ui/AssociatePaymentMethodGroupDeviceScreen.js';

import { ListDiscountScreen } from '../components/ui/ListDiscountScreen.js';
import { AddDiscountScreen } from '../components/ui/AddDiscountScreen.js';
import { EditDiscountScreen } from '../components/ui/EditDiscountScreen.js';

import { ListProductScreen } from '../components/ui/ListProductScreen.js';
import { AddProductScreen } from '../components/ui/AddProductScreen.js';
import { EditProductScreen } from '../components/ui/EditProductScreen.js';

import { ListProductGroup } from '../components/ui/ListProductGroup.js';
import { AddProductGroup } from '../components/ui/AddProductGroup.js';
import { EditProductGroup } from '../components/ui/EditProductGroup.js';
import { AssociateProductGroupProduct } from '../components/ui/AssociateProductGroupProduct.js';
import { AssociateProductGroupDevice } from '../components/ui/AssociateProductGroupDevice.js';

import { ListPriceScreen } from '../components/ui/ListPriceScreen.js';
import { AddPriceScreen } from '../components/ui/AddPriceScreen.js';
import { EditPriceScreen } from '../components/ui/EditPriceScreen.js';

import { ListTaxScreen } from '../components/ui/ListTaxScreen.js';
import { AddTaxScreen } from '../components/ui/AddTaxScreen.js';
import { EditTaxScreen } from '../components/ui/EditTaxScreen.js';

import { AddMeasurementUnit } from '../components/ui/AddMeasurementUnit.js';
import { EditMeasurementUnit } from '../components/ui/EditMeasurementUnit.js';
import { ListMeasurementUnit } from '../components/ui/ListMeasurementUnit.js';

import { AddWarehouseScreen } from '../components/ui/AddWarehouseScreen.js';
import { EditWarehouseScreen } from '../components/ui/EditWarehouseScreen.js';
import { ListWarehouseScreen } from '../components/ui/ListWarehouseScreen.js';

import { ListWarehouseGroup } from '../components/ui/ListWarehouseGroup.js';
import { AddWarehouseGroup } from '../components/ui/AddWarehouseGroup.js';
import { EditWarehouseGroup } from '../components/ui/EditWarehouseGroup.js';

import {AssociateWarehouseGroupWarehouse} from '../components/ui/AssociateWarehouseGroupWarehouse.js'

import {AssociateWarehouseGroupDevice} from '../components/ui/AssociateWarehouseGroupDevice.js'

import { ListProductDetails } from '../components/ui/ListProductDetails.js';

export const ScreenSelector = () => {
    const openScreen = useSelector(state => state.ui.openScreen)

    switch (openScreen) {
        case 'uiOpenDashboard':
            return <Dashboard />

        case 'uiOpenListDevice':
            return <ListDeviceScreen />

        case 'uiOpenListUser':
            return <ListUserScreen />

        case 'uiOpenAddUser':
            return <AddUserScreen />

        case 'uiOpenEditUser':
            return <EditUserScreen />

        case 'uiOpenListUserGroup':
            return <ListUserGroup />

        case 'uiOpenAddUserGroup':
            return <AddUserGroup />

        case 'uiOpenEditUserGroup':
            return <EditUserGroup />

        case 'uiOpenAssociateUserGroupUser':
            return <AssociateUserGroupUser />

        case 'uiOpenAssociateUserGroupDevice':
            return <AssociateUserGroupDevice />

        case 'uiOpenListDelivery':
            return <ListDeliveryScreen />

        case 'uiOpenAddDelivery':
            return <AddDeliveryScreen />

        case 'uiOpenEditDelivery':
            return <EditDeliveryScreen />

        case 'uiOpenListDeliveryRouteGroup':
            return <ListDeliveryGroup />

        case 'uiOpenAddDeliveryRouteGroup':
            return <AddDeliveryGroup />

        case 'uiOpenEditDeliveryRouteGroup':
            return <EditDeliveryGroup />

        case 'uiOpenAssociateDeliveryRouteGroupDelivery':
            return <AssociateDeliveryGroupDeliveryScreen />

        case 'uiOpenAssociateDeliveryRouteGroupDevice':
            return <AssociateDeliveryGroupDeviceScreen />

        case 'uiOpenListCustomer':
            return <ListCustomerScreen />

        case 'uiOpenAddCustomer':
            return <AddCustomerScreen />

        case 'uiOpenEditCustomer':
            return <EditCustomerScreen />

        case 'uiOpenListCustomerGroup':
            return <ListCustomerGroup />

        case 'uiOpenAddCustomerGroup':
            return <AddCustomerGroup />

        case 'uiOpenEditCustomerGroup':
            return <EditCustomerGroup />

        case 'uiOpenAssociateCustomerGroupCustomer':
            return <AssociateCustomerGroupCustomerScreen />

        case 'uiOpenAssociateCustomerGroupDevice':
            return <AssociateCustomerGroupDeviceScreen />

        case 'uiOpenListDocument':
            return <ListDocumentScreen />

        case 'uiOpenDocumentDetails':
            return <ListDocumentDetails />

        case 'uiOpenListNCFRange':
            return <ListNCFRangeScreen />

        case 'uiOpenAddNCFRange':
            return <AddNCFRangeScreen />

        case 'uiOpenEditNCFRange':
            return <EditNCFRangeScreen />

        case 'uiOpenListNCFRangeGroup':
            return <ListNCFRangeGroup />

        case 'uiOpenAddNCFRangeGroup':
            return <AddNCFRangeGroup />

        case 'uiOpenEditNCFRangeGroup':
            return <EditNCFRangeGroup />

        case 'uiOpenAssociateNCFRangeGroupNCFRange':
            return <AssociateNCFRangeGroupNCFRangeScreen />

        case 'uiOpenAssociateNCFRangeGroupDevice':
            return <AssociateNCFRangeGroupDeviceScreen />

        case 'uiOpenListPaymentMethod':
            return <ListPaymentMethodScreen />

        case 'uiOpenAddPaymentMethod':
            return <AddPaymentMethodScreen />

        case 'uiOpenEditPaymentMethod':
            return <EditPaymentMethodScreen />

        case 'uiOpenListPaymentMethodGroup':
            return <ListPaymentMethodGroup />

        case 'uiOpenAddPaymentMethodGroup':
            return <AddPaymentMethodGroup />

        case 'uiOpenEditPaymentMethodGroup':
            return <EditPaymentMethodGroup />

        case 'uiOpenAssociatePaymentMethodGroupPaymentMethod':
            return <AssociatePaymentMethodGroupPaymentMethodScreen />

        case 'uiOpenAssociatePaymentMethodGroupDevice':
            return <AssociatePaymentMethodGroupDeviceScreen />

        case 'uiOpenListDiscount':
            return <ListDiscountScreen />

        case 'uiOpenAddDiscount':
            return <AddDiscountScreen />

        case 'uiOpenEditDiscount':
            return <EditDiscountScreen />

        case 'uiOpenListProduct':
            return <ListProductScreen />

        case 'uiOpenAddProduct':
            return <AddProductScreen />

        case 'uiOpenEditProduct':
            return <EditProductScreen />

        case 'uiOpenListProductGroup':
            return <ListProductGroup />

        case 'uiOpenAddProductGroup':
            return <AddProductGroup />

        case 'uiOpenEditProductGroup':
            return <EditProductGroup />

        case 'uiOpenAssociateProductGroupProduct':
            return <AssociateProductGroupProduct />

        case 'uiOpenAssociateProductGroupDevice':
            return <AssociateProductGroupDevice />

        case 'uiOpenListPrice':
            return <ListPriceScreen />

        case 'uiOpenAddPrice':
            return <AddPriceScreen />

        case 'uiOpenEditPrice':
            return <EditPriceScreen />

        case 'uiOpenListTax':
            return <ListTaxScreen />

        case 'uiOpenAddTax':
            return <AddTaxScreen />

        case 'uiOpenEditTax':
            return <EditTaxScreen />

        //OpenUi's
        case 'uiOpenListMeasurementUnit':
            return <ListMeasurementUnit />
            
        case 'uiOpenAddMeasurementUnit':
            return <AddMeasurementUnit />

        case 'uiOpenEditMeasurementUnit':
            return <EditMeasurementUnit />

        case 'uiOpenListWarehouse':
            return <ListWarehouseScreen />
                
        case 'uiOpenAddWarehouse':
            return <AddWarehouseScreen />
    
        case 'uiOpenEditWarehouse':
            return <EditWarehouseScreen />

        
            case 'uiOpenListWarehouseGroup':
                return <ListWarehouseGroup />
                    
            case 'uiOpenAddWarehouseGroup':
                return <AddWarehouseGroup />
        
            case 'uiOpenEditWarehouseGroup':
                return <EditWarehouseGroup />

    
             case 'uiOpenAssociateWarehouseGroupWarehouse':
                    return <AssociateWarehouseGroupWarehouse />
    

              case 'uiOpenAssociateWarehouseGroupDevice':
                     return <AssociateWarehouseGroupDevice />
    
                     case 'uiOpenProductDetails':
                        return <ListProductDetails />

        default: return ''
    }
}