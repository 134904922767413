import React from 'react';
import { Switch, Route, Redirect } from "react-router-dom";
import { NoAuthScreen } from '../components/auth/NoAuthScreen';
import {LoginScreen} from "../components/auth/LoginScreen";

export const AuthRouter = () => {
    return (
        <div className='auth_main'>
            <div className='auth_box-container'>
                <Switch>
                    <Route 
                        exact
                        path= '/login'
                        component = {LoginScreen}
                    >                
                    </Route>
                    <Redirect to= '/login'></Redirect>
                </Switch>
            </div>
        </div>
    )
};