import React, { useEffect, useState } from "react";
import { Button, Form, Input, Switch, Row, Col } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { clearUserInfo, openScreen } from "../../actions/ui";
import { permissions } from "../../helpers/permissions";
import { addOrUpdate } from "../../hooks/fetchHook";

import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";

export const EditUserScreen = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.ui.loading);
  const editingUserInfo = useSelector((state) => state.ui.editingUser);
  const isSidebarActive = useSelector((state) => state.ui.sidebar);
  const realm = useSelector((state) => state.auth.realm);
  const [formValues, setFormValues] = useState(editingUserInfo);
  const [form] = Form.useForm();
  const [permisos, setPermisos] = useState([])
  
  let children = [];
  let defaultChildren = [];

  if (editingUserInfo.permission !== "") {
    for (const prop of permissions) {
      if ((editingUserInfo.permission & prop.value) === prop.value) {
        defaultChildren.push(prop.value + "");
      }
    }
  }

  for (const prop of permissions) {
    children.push(
      <Col span={8} key={prop.value}>
        <Form.Item label={prop.label} valuePropName="checked">
          <Switch
            size="small"
            name={prop.value}
            onChange={(e) => handdleChecked(e, prop.value)}
            defaultChecked={defaultChildren.includes(prop.value.toString())? true : false}
          />
        </Form.Item>
      </Col>
    );
  }

  useEffect(() => {
    setPermisos(defaultChildren)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  



  const layout = {
    labelCol: { span: 10 },
    wrapperCol: { span: 100 },
  };

  const handdleInputChange = ({ target }) => {
    setFormValues({
      ...formValues,
      [target.id]: target.value,
      realmFk: realm,
    });
  };

  const handleSave = async () => {
    try {
      await form.validateFields().then(() => {
        dispatch(addOrUpdate({...formValues, permission: permisos}, "user"));
      });
    } catch (err) {console.log(err)}
  };

  const handdleDiscart = () => {
    form.resetFields();
    dispatch(clearUserInfo());
    dispatch(openScreen("uiOpenListUser"));
  };

  const handdleSwitchCheck = (e) => {
    setFormValues({
      ...formValues,
      status: e,
    });
  };

  const handdleChecked = (e, value) => {

    if (e === true) {
      setPermisos([...permisos, `${value}`])
    } else {
      setPermisos(permisos.filter((permiso) => permiso !== value.toString()))
    }

  };

  return (
    <div>
      <div className={isSidebarActive ? "_OCS-overlay" : "_overlay"}></div>
      <div className="_header-container3">
        <div
          className={isSidebarActive ? "_OCS-screen-header" : "_screen-header"}
        >
          <h2>Editar Usuario</h2>
        </div>
        <div className={isSidebarActive ? "_btn2-1_" : "_btn2_"}>
          <div className="_btns-container2">
            <Button className="_btn-primary _ant-add-btn " onClick={handdleDiscart} type="primary">
              Volver
            </Button>
            </div>
          </div>
      </div>
      <div
        className={
          isSidebarActive
            ? "_OCS-ant-card-body _OCS-ant-card-form-group"
            : "_ant-card-body _ant-card-form-group"
        }
      >
        <div className="_ant-card-header">Información Básica</div>
        <Form
          form={form}
          layout="vertical"
          {...layout}
          name="deviceInfoForm"
          initialValues={{
            id: [editingUserInfo.id],
            name: [editingUserInfo.name],
            userName: [editingUserInfo.userName],
            password: [editingUserInfo.password],
            code: [editingUserInfo.code],
          }}
        >
          <Form.Item label="ID" name="id">
            <Input
              disabled
              size="large"
              onChange={handdleInputChange}
              id="id"
            />
          </Form.Item>

          <Form.Item label="Nombre" name="name">
            <Input size="large" onChange={handdleInputChange} id="name" />
          </Form.Item>

          <Form.Item label="Nombre de Usuario" name="userName">
            <Input size="large" onChange={handdleInputChange} id="userName" />
          </Form.Item>

          <Form.Item label="Contraseña" name="password">
            <Input.Password
                style={{
                  borderRadius: "0.625rem",
                }}
                size="small"
                onChange={handdleInputChange}
                id="password"
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
          </Form.Item>

          <Form.Item label="Código" name="code">
            <Input size="large" onChange={handdleInputChange} id="code" />
          </Form.Item>
        </Form>
        <div>
          <Form form={form} {...layout} layout="vertical" name="deviceInfoForm">
            <Form.Item label="Estatus" name="status" valuePropName="checked">
              <Switch
                defaultChecked={editingUserInfo.status}
                onChange={handdleSwitchCheck}
                checkedChildren="Activo"
                unCheckedChildren="Inactivo"
              />
            </Form.Item>
          </Form>
        </div>
      </div>
      <div
        className={
          isSidebarActive
            ? "_OCS-ant-card-body _OCS-ant-card-form-group"
            : "_ant-card-body _ant-card-form-group"
        }
      >
        <div className="_ant-card-header">Organización</div>
        <div className="_ant-form-wrapper">
          <div>
            <Form
              form={form}
              {...layout}
              layout="vertical"
              name="deviceInfoForm2"
              labelCol={{
                span: 16,
              }}
            >
              <Row>{children}</Row>
            </Form>
          </div>
        </div>
      </div>
      <div  className={
            isSidebarActive
              ? "_OCS-ant-card-body _OCS-ant-card-form-group _btn-save-container"
              : "_ant-card-body _ant-card-form-group _btn-save-container"
          }>
          <div >  
          <Button
              className="_btn-primary _ant-add-btn "
              loading={isLoading}
              onClick={handleSave}
              type="primary"
            >
              Guardar
            </Button>
            </div>
        </div>
    </div>
  );
};
