import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AiOutlinePlusCircle } from 'react-icons/ai';

import { openScreen } from '../../actions/ui';
import { ListWarehouseTable } from '../../antTables/ListWarehouseTable';
import { Restricted } from '../../hooks/useRestricted';

export const ListWarehouseScreen = () => {
  const isSidebarActive = useSelector(state => state.ui.sidebar);

  const dispatch = useDispatch();
  const handdleAdd = () => {
    dispatch(openScreen('uiOpenAddWarehouse'))
  };
  return (
    <div>
      <div className={isSidebarActive ? '_OCS-overlay' : '_overlay'}></div>
      <div className={isSidebarActive ? '_OCS-screen-header' : '_screen-header'}>
          <h2>Almacenes Asociados</h2>
      </div>
      <div className={isSidebarActive ? '_OCS-ant-card-body' : '_ant-card-body'}>
          <div>
              <Restricted permission={'Usuarios'}>
                  <button className="_btn-primary _ant-add-btn _ant-add-top-table-btn" onClick={handdleAdd}>
                      <AiOutlinePlusCircle /> Asociar Almacenes
                  </button>
              </Restricted>
          </div>
          <div className={isSidebarActive ? '_OCS-table-responsive' : '_table-responsive'}>
              <ListWarehouseTable />
          </div>
      </div>
  </div>
  )
}
