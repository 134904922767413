import React from "react";
import { ProSidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import {
  AiOutlineDashboard,
  AiOutlineMobile,
  AiOutlineUser,
  AiOutlineCustomerService,
  AiOutlineFileDone,
  AiOutlineCreditCard,
  AiOutlineQrcode,
  AiOutlineCalculator,
  AiOutlineDollarCircle,
  AiOutlineMergeCells,
  AiOutlineShoppingCart,
} from "react-icons/ai";
import { BiPackage } from "react-icons/bi";
import { HiOutlineReceiptTax } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { closeCurrentScreen, openScreen } from "../../actions/ui";

export const Sidebar = () => {
  const dispatch = useDispatch();

  const sidebar = useSelector((state) => state.ui.sidebar);
  const currentOpenScreen = useSelector((state) => state.ui.openScreen);

  const handdleOpenScreen = (e) => {
    document.getElementById(e);
    dispatch(closeCurrentScreen());
    dispatch(openScreen(e.currentTarget.id));
  };

  return (
    <>
      <ProSidebar collapsed={sidebar}>
        <Menu iconShape="circle">
          <MenuItem
            icon={<AiOutlineDashboard size={20} />}
            id="uiOpenDashboard"
            title="Inicio"
            onClick={handdleOpenScreen}
            className={
              currentOpenScreen === "uiOpenDashboard" ? "_clicked-option" : ""
            }
          >
            Inicio
          </MenuItem>



          {/* Dispositivos */}

          <MenuItem
            icon={<AiOutlineMobile size={20} />}
            id="uiOpenListDevice"
            title="Dispositivos"
            onClick={handdleOpenScreen}
            className={
              currentOpenScreen === "uiOpenListDevice" ? "_clicked-option" : ""
            }
          >
            Dispositivos
          </MenuItem>




          {/* Usuarios */}

          <SubMenu
            title="Usuarios"
            icon={<AiOutlineUser size={20} />}
          >
            <MenuItem
              id="uiOpenListUser"
              onClick={handdleOpenScreen}
              className={
                currentOpenScreen === "uiOpenListUser" ? "_clicked-option" : ""
              }
            >
              Listar Usuarios
            </MenuItem>
            <MenuItem id="uiOpenListUserGroup" onClick={handdleOpenScreen}>
              Grupos de Usuarios
            </MenuItem>
          </SubMenu>



          {/* Deliverys*/}
          <SubMenu
            title="Deliverys"
            icon={<AiOutlineShoppingCart size={20} />}
          >

            <MenuItem
              id="uiOpenListDelivery"
              onClick={handdleOpenScreen}
              className={
                currentOpenScreen === "uiOpenListDelivery"
                  ? "_clicked-option"
                  : ""
              }
            >
              Listar Deliverys
            </MenuItem>
            <MenuItem
              id="uiOpenListDeliveryRouteGroup"
              onClick={handdleOpenScreen}
            >
              Grupos de Deliverys
            </MenuItem>
          </SubMenu>



          <SubMenu
            title="Clientes"
            icon={<AiOutlineCustomerService size={20} />}
          >


            {/* Clientes*/}

            <MenuItem
              id="uiOpenListCustomer"
              onClick={handdleOpenScreen}
              className={
                currentOpenScreen === "uiOpenListCustomer"
                  ? "_clicked-option"
                  : ""
              }
            >
              Listar Clientes
            </MenuItem>
            <MenuItem id="uiOpenListCustomerGroup" onClick={handdleOpenScreen}>
              Grupos de Clientes
            </MenuItem>
          </SubMenu>

          {/* Documentos*/}


          <MenuItem
            icon={<AiOutlineFileDone size={20} />}
            id="uiOpenListDocument"
            title="Documentos"
            onClick={handdleOpenScreen}
            className={
              currentOpenScreen === "uiOpenListDocument"
                ? "_clicked-option"
                : ""
            }
          >
            Documentos
          </MenuItem>



          {/* Rango NFC */}


          <SubMenu
            title="Rango NCF"
            icon={<HiOutlineReceiptTax size={20} />}
          >
            <MenuItem
              id="uiOpenListNCFRange"
              onClick={handdleOpenScreen}
              className={
                currentOpenScreen === "uiOpenListNCFRange"
                  ? "_clicked-option"
                  : ""
              }
            >
              Listar Rangos NCF
            </MenuItem>
            <MenuItem id="uiOpenListNCFRangeGroup" onClick={handdleOpenScreen}>
              Grupos de Rangos NCF
            </MenuItem>
          </SubMenu>
          <SubMenu
            title="Métodos de Pago"
            icon={<AiOutlineCreditCard size={20} />}
          >





            {/* Metodos de pago */}


            <MenuItem
              id="uiOpenListPaymentMethod"
              onClick={handdleOpenScreen}
              className={
                currentOpenScreen === "uiOpenListPaymentMethod"
                  ? "_clicked-option"
                  : ""
              }
            >
              Listar Métodos de Pago
            </MenuItem>
            <MenuItem
              id="uiOpenListPaymentMethodGroup"
              onClick={handdleOpenScreen}
            >
              Grupos de Métodos
            </MenuItem>
          </SubMenu>


          <MenuItem
            icon={<AiOutlineCalculator size={20} />}
            id="uiOpenListDiscount"
            title="Descuentos"
            onClick={handdleOpenScreen}
            className={
              currentOpenScreen === "uiOpenListDiscount"
                ? "_clicked-option"
                : ""
            }
          >
            Descuentos
          </MenuItem>


          <SubMenu title="Productos" icon={<AiOutlineQrcode size={20} />} >
            <MenuItem
              id="uiOpenListProduct"
              onClick={handdleOpenScreen}
              className={
                currentOpenScreen === "uiOpenListProduct"
                  ? "_clicked-option"
                  : ""
              }
            >
              Listar Productos
            </MenuItem>
            <MenuItem id="uiOpenListProductGroup" onClick={handdleOpenScreen}>
              Grupos de Productos
            </MenuItem>
          </SubMenu>

          <MenuItem
            icon={<AiOutlineDollarCircle size={20} />}
            id="uiOpenListPrice"
            title="Precios"
            onClick={handdleOpenScreen}
            className={
              currentOpenScreen === "uiOpenListPrice" ? "_clicked-option" : ""
            }
          >
            Precios
          </MenuItem>



          <MenuItem
            icon={<AiOutlineCalculator size={20} />}
            id="uiOpenListTax"
            title="Impuestos"
            onClick={handdleOpenScreen}
            className={
              currentOpenScreen === "uiOpenListTax" ? "_clicked-option" : ""
            }
          >
            Impuestos
          </MenuItem>

          <SubMenu title="Almacenes" icon={<BiPackage size={20} />} >



            <MenuItem

              id="uiOpenListWarehouseGroup"
              title=" Grupos de Almacenes"
              onClick={handdleOpenScreen}
              className={
                currentOpenScreen === "uiOpenListWarehouseGroup" ? "_clicked-option" : ""
              }
            >
              Lista de Almacenes
            </MenuItem>


            <MenuItem

              id="uiOpenListWarehouse"
              title="Almacenes"
              onClick={handdleOpenScreen}
              className={
                currentOpenScreen === "uiOpenListWarehouse" ? "_clicked-option" : ""
              }
            >
               Almacenes Asociados
            </MenuItem>





          </SubMenu>

          <MenuItem
            icon={<AiOutlineMergeCells size={20} />}
            id="uiOpenListMeasurementUnit"
            title="Unidades de medida"
            onClick={handdleOpenScreen}
            className={
              currentOpenScreen === "uiOpenListMeasurementUnit" ? "_clicked-option" : ""
            }
          >
            Unidades de medida
          </MenuItem>

          <div style={{ height: '50px' }}></div>

        </Menu>

      </ProSidebar>
    </>
  );
};
