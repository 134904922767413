import React, { useState } from 'react';
import { Button, Form, Input, Switch } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { clearMeasurementUnitInfo, openScreen } from '../../actions/ui';
import { addOrUpdate } from '../../hooks/fetchHook';

export const EditMeasurementUnit = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(state => state.ui.loading);
  const editingTax = useSelector(state => state.ui.MeasurementUnit);
  const isSidebarActive = useSelector(state => state.ui.sidebar);
  const realm = useSelector(state => state.auth.realm);
  const [formValues, setFormValues] = useState(editingTax);
  const [form] = Form.useForm();


  const layout = {
      labelCol: { span: 10 },
      wrapperCol: { span: 100 }
  };

  const handdleInputChange = ({ target }) => {
      setFormValues({
          ...formValues,
          [target.id]: target.value,
          realmFk: realm
      })
  };

  const handleSave = async () => {
      try {
          await form.validateFields()
              .then(() => {
                  dispatch(addOrUpdate(formValues, 'measurementunit'))
              })
      }
      catch (err) { }
  }

  const handdleDiscart = () => {
      form.resetFields()
      dispatch(clearMeasurementUnitInfo())
      dispatch(openScreen('uiOpenListMeasurementUnit'))
  };

  const handdleStatus = (e) => {
      setFormValues({
          ...formValues,
          status: e
      })
  };

  return (
      <div>
          <div className={isSidebarActive ? '_OCS-overlay' : '_overlay'}></div>
          <div className='_header-container3'>
              <div className={isSidebarActive ? '_OCS-screen-header' : '_screen-header'}> 
                  <h2>Editar Unidad de Medida</h2>
              </div>
              <div className={isSidebarActive ? "_btn2-1_" : "_btn2_"}>
          <div className="_btns-container2">
            <Button className="_btn-primary _ant-add-btn " onClick={handdleDiscart} type="primary">
              Volver
            </Button>
            </div>
          </div>
          </div>
          <div className={isSidebarActive ? '_OCS-ant-card-body _OCS-ant-card-form-group' : '_ant-card-body _ant-card-form-group'}>
              <div className='_ant-card-header'>Información Básica</div>
              <Form
                  form={form}
                  layout='vertical'
                  {...layout}
                  name="deviceInfoForm"
                  initialValues={{
                      id: [editingTax.id],
                      name: [editingTax.name],
                      code: [editingTax.code],
                  }}
              >
                  <Form.Item
                      label="ID"
                      name="id"
                  >
                      <Input disabled size='large' onChange={handdleInputChange} id="id" />
                  </Form.Item>

                  <Form.Item
                      label="Nombre"
                      name="name"
                  >
                      <Input size='large' onChange={handdleInputChange} id="name" />
                  </Form.Item>
                  <Form.Item
                      label="Estatus"
                      name="status"
                      valuePropName="checked"
                  >
                      <Switch defaultChecked={editingTax.status} onChange={handdleStatus} checkedChildren="Activo" unCheckedChildren="Inactivo"></Switch>
                  </Form.Item>
              </Form>
          </div>
          <div  className={
            isSidebarActive
              ? "_OCS-ant-card-body _OCS-ant-card-form-group _btn-save-container"
              : "_ant-card-body _ant-card-form-group _btn-save-container"
          }>
          <div >  
          <Button
              className="_btn-primary _ant-add-btn "
              loading={isLoading}
              onClick={handleSave}
              type="primary"
            >
              Guardar
            </Button>
            </div>
        </div>

      </div>
  )
}
