import {types} from '../types/types';

const initialState = {}

export const authReducer = (state = initialState, action) => {
    switch(action.type){
        case types.login: 
            return {
                ...state,
            }
        
        case types.logout:
            return {}

        case types.setUsername:
            return {
                ...state,
                username: action.payload
            }

        case types.setPermissions:
            return {
                ...state,
                permissions: action.payload
            }

        case types.setBusinessName:
            return {
                ...state,
                businessName: action.payload
            }

        case types.setRealm:
            return {
                ...state,
                realm: action.payload
            }
            
        default:
            return state
    }
};