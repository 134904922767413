import { fetchWithToken } from '../helpers/fetch.js';
import { types } from '../types/types.js';
import { message } from 'antd';

export const openScreen = (pageId) => ({
    type: types[pageId],
    payload: pageId
});

export const closeCurrentScreen = () => ({
    type: types.uiCloseCurrentScreen,
    payload: true
});

export const uiLogout = () => ({
    type: types.uiLogout
})

export const sidebarToggle = (isActive) => ({
    type: types.uiSidebarActive,
    payload: isActive
});

export const loading = (isLoading) => ({
    type: types.uiLoading,
    payload: isLoading
});

export const userToEdit = (userInfo) => ({
    type: types.editingUserInfo,
    payload: userInfo
});

export const deleteUser = (record) => {
    const body = {
        code: record.code,
        id: record.id,
        key: record.key,
        name: record.name,
        password: record.password,
        permission: record.permission,
        realmFk: record.realmFk,
        status: record.status,
        userName: record.userName
    }


    return async (dispatch) => {
        try {
            const req = await fetchWithToken('user/delete', body, 'POST');
            const resp = await req.json()
            dispatch(deletingUSer)

            if (resp.response) {
                message.success('Listo')
            } else {
                message.warning('Este usuario está relacionado a un grupo de usuarios, debe eliminarse de dicha entidad antes de proceder')
            }
        }
        catch (err) {
            dispatch(deletingUSer)
            message.error('Error al eliminar usuario')
        }
    }
};

const deletingUSer = () => ({
    type: types.deleteUser
});

export const clearUserInfo = () => {
    const clearedUser = Object.freeze({
        id: "",
        name: "",
        userName: "",
        active: "",
        phoneNumber: "",
        email: "",
        address: "",
    })

    return {
        type: types.clearUserInfo,
        payload: clearedUser
    }
};

export const clearGroupInfo = () => {
    const clearedGroup = Object.freeze({
        id: "",
        groupName: "",
        shortName: "",
        createdAt: "",
        members: "",
        activeMembers: "",
    })

    return {
        type: types.clearGroupInfo,
        payload: clearedGroup
    }
};

export const customerToEdit = (customerInfo) => ({
    type: types.editingCustomerInfo,
    payload: customerInfo
});

export const deleteCustomer = (customerInfo) => {
    customerInfo.typeNcf = null;
    customerInfo.documentType = null;
    customerInfo.concept = null;
    delete customerInfo.actions

    return async (dispatch) => {
        try {
            await fetchWithToken('customer/delete', customerInfo, 'POST')

            dispatch(deletingCustomer)
            message.success('Listo')
        } catch (err) {
            message.error('Error al eliminar cliente')
        }
    }
};

const deletingCustomer = () => ({
    type: types.deleteCustomer
})

export const clearCustomerInfo = () => {
    const clearedCustomer = Object.freeze({
        id: "",
        name: "",
        active: "",
        phoneNumber: "",
        email: "",
        address: "",
        representative: ""
    })

    return {
        type: types.clearCustomerInfo,
        payload: clearedCustomer
    }
};

export const paymentMethodToEdit = (paymentInfo) => ({
    type: types.editingPaymentMethodInfo,
    payload: paymentInfo
})

export const clearPaymentMethodInfo = () => {
    const clearedPaymentMethod = Object.freeze({
        id: "",
        name: "",
        ordinal: "",
        strategyHandler: "",
        referenceUsed: "",
        singlePayment: "",
        realmFk: "",
        status: ""
    })

    return {
        type: types.clearPaymentMethodInfo,
        payload: clearedPaymentMethod
    }
};

export const deletePaymentMethod = (record) => {
    const body = {
        id: record.id,
        key: record.key,
        name: record.name,
        ordinal: record.ordinal,
        realmFk: record.realmFk,
        referenceUsed: record.referenceUsed,
        singlePayment: record.singlePayment,
        status: record.status,
        strategyHandler: record.strategyHandlers
    }

    return async (dispatch) => {
        try {
            await fetchWithToken('paymentmethod/delete', body, 'POST');

            dispatch(deletingPaymentMehotd)
            message.success('Listo')
        }
        catch (err) {
            message.error('Error al eliminar método de pago')
        }
    }
}

const deletingPaymentMehotd = () => ({
    type: types.deletePaymentMethod
})

export const productToEdit = (productInfo) => ({
    type: types.editingProductInfo,
    payload: productInfo
})

export const clearProductInfo = () => {
    const clearedProductInfo = Object.freeze({
        id: "",
        code: "",
        existence: "",
        formatter: "",
        freightChargeFk: "",
        name: "",
        ordinal: "",
        realmFk: "",
        status: "",
        visible: "",
    })

    return {
        type: types.clearProductInfo,
        payload: clearedProductInfo
    }
};

export const deleteProduct = (record) => {
    const body = {
        code: record.code,
        existence: record.existence,
        formatter: record.formatter,
        id: record.id,
        key: record.key,
        lastPrice: record.lastPrice,
        name: record.name,
        ordinal: record.ordinal,
        realmFk: record.realmFk,
        status: record.status,
        visible: record.visible
    };

    return async (dispatch) => {
        try {
            await fetchWithToken('product/delete', body, 'POST');

            dispatch(deletingProduct)
            message.success('Listo')
        }
        catch (err) {
            message.error('Error al eliminar producto')
        }
    }
}

const deletingProduct = () => ({
    type: types.deleteProduct
});

export const taxToEdit = (taxInfo) => ({
    type: types.editingTaxInfo,
    payload: taxInfo
})

export const clearTaxInfo = () => {
    const clearedTaxInfo = Object.freeze({
        id: "",
        name: "",
        code: "",
        realmFk: "",
        status: ""
    })

    return {
        type: types.clearTaxInfo,
        payload: clearedTaxInfo
    }
};

export const deleteTax = (taxInfo) => {
    delete taxInfo.actions

    return async (dispatch) => {
        try {
            await fetchWithToken('tax/delete', taxInfo, 'POST');

            dispatch(deletingTax)
            message.success('Listo')
        }
        catch (err) {
            message.error('Error al eliminar impuesto')
        }
    }
};

const deletingTax = () => ({
    type: types.deleteTax
});

export const deliveryToEdit = (deliveryInfo) => ({
    type: types.editingDeliveryInfo,
    payload: deliveryInfo
})

export const clearDeliveryInfo = () => {
    const clearedDeliveryInfo = Object.freeze({
        id: "",
        name: "",
        code: "",
        realmFk: "",
        status: ""
    })

    return {
        type: types.clearDeliveryInfo,
        payload: clearedDeliveryInfo
    }
};

export const deleteDelivery = (record) => {
    const body = {
        attended: record.attended,
        customerFk: record.customerFk,
        id: record.id,
        key: record.key,
        label: record.label,
        message: record.message,
        ordinal: record.ordinal,
        priority: record.priority,
        process: record.process,
        realmFk: record.realmFk,
        status: record.status
    }

    return async (dispatch) => {
        try {
            await fetchWithToken('deliveryroute/delete', body, 'POST');

            dispatch(deletingDelivery)
            message.success('Listo')
        }
        catch (err) {
            message.error('Error al eliminar delivery')
        }
    }
};

const deletingDelivery = () => ({
    type: types.deleteDelivery
});

export const customerGroupToEdit = (customerGroupInfo) => ({
    type: types.editingCustomerGroupInfo,
    payload: customerGroupInfo
})

export const clearCustomerGroupInfo = () => {
    const clearedCustomerGroupInfo = Object.freeze({
        id: "",
        name: "",
        realmFk: "",
        description: "",
        status: ""
    })

    return {
        type: types.clearedCustomerGroupInfo,
        payload: clearedCustomerGroupInfo
    }
};

export const refreshMyKey = (refreshKey) => ({
    type: types.refreshKey,
    payload: refreshKey
});


export const customerGroupCustomerToEdit = (customerGroupCustomerInfo) => {
    const groupInfo = {
        groupFk: customerGroupCustomerInfo.id,
        realmFk: customerGroupCustomerInfo.realmFk
    }
    return {
        type: types.editingCustomerGroupCustomerInfo,
        payload: groupInfo
    }
}

export const clearCustomerGroupCustomerInfo = () => {
    const clearedCustomerGroupCustomerInfo = Object.freeze({
        groupFk: "",
        entityId: "",
        realmFk: ""
    })

    return {
        type: types.clearedCustomerGroupCustomerInfo,
        payload: clearedCustomerGroupCustomerInfo
    }
};

export const customerGroupDeviceToEdit = (customerGroupDeviceInfo) => {
    const deviceInfo = {
        groupFk: customerGroupDeviceInfo.id,
        realmFk: customerGroupDeviceInfo.realmFk
    }
    return {
        type: types.editingCustomerGroupDeviceInfo,
        payload: deviceInfo
    }
}

export const clearCustomerGroupDeviceInfo = () => {
    const clearedCustomerGroupDeviceInfo = Object.freeze({
        groupFk: "",
        deviceId: "",
        realmFk: ""
    })

    return {
        type: types.clearedCustomerGroupDeviceInfo,
        payload: clearedCustomerGroupDeviceInfo
    }
};

export const deliveryGroupToEdit = (deliveryGroupInfo) => ({
    type: types.editingDeliveryGroupInfo,
    payload: deliveryGroupInfo
})

export const clearDeliveryGroupInfo = () => {
    const clearedDeliveryGroupInfo = Object.freeze({
        id: "",
        name: "",
        realmFk: "",
        description: "",
        status: ""
    })

    return {
        type: types.clearedDeliveryGroupInfo,
        payload: clearedDeliveryGroupInfo
    }
};

export const deliveryGroupDeliveryToEdit = (deliveryGroupDeliveryInfo) => {
    const groupInfo = {
        groupFk: deliveryGroupDeliveryInfo.id,
        realmFk: deliveryGroupDeliveryInfo.realmFk
    }
    return {
        type: types.editingDeliveryGroupDeliveryInfo,
        payload: groupInfo
    }
}

export const clearDeliveryGroupDeliveryInfo = () => {
    const clearedDeliveryGroupDeliveryInfo = Object.freeze({
        groupFk: "",
        entityId: "",
        realmFk: ""
    })

    return {
        type: types.clearedDeliveryGroupDeliveryInfo,
        payload: clearedDeliveryGroupDeliveryInfo
    }
};

export const deliveryGroupDeviceToEdit = (deliveryGroupDeviceInfo) => {
    const deliveryInfo = {
        groupFk: deliveryGroupDeviceInfo.id,
        realmFk: deliveryGroupDeviceInfo.realmFk
    }
    return {
        type: types.editingDeliveryGroupDeviceInfo,
        payload: deliveryInfo
    }
}

export const clearDeliveryGroupDeviceInfo = () => {
    const clearedDeliveryGroupDeviceInfo = Object.freeze({
        groupFk: "",
        deviceId: "",
        realmFk: ""
    })

    return {
        type: types.clearedDeliveryGroupDeviceInfo,
        payload: clearedDeliveryGroupDeviceInfo
    }
};

export const PaymentMethodGroupToEdit = (info) => ({
    type: types.editingPaymentMethodGroupInfo,
    payload: info
})

export const clearPaymentMethodGroupInfo = () => {
    const clearedPaymentMethodGroupInfo = Object.freeze({
        id: "",
        name: "",
        realmFk: "",
        description: "",
        status: ""
    })

    return {
        type: types.clearedPaymentMethodGroupInfo,
        payload: clearedPaymentMethodGroupInfo
    }
};

export const paymentMethodGroupPaymentMethodToEdit = (info) => {
    const groupInfo = {
        groupFk: info.id,
        realmFk: info.realmFk
    }
    return {
        type: types.editingPaymentMethodGroupPaymentMethodInfo,
        payload: groupInfo
    }
}

export const clearPaymentMethodGroupPaymentMethodInfo = () => {
    const clearedPaymentMethodGroupPaymentMethodInfo = Object.freeze({
        groupFk: "",
        entityId: "",
        realmFk: ""
    })

    return {
        type: types.clearedPaymentMethodGroupPaymentMethodInfo,
        payload: clearedPaymentMethodGroupPaymentMethodInfo
    }
};

export const paymentMethodGroupDeviceToEdit = (info) => {
    const paymentMethodInfo = {
        groupFk: info.id,
        realmFk: info.realmFk
    }
    return {
        type: types.editingPaymentMethodGroupDeviceInfo,
        payload: paymentMethodInfo
    }
}

export const clearPaymentMethodGroupDeviceInfo = () => {
    const clearedPaymentMethodGroupDeviceInfo = Object.freeze({
        groupFk: "",
        deviceId: "",
        realmFk: ""
    })

    return {
        type: types.clearedPaymentMethodGroupDeviceInfo,
        payload: clearedPaymentMethodGroupDeviceInfo
    }
};

export const productGroupToEdit = (info) => ({
    type: types.editingProductGroupInfo,
    payload: info
})

export const clearProductGroupInfo = () => {
    const clearedProductGroupInfo = Object.freeze({
        id: "",
        name: "",
        realmFk: "",
        description: "",
        status: ""
    })

    return {
        type: types.clearedProductGroupInfo,
        payload: clearedProductGroupInfo
    }
};

export const productGroupProductToEdit = (info) => {
    const groupInfo = {
        groupFk: info.id,
        realmFk: info.realmFk
    }
    return {
        type: types.editingProductGroupProductInfo,
        payload: groupInfo
    }
}

export const clearProductGroupProductInfo = () => {
    const clearedProductGroupProductInfo = Object.freeze({
        groupFk: "",
        entityId: "",
        realmFk: ""
    })

    return {
        type: types.clearedProductGroupProductInfo,
        payload: clearedProductGroupProductInfo
    }
};

export const productGroupDeviceToEdit = (info) => {
    const productInfo = {
        groupFk: info.id,
        realmFk: info.realmFk
    }
    return {
        type: types.editingProductGroupDeviceInfo,
        payload: productInfo
    }
};

export const clearProductGroupDeviceInfo = () => {
    const clearedProductGroupDeviceInfo = Object.freeze({
        groupFk: "",
        deviceId: "",
        realmFk: ""
    })

    return {
        type: types.clearedProductGroupDeviceInfo,
        payload: clearedProductGroupDeviceInfo
    }
};

export const userGroupToEdit = (info) => ({
    type: types.editingUserGroupInfo,
    payload: info
})

export const clearUserGroupInfo = () => {
    const clearedUserGroupInfo = Object.freeze({
        id: "",
        name: "",
        realmFk: "",
        description: "",
        status: ""
    })

    return {
        type: types.clearedUserGroupInfo,
        payload: clearedUserGroupInfo
    }
};

export const userGroupUserToEdit = (info) => {
    const groupInfo = {
        groupFk: info.id,
        realmFk: info.realmFk
    }
    return {
        type: types.editingUserGroupUserInfo,
        payload: groupInfo
    }
}

export const clearUserGroupUserInfo = () => {
    const clearedUserGroupUserInfo = Object.freeze({
        groupFk: "",
        entityId: "",
        realmFk: ""
    })

    return {
        type: types.clearedUserGroupUserInfo,
        payload: clearedUserGroupUserInfo
    }
};

export const userGroupDeviceToEdit = (info) => {
    const userInfo = {
        groupFk: info.id,
        realmFk: info.realmFk
    }
    return {
        type: types.editingUserGroupDeviceInfo,
        payload: userInfo
    }
};

export const clearUserGroupDeviceInfo = () => {
    const clearedUserGroupDeviceInfo = Object.freeze({
        groupFk: "",
        deviceId: "",
        realmFk: ""
    })

    return {
        type: types.clearedUserGroupDeviceInfo,
        payload: clearedUserGroupDeviceInfo
    }
};

export const discountToEdit = (record) => ({
    type: types.editingDiscountInfo,
    payload: record
})

export const clearDiscountInfo = () => {
    const clearedDiscountInfo = Object.freeze({
        id: 0,
        customerFk: 0,
        productFk: 0,
        measurementUnit: "",
        percentage: 0,
        flat: 0,
        validFrom: "",
        validUntil: "",
        realmFk: "",
        status: true
    })

    return {
        type: types.clearDiscountInfo,
        payload: clearedDiscountInfo
    }
};

export const NCFRangeToEdit = (record) => ({
    type: types.editingNCFRangeInfo,
    payload: record
})

export const clearNCFRangeInfo = () => {
    const clearedNCFRangeInfo = Object.freeze({
        id: 0,
        typeNcf: 0,
        validUntil: "",
        startSequence: 0,
        finalSequence: 0,
        ordinal: 0,
        taxPrefix: "",
        status: true

    })

    return {
        type: types.clearNCFRangeInfo,
        payload: clearedNCFRangeInfo
    }
};

export const deleteNCFRange = (record) => {
    const body = {
        id: record.id,
        typeNcf: record.typeNcf,
        validUntil: record.validUntil,
        startSequence: record.startSequence,
        finalSequence: record.finalSequence,
        ordinal: record.ordinal,
        taxPrefix: record.taxPrefix,
        status: record.status,
        realmFk: record.realmFk
    }
    return async (dispatch) => {
        try {
            await fetchWithToken('ncfrange/delete', body, 'POST');

            dispatch(deletingNCFRange)
            message.success('Listo')
        }
        catch (err) {
            message.error('Error al eliminar Rango NCF')
        }
    }
};

const deletingNCFRange = () => ({
    type: types.deleteNCFRange
});

export const NCFRangeGroupToEdit = (info) => ({
    type: types.editingNCFRangeGroupInfo,
    payload: info
})

export const clearNCFRangeGroupInfo = () => {
    const clearedNCFRangeGroupInfo = Object.freeze({
        id: "",
        name: "",
        realmFk: "",
        description: "",
        status: ""
    })

    return {
        type: types.clearedUserGroupInfo,
        payload: clearedNCFRangeGroupInfo
    }
};

export const NCFRangeGroupNCFRangeToEdit = (info) => {
    const groupInfo = {
        groupFk: info.id,
        realmFk: info.realmFk
    }
    return {
        type: types.editingNCFRangeGroupNCFRangeInfo,
        payload: groupInfo
    }
}

export const clearNCFRangeGroupNCFRangeInfo = () => {
    const clearedNCFRangeGroupNCFRangeInfo = Object.freeze({
        groupFk: "",
        entityId: "",
        realmFk: ""
    })

    return {
        type: types.clearedNCFRangeGroupNCFRangeInfo,
        payload: clearedNCFRangeGroupNCFRangeInfo
    }
};

export const NCFRangeGroupDeviceToEdit = (info) => {
    const NCFRangeInfo = {
        groupFk: info.id,
        realmFk: info.realmFk
    }
    return {
        type: types.editingNCFRangeGroupDeviceInfo,
        payload: NCFRangeInfo
    }
};

export const clearNCFRangeGroupDeviceInfo = () => {
    const clearedNCFRangeGroupDeviceInfo = Object.freeze({
        groupFk: "",
        deviceId: "",
        realmFk: ""
    })

    return {
        type: types.clearedNCFRangeGroupDeviceInfo,
        payload: clearedNCFRangeGroupDeviceInfo
    }
};

export const priceToEdit = (record) => ({
    type: types.editingPriceInfo,
    payload: record
})

export const clearPriceInfo = () => {
    const clearedPriceInfo = Object.freeze({
        id: 0,
        realmFk: "",
        validFrom: "",
        validUntil: "",
        amount: 0,
        productFk: 0,
        zone: 0,
        measurementUnit: "",
        status: true,
        type: 0
    })

    return {
        type: types.clearPriceInfo,
        payload: clearedPriceInfo
    }
};


export const deletePrice = (record) => {
    const body = {
        id: record.id,
        realmFk: record.realmFk,
        validFrom: record.validFrom,
        validUntil: record.validUntil,
        amount: record.amount,
        productFk: record.productFk,
        zone: record.zone,
        measurementUnit: record.measurementUnit,
        status: record.status,
        type: record.type
    }
    return async (dispatch) => {
        try {
            await fetchWithToken('price/delete', body, 'POST');

            dispatch(deletingPrice)
            message.success('Listo')
        }
        catch (err) {
            message.error('Error al eliminar Precio')
        }
    }
};

const deletingPrice = () => ({
    type: types.deletePrice
});

export const documentDetails = (record) => ({
    type: types.documentDetails,
    payload: record
});

//Metodos de modulo

export const clearMeasurementUnitInfo = () => {
    const clearedMeasurementUnitInfo = Object.freeze({
        id: 0,
        name: "",
        realmFk: "",
        status: true,
    })

    return {
        type: types.clearMeasurementUnitInfo,
        payload: clearedMeasurementUnitInfo
    }
};

export const deleteMeasurementUnit = (measurementUnitInfo) => {
    delete measurementUnitInfo.actions

    return async (dispatch) => {
        try {
            await fetchWithToken('measurementunit/delete', measurementUnitInfo, 'POST');

            dispatch(deletingMeasurementUnit)
            message.success('Listo')
        }
        catch (err) {
            message.error('Error al eliminar Unidad de pago')
        }
    }
};

const deletingMeasurementUnit = () => ({
    type: types.deleteMeasurementUnitInfo
});

export const MeasurementUnitToEdit = (MeasurementUnitInfo) => ({
    type: types.editingMeasurementUnitInfo,
    payload: MeasurementUnitInfo
})


// Alamacenes

export const clearWarehouseInfo = () => {
    const clearedWarehouseInfo = Object.freeze({
        id: 0,
        name: "",
        realmFk: "",
        status: true,
    })

    return {
        type: types.clearWarehouseInfo,
        payload: clearedWarehouseInfo
    }
};

export const deleteWarehouse = (WarehouseInfo) => {
    delete WarehouseInfo.actions

    return async (dispatch) => {
        try {
            await fetchWithToken('warehouse/delete', WarehouseInfo, 'POST');

            dispatch(deletingWarehouse)
            message.success('Listo')
        }
        catch (err) {
            message.error('Error al eliminar Unidad de pago')
        }
    }
};

const deletingWarehouse = () => ({
    type: types.deleteWarehouseInfo
});

export const WarehouseToEdit = (WarehouseInfo) => ({
    type: types.editingWarehouseInfo,
    payload: WarehouseInfo
})

// Grupo de Almacenes //




export const clearWarehouseGroupInfo = () => {
    const clearedWarehouseGroupInfo = Object.freeze({
        id: 0,
        name: "",
        realmFk: "",
        status: true,
    })

    return {
        type: types.clearWarehouseGroupInfo,
        payload: clearedWarehouseGroupInfo
    }
};

export const deleteWarehouseGroup = (WarehouseGroupInfo) => {
    delete WarehouseGroupInfo.actions

    return async (dispatch) => {
        try {
            await fetchWithToken('warehouseGroup/delete', WarehouseGroupInfo, 'POST');

            dispatch(deletingWarehousGroup)
            message.success('Listo')
        }
        catch (err) {
            message.error('Error al eliminar Unidad de pago')
        }
    }
};

const deletingWarehousGroup = () => ({
    type: types.deleteWarehouseGroupInfo
});

export const WarehouseGroupToEdit = (WarehouseGroupInfo) => ({
    type: types.editingWarehouseGroupInfo,
    payload: WarehouseGroupInfo
})



export const clearWarehouseGroupWarehouseInfo = () => {
    const clearedWarehouseGroupWarehouseInfo = Object.freeze({
        groupFk: "",
        entityId: "",
        realmFk: ""
    })

    return {
        type: types.clearedWarehouseGroupWarehouseInfo,
        payload: clearedWarehouseGroupWarehouseInfo
    }
};




export const warehouseGroupWarehouseToEdit = (info) => {
    const groupInfo = {
        groupFk: info.id,
        realmFk: info.realmFk
    }
    return {
        type: types.editingWarehouseGroupWarehouseInfo,
        payload: groupInfo
    }
};



export const clearWarehouseGroupDeviceInfo = () => {
    const clearedWarehouseGroupDeviceInfo = Object.freeze({
        groupFk: "",
        entityId: "",
        realmFk: ""
    })

    return {
        type: types.clearedWarehouseGroupDeviceInfo,
        payload: clearedWarehouseGroupDeviceInfo
    }
};


export const warehouseGroupDeviceToEdit = (info) => {
    const groupInfo = {
        groupFk: info.id,
        realmFk: info.realmFk
    }
    return {
        type: types.editingWarehouseGroupDeviceInfo,
        payload: groupInfo
    }
};








