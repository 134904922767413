import { baseUrl } from "./baseUrl";
import { authUrl } from "./authUrl";

export const fetchWithoutToken = (endpoint, data, method ='GET') => {
    const url = `${authUrl}/${endpoint}`;

    if(method === 'GET'){
        return fetch(url, {
            mode: "cors",
            method,
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Access-Control-Allow-Origin": "*",
                "X-Consumer-Key": "Handdo Managers"
            }
        });
    } else {
        return fetch(url, {
            mode: "cors",
            method,
            headers: {
                "Content-type": "application/json",
                "Accept": "application/json",
                "Access-Control-Allow-Origin": "*",
                "X-Consumer-Key": "Handdo Managers"
            },
            body: JSON.stringify(data)
        }) 
    }
};

export const fetchWithToken = (endpoint, data, method ='GET') => {

    var url = `${baseUrl}/${endpoint}`;

    if (endpoint.startsWith('document')) {
        url += `?limitRow=${100000000}`;
    }

    if(method === 'GET'){
        
        return fetch(url, {
            mode: "cors",
            method,
            headers: {
                "Content-type": "application/json",
                "Accept": "application/json"
            }
        })
    } else {
        
        return fetch(url, {
            mode: "cors",
            method,
            headers: {
                "Content-type": "application/json",
                "Accept": "application/json",
                "Access-Control-Allow-Origin": "*"
            },
            body: JSON.stringify(data)
        }) 
    }
};