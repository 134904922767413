import React, { useEffect, useState } from 'react';
import { Button, DatePicker, Form, Input, Switch, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { clearDiscountInfo, openScreen } from '../../actions/ui';
import { addOrUpdate } from '../../hooks/fetchHook';
import moment from 'moment';
import { MeasurementUnitListBox } from './MeasurementUnitListBox';
import { getTableData } from '../../hooks/fetchHook';
import { CustomerListBox } from './CustomerListBox';

export const AddDiscountScreen = () => {


    const [data, setData] = useState([]);
    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.ui.loading);
    const editingDiscount = useSelector(state => state.ui.editingDiscount);
    const isSidebarActive = useSelector(state => state.ui.sidebar);
    const realm = useSelector(state => state.auth.realm);
    const [formValues, setFormValues] = useState(editingDiscount);
    const [form] = Form.useForm();

    const layout = {
        labelCol: { span: 10 },
        wrapperCol: { span: 100 }
    };

    const handleInputchange = ({ target }) => {
        setFormValues({
            ...formValues,
            [target.id]: target.value,
            realmFk: realm
        })
    };


    // const handleSelectchange = ( target) => {
    //     setFormValues({
    //         ...formValues,
    //         measurementUnit: target,
    //     })
    // };

    const handleSave = async () => {
        try {
            await form.validateFields()
                .then(() => {
                    dispatch(addOrUpdate(formValues, 'discount'))
                    form.resetFields()
                    console.log(formValues)
                })
        }
        catch (err) { }
    }

    const handleDiscart = () => {
        form.resetFields()
        dispatch(clearDiscountInfo())
        dispatch(openScreen('uiOpenListDiscount'))
    };

    const handdleStatus = (e) => {
        setFormValues({
            ...formValues,
            status: e
        })
    };

    const handleDateFrom = (date) => {
        setFormValues({
            ...formValues,
            validFrom: date,
        })
    }

    const handleDateUntil = (date) => {
        setFormValues({
            ...formValues,
            validUntil: date,
        })
    }

    const handdleSelect = (e) => {
        setFormValues({
            ...formValues,
            productFk: e
        })

    };



    useEffect(() => {
        dispatch(getTableData('product', realm))
            .then((response) => {
                setData(response)



            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    let children = [];
    const { Option } = Select;

    for (const prop of data) {
        children.push(<Option key={prop.id}>{prop.name}</Option>)
    };

    return (
        <div>
            <div className={isSidebarActive ? '_OCS-overlay' : '_overlay'}></div>
            <div className='_header-container3'>
                <div className={isSidebarActive ? '_OCS-screen-header' : '_screen-header'}>
                    <h2>Crear Descuento</h2>
                </div>
                <div className={isSidebarActive ? "_btn2-1_" : "_btn2_"}>
                    <div className="_btns-container2">
                        <Button className="_btn-primary _ant-add-btn " onClick={handleDiscart} type="primary">
                            Volver
                        </Button>
                    </div>

                </div>
            </div>
            <div className={isSidebarActive ? '_OCS-ant-card-body _OCS-ant-card-form-group' : '_ant-card-body _ant-card-form-group'}>
                <div className='_ant-card-header'>Información Básica</div>
                <Form
                    form={form}
                    layout='vertical'
                    {...layout}
                    name="discountForm"
                >



                    {/* <Form.Item
                        label="ID Cliente"
                        name="customerFk"
                        rules={[
                            {
                                required: true,
                                message: 'Favor ingresar ID del Cliente',
                            },
                        ]}
                    >
                        <Input
                            id='customerFk'
                            onChange={handleInputchange}
                            size='large'
                        />
                    </Form.Item> */}




                    <CustomerListBox
                        handler={handleInputchange}
                        initialValue={editingDiscount.customerfk}
                    />


                    <Form.Item
                        label="Producto"
                        name="productFk"
                        rules={[
                            {
                                required: true,
                                message: 'Favor seleccionar producto',
                            }
                        ]}
                    >
                        <Select
                            showSearch
                            onChange={handdleSelect}
                            allowClear
                        >{children}</Select>
                    </Form.Item>









                    {/* <Form.Item
                        label="ID Producto"
                        name="productFk"
                        rules={[
                            {
                                required: true,
                                message: 'Favor ingresar ID del Cliente',
                            },
                        ]}
                    >
                        <Input
                            id='productFk'
                            onChange={handleInputchange}
                            size='large'
                        />
                    </Form.Item> */}











                    <Form.Item
                        label="Válido Desde"
                        name="validFrom"
                        rules={[
                            {
                                required: true,
                                message: 'Favor ingresar fecha válida',
                            },
                        ]}
                    >
                        <DatePicker
                            onChange={handleDateFrom}
                            format="YYYY-MM-DD HH:mm:ss"
                            showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                        />
                    </Form.Item>

                    <Form.Item
                        label="Válido Hasta"
                        name="validUntil"
                        rules={[
                            {
                                required: true,
                                message: 'Favor ingresar fecha válida',
                            },
                        ]}
                    >
                        <DatePicker
                            onChange={handleDateUntil}
                            format="YYYY-MM-DD HH:mm:ss"
                            showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                        />
                    </Form.Item>
                </Form>
            </div>
            <div className={isSidebarActive ? '_OCS-ant-card-body _OCS-ant-card-form-group' : '_ant-card-body _ant-card-form-group'}>
                <div className='_ant-card-header'>Informacion del Recipiente</div>
                <div className='_ant-form-wrapper'>
                    <div>
                        <Form
                            form={form}
                            {...layout}
                            layout='vertical'
                            name="deviceInfoForm"
                        >
                            <Form.Item
                                label="Estatus"
                                name="status"
                                valuePropName="checked"
                            >
                                <Switch defaultChecked checkedChildren="Activo" unCheckedChildren="Inactivo" onChange={handdleStatus}></Switch>
                            </Form.Item>


                            <MeasurementUnitListBox handler={handleInputchange} />
                        </Form>
                    </div>

                    <div>
                        <Form
                            form={form}
                            {...layout}
                            layout='vertical'
                            name="discountForm"
                        >
                            <Form.Item
                                label="Monto"
                                name="flat"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Favor ingresar porcentaje',
                                    },
                                ]}
                            >
                                <Input size='large' onChange={handleInputchange} id="flat" type='number' />
                            </Form.Item>

                            <Form.Item
                                label="Procentaje"
                                name="percentage"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Favor ingresar porcentaje',
                                    },
                                ]}
                            >
                                <Input
                                    id='percentage'
                                    onChange={handleInputchange}
                                    size='large'
                                />
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </div>
            <div className={
                isSidebarActive
                    ? "_OCS-ant-card-body _OCS-ant-card-form-group _btn-save-container"
                    : "_ant-card-body _ant-card-form-group _btn-save-container"
            }>
                <div >
                    <Button
                        className="_btn-primary _ant-add-btn "
                        loading={isLoading}
                        onClick={handleSave}
                        type="primary"
                    >
                        Crear
                    </Button>
                </div>
            </div>

        </div>
    )
};