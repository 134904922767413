import React, { useState } from "react";
import { Button, Form, Input, Switch, Row, Col } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { permissions } from "../../helpers/permissions";
import { addOrUpdate } from "../../hooks/fetchHook";
import { clearUserInfo, openScreen } from "../../actions/ui";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";

export const AddUserScreen = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.ui.loading);
  const editingUserInfo = useSelector((state) => state.ui.editingUser);
  const isSidebarActive = useSelector((state) => state.ui.sidebar);
  const realm = useSelector((state) => state.auth.realm);
  const [formValues, setFormValues] = useState(editingUserInfo);
  const [form] = Form.useForm();
  const [permisos, setPermisos] = useState([]);

  const layout = {
    labelCol: { span: 10 },
    wrapperCol: { span: 100 },
  };

  const handdleInputChange = ({ target }) => {
    setFormValues({
      ...formValues,
      [target.id]: target.value,
      realmFk: realm,
    });
  };

  const handdleSave = async () => {
    try {
      await form.validateFields().then(() => {
        dispatch(addOrUpdate({...formValues, permission: permisos}, "user"));
        form.resetFields();
        console.log(formValues)
      });
    } catch (err) {}
  };

  const handdleDiscart = () => {
    form.resetFields();
    dispatch(clearUserInfo());
    dispatch(openScreen("uiOpenListUser"));
  };

  const handdleSwitchCheck = (e) => {
    setFormValues({
      ...formValues,
      status: e,
    });
  };

  const handdleChecked = (e, value) => {
    if (e === true) {
      setPermisos([...permisos, `${value}`])
    } else {
      setPermisos(permisos.filter((permiso) => permiso !== value.toString()))
    }
  };

  let children = [];

  for (const prop of permissions) {
    // children.push(<Option key={prop.value}>{prop.label}</Option>)
    children.push(
      <Col span={8} key={prop.value}>
        <Form.Item label={prop.label} valuePropName="checked">
          <Switch
            size="small"
            name={prop.value}
            onChange={(e) => handdleChecked(e, prop.value)}
          />
        </Form.Item>
      </Col>
    );
  }


  return (
    <div>
      <div className={isSidebarActive ? "_OCS-overlay" : "_overlay"}></div>
      <div className="_header-container3">
        <div
          className={isSidebarActive ? "_OCS-screen-header" : "_screen-header"}
        >
          <h2>Crear Usuario</h2>
        </div>

        <div className={isSidebarActive ? "_btn2-1_" : "_btn2_"}>
          <div className="_btns-container2">
            <Button
              className="_btn-primary _ant-add-btn "
              onClick={handdleDiscart}
              type="primary"
            >
              Volver
            </Button>
          </div>
        </div>
        {/* <div className="_btns-container">
          <Button className="_discard-btn" onClick={handdleDiscart}>
            Volver
          </Button>
          <Button
            className="_btn-primary _ant-add-btn"
            loading={isLoading}
            onClick={handdleSave}
            type="primary"
          >
            Crear
          </Button>
        </div> */}
      </div>
      <div
        className={
          isSidebarActive
            ? "_OCS-ant-card-body _OCS-ant-card-form-group"
            : "_ant-card-body _ant-card-form-group"
        }
      >
        <div className="_ant-card-header">Información Básica</div>
        <Form form={form} layout="vertical" {...layout} name="userInfoForm">
          <Form.Item label="Nombre" name="name">
            <Input size="large" onChange={handdleInputChange} id="name" />
          </Form.Item>

          <Form.Item label="Nombre de Usuario" name="userName">
            <Input size="large" onChange={handdleInputChange} id="userName" />
          </Form.Item>

          <Form.Item label="Contraseña" name="password">
            <Input.Password
              style={{
                borderRadius: "0.625rem",
              }}
              size="small"
              onChange={handdleInputChange}
              id="password"
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
            />
          </Form.Item>

          <Form.Item
            label="Código"
            name="code"
            rules={[
              {
                //   required: true,
                message: "Favor ingresar Código",
              },
            ]}
          >
            <Input size="large" onChange={handdleInputChange} id="code" type="number" />
          </Form.Item>
        </Form>
        <div>
          <Form form={form} {...layout} layout="vertical" name="deviceInfoForm">
            <Form.Item name="status" label="Estatus" valuePropName="checked">
              <Switch
                defaultChecked
                checkedChildren="Activo"
                unCheckedChildren="Inactivo"
                onChange={handdleSwitchCheck}
              />
            </Form.Item>
          </Form>
        </div>
      </div>
      <div
        className={
          isSidebarActive
            ? "_OCS-ant-card-body _OCS-ant-card-form-group"
            : "_ant-card-body _ant-card-form-group"
        }
      >
        <div className="_ant-card-header">Permisos</div>
        <div className="_ant-form-wrapper">
          <div>
            <Form
              form={form}
              {...layout}
              layout="vertical"
              name="deviceInfoForm2"
              labelCol={{
                span: 16,
              }}
            >
              <Row>{children}</Row>
            </Form>
          </div>
        </div>
      </div>
      <div  className={
            isSidebarActive
              ? "_OCS-ant-card-body _OCS-ant-card-form-group _btn-save-container"
              : "_ant-card-body _ant-card-form-group _btn-save-container"
          }>
        <div >
          <Button
            className="_btn-primary _ant-add-btn "
            loading={isLoading}
            onClick={handdleSave}
            type="primary"
          >
            Crear
          </Button>
        </div>
      </div>
    </div>
  );
};
