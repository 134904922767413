import { Descriptions } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { mapType } from '../helpers/mapType';

export const DocumentDetails = () => {
    let documentDetails = useSelector(state => state.ui.documentDetails);
 
    let userInfo = null;

    let vatId = documentDetails.vatId;
    let name = "";
    if (documentDetails.customer !== undefined)
    {
        name = documentDetails.customer.name;
        vatId = documentDetails.customer.rnc;
    }

    try {

        userInfo = <Descriptions.Item label="Cliente" >
            Nombre: {name}
            <br />
            Cédula o RNC: {vatId}
        </Descriptions.Item>


    } catch (err) {
        console.log(err)        
    }


    return (
        <Descriptions 
            bordered
            column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
            size= "middle"
            >
            {userInfo}
            <Descriptions.Item label="Tipo de Documento">{mapType( documentDetails.documentType)}</Descriptions.Item>
            <Descriptions.Item label="Concepto">{documentDetails.concept}</Descriptions.Item>
            <Descriptions.Item label="Numero de Comprobante Fiscal">{documentDetails.taxReceiptNumber}</Descriptions.Item>
            <Descriptions.Item label="NCF Valido Hasta">{documentDetails.ncfValidUntil}</Descriptions.Item>
            <Descriptions.Item label="Número">{documentDetails.number}</Descriptions.Item>
            <Descriptions.Item label="Ordinal">{documentDetails.ordinal}</Descriptions.Item>
            <Descriptions.Item label="Secuencia Global">{documentDetails.globalSequence}</Descriptions.Item>
            <Descriptions.Item label="Sub Total">{documentDetails.subTotal}</Descriptions.Item>
            <Descriptions.Item label="Total">{documentDetails.amount}</Descriptions.Item>
            <Descriptions.Item label="Impuestos">{documentDetails.taxAmount}</Descriptions.Item>
            <Descriptions.Item label="Total a Pagor">{documentDetails.paymentAmount}</Descriptions.Item>
            <Descriptions.Item label="Firma"><img alt="Firma" src={`data:image/png;base64,${documentDetails.signature}`} /></Descriptions.Item>
        </Descriptions>
    )
};