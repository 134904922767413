import React from 'react';
import { Table } from 'antd';
import 'antd/dist/antd.css';
import { useSelector } from 'react-redux';


export const DocumentPayments = () => {
    const documentDetails = useSelector(state => state.ui.documentDetails)

    const columns = [
        {
          title: 'Referencia',
          width: 100,
          fixed: 'left',
          dataIndex: 'reference',
          key: 'reference',
          sorter: (a, b) => a.reference.length - b.reference.length
        },
        {
            title: 'Monto',
            width: 90,
            dataIndex: 'amount',
            key: 'amount',
            sorter: (a, b) => a.amount - b.amount
        },
    ];

    return (
        <div>
            <Table
                locale={{
                    triggerAsc: 'Click para ordernar ascendente',
                    triggerDesc: 'Click para ordernar descendente',
                    cancelSort: 'Click para cancelar orden',
                }}
                dataSource={documentDetails.payments}
                scroll={{ x: 400 }}
                columns={columns}
            />
        </div>
    )
}