import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import 'antd/dist/antd.css';
import {
  AiOutlineMore,
  AiOutlineEdit,
  AiOutlineDelete,
} from 'react-icons/ai';
import { Popover, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { deleteDelivery, deliveryToEdit, openScreen, refreshMyKey } from '../actions/ui';
import { Restricted } from '../hooks/useRestricted';
import { getTableData } from '../hooks/fetchHook';

import { ExportData } from '../components/xlsx/ExportData';
import { ImportData } from '../components/xlsx/ImportData';
import { formatExport, formatImport, formatToTable } from '../helpers/columnsFormat';
import { getEmptyFilters } from '../helpers/getEmptyFilters';
import { FilterPanel } from "../components/ui/FilterPanel";

import Cookies from "universal-cookie";

const cookies = new Cookies();

export const ListDeliveriesTable = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [customers, setCustomers] = useState([]);
  const refreshKey = useSelector(state => state.ui.refreshKey);
  const realm = useSelector(state => state.auth.realm)

  const filterFields = [
    {
      id: "label",
      name: "Etiqueta",
      type: "string",
    },
    {
      id: "customerName",
      name: "Nombre Cliente",
      type: "string",
    },

  ]
  const [filters, setFilters] = useState(getEmptyFilters(filterFields));

  const ActionType = Object.freeze({
    VIEW_DETAILS: {
      label: 'Editar',
      icon: <><AiOutlineEdit /></>,
      handler(item) {
        dispatch(openScreen('uiOpenEditDelivery'))
        dispatch(deliveryToEdit(item))
      }
    },
    DELETE: {
      label: 'Eliminar',
      icon: <><AiOutlineDelete /></>,
      async handler(item) {
        await dispatch(deleteDelivery(item))
        dispatch(refreshMyKey(refreshKey + 1))
      }
    }
  });

  useEffect(() => {
    if (cookies.get('customer') === undefined) {
      dispatch(getTableData('customer', realm))
          .then(async (response) => {
              setCustomers(response);
          })
          .catch((err) => {
              console.log(err);
          });
  }

    dispatch(getTableData('deliveryroute', realm))
    .then((response) => {
      for (let prop of response) {
        prop.key = prop.id
        prop.actions = [ActionType.VIEW_DETAILS, ActionType.DELETE]
      }
      setData(response)
    })
    .catch(err => {
      console.log(err)
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, refreshKey, realm, filters]);

  const columns = [
    {
       title: 'ID',
       width: 70,
       fixed: 'left',
       dataIndex: 'id',
       key: 'id',
      sorter: (a, b) => a.id - b.id
     },
    {
      title: 'Etiqueta',
      width: 90,
      dataIndex: 'label',
      key: 'label',
      sorter: (a, b) => a.ordinal - b.ordinal
    },
    {
      title: 'Cliente',
      width: 160,
      dataIndex: 'customerFk',
      key: 'customerFk',
      sorter: (a, b) => a.customerFk.length - b.customerFk.length,
      render: (a) => {
        var c = customers.find(c => c.id === a)
        if (c) return <p>{c.name}</p>
        return <p>{a}</p>
      }
    },
    // {
    //   title: 'Ordinal',
    //   width: 90,
    //   dataIndex: 'ordinal',
    //   key: 'ordinal',
    //   sorter: (a, b) => a.ordinal - b.ordinal
    // },
    {
      title: 'Mensaje',
      width: 160,
      dataIndex: 'message',
      key: 'message',
    },
    {
      title: 'Estatus',
      width: 100,
      dataIndex: 'status',
      key: 'status',
      sorter: (a, b) => a.status - b.status
    },
    {
      key: 'actions',
      width: 70,
      render(actionList, row) {
        return (
          <Restricted permission={'Deliverys'}>
            <Popover
              className='_more-btn'
              content={
                <>
                  {
                    row.actions !== undefined 
                    ? actionList.actions.map((definition,index) => <div key={index} className="_ops-option" onClick={() => definition.handler(row)}>{definition.icon} {definition.label}</div>)
                    : null
                  }
                </>
              }
            >
              {
                <Button><AiOutlineMore size='20' /></Button>
              }
            </Popover>
          </Restricted>
        );
      }
    }
  ];

  return (
    <div>
      <FilterPanel
        setFilters={setFilters}
        filterFields={filterFields}
      />
      <Table
        locale={{
          triggerAsc: 'Click para ordernar ascendente',
          triggerDesc: 'Click para ordernar descendente',
          cancelSort: 'Click para cancelar orden',
        }}
        onChange={() => formatToTable(columns)}
        dataSource={data.filter((c) => {
          console.log(c)

          var customer = customers.find(a => a.id === c.customerFk)

          if (customer === undefined) {
            customer = ''
          }else{
            customer = customer.name
          }


          if (
            c.label.toString().toLowerCase().includes(filters.label.toLowerCase()) &&
            customer.toLowerCase().includes(filters.customerName.toLowerCase())
          ) {
            return c;
          }
        return null;
      })}
        columns={formatToTable(columns)}
        scroll={{ x: 400 }}
        footer={() => {
          return(
            <>
              <Restricted permission={'Deliverys'}>
                <ImportData columns={formatImport(columns)} tableName={'deliveryroute'} />
              </Restricted>
              <ExportData columns={formatExport(columns)} data={data} tableName={'deliveryroute'}  />
            </>
          )
        }}
      />
    </div>
  )
}