import React, {useState} from 'react';
import {Button, Form, Input, Switch, Select} from 'antd';
import {useDispatch, useSelector} from 'react-redux';
import {clearDeliveryInfo, openScreen} from '../../actions/ui';
import {addOrUpdate} from '../../hooks/fetchHook';
import {CustomerListBox} from './CustomerListBox';

export const AddDeliveryScreen = () => {

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.ui.loading);
    const editingDelivery = useSelector(state => state.ui.editingDelivery);
    const isSidebarActive = useSelector(state => state.ui.sidebar);
    const realm = useSelector(state => state.auth.realm);
    const [formValues, setFormValues] = useState(editingDelivery);
    const [form] = Form.useForm();

    const layout = {
        labelCol: {span: 10},
        wrapperCol: {span: 100}
    };

    const handleInputchange = ({target}) => {
        setFormValues({
            ...formValues,
            [target.id]: target.value,
            realmFk: realm
        })
    };

    const handleSave = async () => {
        try {
            await form.validateFields()
                .then(() => {
                    dispatch(addOrUpdate({
                        ...formValues,
                        ordinal: parseInt(formValues.ordinal),
                        customerFk: parseInt(formValues.customerFk)
                    }, 'deliveryroute'))
                    form.resetFields()
                })
        } catch (err) {
        }
    }

    const handleDiscart = () => {
        form.resetFields()
        dispatch(clearDeliveryInfo())
        dispatch(openScreen('uiOpenListDelivery'))
    };

    const handdleStatus = (e) => {
        setFormValues({
            ...formValues,
            status: e
        })
    };

    const handdleDocumentType = (e) => {
        setFormValues({
            ...formValues,
            process: e
        })
    }

    return (
        <div>
            <div className={isSidebarActive ? '_OCS-overlay' : '_overlay'}></div>
            <div className='_header-container3'>
                <div className={isSidebarActive ? '_OCS-screen-header' : '_screen-header'}>
                    <h2>Crear Delivery</h2>
                </div>


                <div className={isSidebarActive ? "_btn2-1_" : "_btn2_"}>
                    <div className="_btns-container2">
                        <Button className="_btn-primary _ant-add-btn " onClick={handleDiscart} type="primary">
                            Volver
                        </Button>
                    </div>
                </div>
            </div>


            <div
                className={isSidebarActive ? '_OCS-ant-card-body _OCS-ant-card-form-group' : '_ant-card-body _ant-card-form-group'}>
                <div className='_ant-card-header'>Información Básica</div>
                <Form
                    form={form}
                    layout='vertical'
                    {...layout}
                    name="deviceInfoForm"
                >
                    <Form.Item
                        label="Estatus"
                        name="status"
                        valuePropName="checked"
                    >
                        <Switch defaultChecked checkedChildren="Activo" unCheckedChildren="Inactivo"
                                onChange={handdleStatus}></Switch>
                    </Form.Item>

                    <Form.Item
                        label="Ordinal"
                        name="ordinal"
                        rules={[
                            {
                                required: true,
                                message: 'Favor ingresar Ordinal',
                            },
                        ]}
                    >
                        <Input
                            id='ordinal'
                            type='number'
                            onChange={handleInputchange}
                            size='large'
                        />
                    </Form.Item>

                    <Form.Item
                        label="Etiqueta"
                        name="label"
                    >
                        <Input
                            id='label'
                            onChange={handleInputchange}
                            size='large'
                        />
                    </Form.Item>
                </Form>
            </div>
            <div
                className={isSidebarActive ? '_OCS-ant-card-body _OCS-ant-card-form-group' : '_ant-card-body _ant-card-form-group'}>
                <div className='_ant-card-header'>Informacion del Recipiente</div>
                <div className='_ant-form-wrapper'>
                    <div>
                        <Form
                            form={form}
                            {...layout}
                            layout='vertical'
                            name="deviceInfoForm"
                        >
                            {/* <Form.Item
                                label="Cliente"
                                name="customerFk"
                                rules={[
                                    {
                                      required: true,
                                      message: 'Favor ingresar Cliente',
                                    },
                                  ]}
                            >
                                <Input
                                    id='customerFk'
                                    type='number'
                                    onChange={handleInputchange}
                                    size='large'
                                />
                            </Form.Item> */}
                            <CustomerListBox
                                handler={handleInputchange}
                                initialValue={editingDelivery.customerfk}
                            />
                        </Form>
                    </div>

                    <div>
                        <Form
                            form={form}
                            {...layout}
                            layout='vertical'
                            name="deviceInfoForm"
                        >
                            {/* <Form.Item
                                label="Proceso"
                                name="process"
                            >
                                <Input size='large' onChange={handleInputchange} id="process" />
                            </Form.Item> */}
                            <Form.Item
                                label="Tipo de Documento"
                                name="process"
                            >
                                <Select
                                    placeholder="Seleccionar Documento"
                                    size='large'
                                    onChange={handdleDocumentType}
                                    allowClear
                                >
                                    <Select.Option value={0}>Conduce</Select.Option>
                                    <Select.Option value={1}>Factura</Select.Option>
                                    <Select.Option value={3}>Trans LNV</Select.Option>
                                    <Select.Option value={4}>Recibo</Select.Option>
                                    <Select.Option value={5}>Pedido</Select.Option>
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label="Mensaje"
                                name="message"
                            >
                                <Input.TextArea rows={3} onChange={handleInputchange} id="message"/>
                            </Form.Item>

                        </Form>
                    </div>
                </div>
            </div>
            <div className={
                isSidebarActive
                    ? "_OCS-ant-card-body _OCS-ant-card-form-group _btn-save-container"
                    : "_ant-card-body _ant-card-form-group _btn-save-container"
            }>
                <div>
                    <Button
                        className="_btn-primary _ant-add-btn "
                        loading={isLoading}
                        onClick={handleSave}
                        type="primary"
                    >
                        Crear
                    </Button>
                </div>
            </div>
        </div>
    )
};