import {permissions} from "../helpers/permissions";
import {
    AiOutlineCheckCircle,
    AiOutlineCloseCircle,
} from 'react-icons/ai';

export const formatToTable = (columns) => {

    columns = columns.filter(
        (columna) => columna.key !== "id" && columna.key !== "password"
    );
    return columns.map((columna) => {
        switch (columna.key) {
            case "permission":
                columna.render = (text) => {
                    let permissionList = [];

                    for (let i = 0; i < permissions.length; i++) {
                        if ((text & permissions[i].value) === permissions[i].value) {
                            permissionList.push(`${permissions[i].label}, `);
                        }
                    }

                    return <div>{permissionList}</div>
                };
                break;
            case "status":
                columna.render = (text) => {
                    if (text === true) {
                        return <div style={{color: '#04d182'}}>Activo <AiOutlineCheckCircle size='15'/></div>
                    } else if (text === false) {
                        return <div style={{color: '#ff6b72'}}>Inactivo <AiOutlineCloseCircle size='15'/></div>
                    } else if (text === 5) {
                        return <div style={{color: '#04d182'}}>Disponible <AiOutlineCloseCircle size='15'/></div>
                    } else if (text === 10) {
                        return <div style={{color: '#0460d1'}}>Entregado <AiOutlineCloseCircle size='15'/></div>
                    } else {
                        return <div style={{color: '#ff6b72'}}>Cancelado <AiOutlineCloseCircle size='15'/></div>
                    }
                }
                break;
            default:
                break;
        }
        return columna;
    });
};

export const formatExport = (columns) => {
    columns = columns.filter(
        (columna) =>
            columna.key !== "permission" &&
            columna.key !== "password" &&
            columna.key !== "actions"
    );
    return columns.map((columna) => {
        switch (columna.key) {
            case "permission":
                columna.render = (text) => {
                    let permissionList = [];

                    for (let i = 0; i < permissions.length; i++) {
                        if ((text & permissions[i].value) === permissions[i].value) {
                            permissionList.push(`${permissions[i].label}, `);
                        }
                    }

                    return permissionList.toString();
                };
                break;
            case "itsWarehouse":
                columna.render = (text) => {
                    if (text === true) {
                        return "Disponible";
                    } else {
                        return "Inactivo";
                    }
                };
                break;
            case "status":
                columna.render = (text) => {
                    if (text === true) {
                        return "Activo";
                    } else {
                        return "Inactivo";
                    }
                };
                break;
            case "referenceUsed":
                columna.render = (text) => {
                    if (text === true) {
                        return "Utilizado";
                    } else {
                        return "No utlizado";
                    }
                };
                break;
            default:
                break;
        }
        return columna;
    });
};

export const formatImport = (columns) => {
    return columns.filter(
        (columna) => columna.key !== "permission" && columna.key !== "password" && columna.key !== "actions"
    );
};
