import { useState } from "react";
import {
  MapContainer,
  Marker,
  TileLayer,
  useMapEvents,
  Circle,
} from "react-leaflet";
import L from "leaflet";
import { Slider } from "antd";

import "leaflet/dist/leaflet.css";

const icon = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-icon.png",
  shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png",
});

export const MapInput = ({ handler,formValues, lat=18.4822881, lon=-69.9229807, rad=1 }) => {
  const [ubi, setUbi] = useState([lat, lon]);
  const [radios, setRadius] = useState(rad);
  
  function MyComponent() {
    useMapEvents({
      click: (e) => {
        console.log(e)
        if (e.latlng === undefined) return (0,0)
        const { lat, lng } = e.latlng;
        setUbi([lat, lng]);
        handler({
          ...formValues,
          latitud: lat,
          longitud: lng
        })
        // L.marker([lat, lng], { icon }).addTo(map);
      },
    });
    return null;
  }

  const onChangeRadius = (e) => {
    setRadius(e * 10);
    handler({
      ...formValues,
      radio: e
    })
  };

  return (
    <>
      <p>Seleccione una ubicación y un rango para editar</p>
      <div style={{ width: "100%", height: "400px", display: 'flex' }}>
        <Slider vertical onChange={onChangeRadius}  />
        <MapContainer
          center={ubi}
          zoom={15}
          >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
          <MyComponent />
          <Circle center={ubi} radius={radios} />
          <Marker position={ubi} icon={icon} />
        </MapContainer>
      </div>
    </>
  );
};
