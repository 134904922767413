import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
//import { AiOutlinePlusCircle } from 'react-icons/ai';

import { openScreen } from '../../actions/ui';
import { ListDocumentsTable } from '../../antTables/ListDocumentsTable';

export const ListDocumentScreen = () => {
    const isSidebarActive = useSelector(state => state.ui.sidebar);

    const dispatch = useDispatch();
   
    // eslint-disable-next-line no-unused-vars
    const handdleAddDocument = () => {
        dispatch(openScreen('uiOpenAddDocument'))
    };

    return (
        <div>
            <div className={isSidebarActive ? '_OCS-overlay' : '_overlay'}></div>
            <div className={isSidebarActive ? '_OCS-screen-header' : '_screen-header'}>
                <h2>Lista de Documentos</h2>
            </div>
            <div className={isSidebarActive ? '_OCS-ant-card-body' : '_ant-card-body'}>
                <div className={isSidebarActive ? '_OCS-table-responsive' : '_table-responsive'}>
                    <ListDocumentsTable />
                </div>
            </div>
        </div>
    )
}