import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import 'antd/dist/antd.css';
import {
    AiOutlineCheckCircle,
    AiOutlineCloseCircle,
    AiOutlineMore,
    AiOutlineEdit,
    AiOutlineDelete
} from 'react-icons/ai';
import { Popover, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { deleteNCFRange, NCFRangeToEdit, openScreen, refreshMyKey } from '../actions/ui';
import { Restricted } from '../hooks/useRestricted';
import { getTableData } from '../hooks/fetchHook';
import moment from 'moment';

import { ExportData } from '../components/xlsx/ExportData';
import { ImportData } from '../components/xlsx/ImportData';
import { formatExport, formatImport, formatToTable } from '../helpers/columnsFormat';
import { docTypes } from '../helpers/ncfType';

export const ListNCFRangeTable = () => {
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const refreshKey = useSelector(state => state.ui.refreshKey);
    const realm = useSelector(state => state.auth.realm)
    
    // let toLocal = moment.parseZone("2022-03-30T18:05:03").local().format("YYYY-MM-DD HH:mm:ss")
    // console.log({toLocal})
    // console.log('utc', moment().utc(toLocal).format("YYYY-MM-DD HH:mm:ss"))

    const ActionType = Object.freeze({
        VIEW_DETAILS: {
            label: 'Editar',
            icon: <><AiOutlineEdit /></>,
            handler(item) {
                dispatch(openScreen('uiOpenEditNCFRange'))
                dispatch(NCFRangeToEdit(item))
            }
        },
        DELETE: {
            label: 'Eliminar',
            icon: <><AiOutlineDelete /></>,
            async handler(item) {
                await dispatch(deleteNCFRange(item))
                dispatch(refreshMyKey(refreshKey + 1))
            }
        }
    });

    useEffect(() => {
        dispatch(getTableData('ncfrange', realm))
            .then((response) => {
                for (let prop of response) {
                    prop.key = prop.id
                    prop.actions = [ActionType.VIEW_DETAILS, ActionType.DELETE]
                    prop.validUntil = moment.parseZone(prop.validUntil).local().format("YYYY-MM-DD HH:mm:ss")
                }
                setData(response)
                
            })
            .catch(err => {
                console.log(err)
            })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, refreshKey]);

    const columns = [
        {
            title: 'ID',
            width: 70,
            fixed: 'left',
            dataIndex: 'id',
            key: 'id',
            sorter: (a, b) => a.id - b.id
        },
        {
            title: 'Nombre',
            width: 90,
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => a.ordinal - b.ordinal
        },
        {
            title: 'Ordinal',
            width: 90,
            dataIndex: 'ordinal',
            key: 'ordinal',
            sorter: (a, b) => a.ordinal - b.ordinal
        },
        {
            title: 'Tipo NCF',
            width: 120,
            dataIndex: 'typeNcf',
            key: 'typeNcf',
            sorter: (a, b) => a.typeNcf - b.typeNcf,
            render: (text) => {
                for (const prop of docTypes) {
                  if (text === prop.value) {
                    return <div>{prop.label}</div>
                  }
                }
              }
            
           
        },
        {
            title: 'Secuencia Inicial',
            width: 120,
            dataIndex: 'startSequence',
            key: 'startSequence', 
            sorter: (a, b) => a.startSequence - b.startSequence
        },
        {
            title: 'Secuencia Final',
            width: 120,
            dataIndex: 'finalSequence',
            key: 'finalSequence',
            sorter: (a, b) => a.finalSequence - b.finalSequence
        },
        {
            title: 'Prefijo de Impusto',
            width: 170,
            dataIndex: 'taxPrefix',
            key: 'taxPrefix',
            sorter: (a, b) => a.taxPrefix.length - b.taxPrefix.length
        },
        {
            title: 'Válido Hasta',
            width: 150,
            dataIndex: 'validUntil',
            key: 'validUntil',
            sorter: (a, b) => a.validUntil.length - b.validUntil.length
        },
        {
            title: 'Estatus',
            width: 100,
            dataIndex: 'status',
            key: 'status',
            render: (text) => {
                if (text === true) {
                    return <div style={{ color: '#04d182' }}>Activo <AiOutlineCheckCircle size='15' /></div>
                }
                else {
                    return <div style={{ color: '#ff6b72' }}>Inactivo <AiOutlineCloseCircle size='15' /></div>
                }
            },
            filters: [
                {
                    text: 'Activo',
                    value: true,
                },
                {
                    text: 'Inactivo',
                    value: false,
                },
            ],
            onFilter: (value, record) => record.status === value,
        },
        {
            key: 'actions',
            width: 70,
            render(actionList, row) {
                return (
                    <Restricted permission={'NCF'}>
                        <Popover
                            className='_more-btn'
                            content={
                                <>
                                    {
                                        row.actions !== undefined 
                                        ? actionList.actions.map((definition,index) => <div key={index} className="_ops-option" onClick={() => definition.handler(row)}>{definition.icon} {definition.label}</div>)
                                        : null
                                    }
                                </>
                            }
                        >
                            {
                                <Button><AiOutlineMore size='20' /></Button>
                            }
                        </Popover>
                    </Restricted>
                );
            }
        }
    ];

    return (
      <div>
        <Table
          locale={{
            triggerAsc: "Click para ordernar ascendente",
            triggerDesc: "Click para ordernar descendente",
            cancelSort: "Click para cancelar orden",
          }}
          onChange={ () => formatToTable(columns)}
          dataSource={data}
          columns={formatToTable(columns)}
          scroll={{ x: 400 }}
          footer={() => {
            return (
              <>
                <Restricted permission={'NCF'}>
                  <ImportData
                    columns={formatImport(columns)}
                    tableName={"ncfrange"}
                  />
                </Restricted>
                <ExportData
                  columns={formatExport(columns)}
                  data={data}
                  tableName={"ncfrange"}
                />
              </>
            );
          }}
        />
      </div>
    );
}