import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    AiOutlineUnorderedList,
    AiOutlineUser,
} from 'react-icons/ai';
import { Popover, Button } from 'antd';


import handdoHLogo from '../../resources/logos/handdoLogoNew2.png';
import handdoHLogoL from '../../resources/logos/handdoLogoNew.png';

import { closeCurrentScreen, openScreen, sidebarToggle } from '../../actions/ui';
import { startLogout } from '../../actions/auth';
import { useHistory } from 'react-router-dom';


export const Navbar = () => {
    const dispatch = useDispatch();

    const sidebar = useSelector(state => state.ui.sidebar);
    const username = useSelector(state => state.auth.username);
    const businessName = useSelector(state => state.auth.businessName);

    const handdleSideBar = () => {
        dispatch(sidebarToggle(!sidebar))
    }

    const handleOpenScreen = (e) => {
        dispatch(closeCurrentScreen())
        dispatch(openScreen(e.currentTarget.value))
    };

    const handdleLogout = () => {
        dispatch(startLogout())
        routeChange()
    }

    const history = useHistory();

    const routeChange = () => {
        let path = `/`;
        history.push(path);
    };

    return (
        <div className='_navbar-container'>
            <div className='_logo-username-container'>
                <div>
                    <img
                        className={sidebar ? '_OCS-handdoH-png' : '_handdo-png'}
                        src={sidebar ?   handdoHLogo : handdoHLogoL}
                        alt='Handdo H logo'
                    ></img>
                </div>
                <button onClick={handdleSideBar} className='_menu-btn'>
                    <AiOutlineUnorderedList size='25' />
                </button>

                <select onChange={handleOpenScreen} name='Pages' id='pageSelect' defaultValue='DEFAULT'>
                    <option value='DEFAULT' disabled >Selecione una pantalla...</option>
                    <option className='_pageOption' value='uiOpenDashboard'>Inicio</option>
                    <option className='_pageOption' value='uiOpenListDevice'>Dispositivos</option>
                    <option className='_pageOption' value='uiOpenListUser'>Usuarios</option>
                    <option className='_pageOption' value='uiOpenListDelivery'>Deliverys</option>
                    <option className='_pageOption' value='uiOpenListCustomer'>Clientes</option>
                    <option className='_pageOption' value='uiOpenListDocument'>Documentos</option>
                    <option className='_pageOption' value='uiOpenListPaymentMethod'>Métodos de Pago</option>
                    <option className='_pageOption' value='uiOpenListProduct'>Productos</option>
                    <option className='_pageOption' value='uiOpenListTax'>Impuestos</option>
                    <option className='_pageOption' value='uiOpenListMeasurementUnit'>Unidades de medidas</option>
                    <option className='_pageOption' value='uiOpenListWarehouse'>Almacenes</option>
                </select>

            </div>

            <div className='_navbar-user-icon'>
                <div className='_navbar-icon'>
                    <Popover
                        className='_more-btn'
                        content={
                            <div>
                                <p >{username}</p>
                                <p >{businessName}</p>
                                <a className="_ops-option" onClick={handdleLogout} href='/login'>Logout</a>
                            </div>
                        }
                    >
                        <Button><AiOutlineUser size='27' /></Button>
                    </Popover>
                </div>
            </div>
        </div>
    )
}