import React, { useEffect, useState } from "react";
import { Table } from "antd";
import "antd/dist/antd.css";
import {
  AiOutlineCheckCircle,
  AiOutlineCloseCircle,
  AiOutlineMore,
  AiOutlineEdit,
  AiOutlineDelete,
} from "react-icons/ai";
import { Popover, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteWarehouse,
  WarehouseToEdit,
  openScreen,
  refreshMyKey,
} from "../actions/ui";
import { Restricted } from "../hooks/useRestricted";
import { getTableData } from "../hooks/fetchHook";

import { ExportData } from "../components/xlsx/ExportData";
import { ImportData } from "../components/xlsx/ImportData";
import {
  formatExport,
  formatImport,
  formatToTable,
} from "../helpers/columnsFormat";


export const ListWarehouseTable = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const refreshKey = useSelector((state) => state.ui.refreshKey);
  const realm = useSelector((state) => state.auth.realm);

  const ActionType = Object.freeze({
    VIEW_DETAILS: {
      label: "Editar",
      icon: (
        <>
          <AiOutlineEdit />
        </>
      ),
      handler(item) {
        dispatch(openScreen("uiOpenEditWarehouse"));
        dispatch(WarehouseToEdit(item));
      },
    },
    DELETE: {
      label: "Eliminar",
      icon: (
        <>
          <AiOutlineDelete />
        </>
      ),
      async handler(item) {
        await dispatch(deleteWarehouse(item));
        dispatch(refreshMyKey(refreshKey + 1));
      },
    },
  });

  useEffect(() => {
    dispatch(getTableData("warehouse", realm))
      .then((response) => {
        for (let prop of response) {
          prop.key = prop.id;
          prop.actions = [ActionType.VIEW_DETAILS, ActionType.DELETE];
        }
        setData(response);
        

      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshKey]);

  const columns = [
    {
      title: 'ID',
      width: 80,
      dataIndex: 'id',
      key: 'id',
      fixed: 'left',
      sorter: (a, b) => a.id - b.id
    },
    {
      title: "Almacen",
      width: 100,
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.length - b.name.length,
    }, {
      title: "Producto",
      width: 100,
      dataIndex: "productName",
      key: "productName",
      sorter: (a, b) => a.productName.length - b.productName.length,
    },
    {
      title: "Cantidad",
      width: 100,
      dataIndex: "quantity",
      key: "quantity",
      sorter: (a, b) => a.quantity.length - b.quantity.length,
    },
    {
      title: "Unidad de Medida",
      width: 100,
      dataIndex: "measurementUnit",
      key: "measurementUnit",
      sorter: (a, b) => a.measurementUnit.length - b.measurementUnit.length,
    },
    // {
    //   title: "Creado",
    //   width: 100,
    //   dataIndex: "created",
    //   key: "created",
    //   sorter: (a, b) => a.created.length - b.created.length,
    // },
    // {
    //   title: "Modificado",
    //   width: 100,
    //   dataIndex: "modified",
    //   key: "modified",
    //   sorter: (a, b) => a.modified.length - b.modified.length,
    // },

    {
      title: "Estatus",
      width: 100,
      dataIndex: "status",
      key: "status",
      render: (text) => {
        if (text === true) {
          return (
            <div style={{ color: "#04d182" }}>
              Activo <AiOutlineCheckCircle size="15" />
            </div>
          );
        } else {
          return (
            <div style={{ color: "#ff6b72" }}>
              Inactivo <AiOutlineCloseCircle size="15" />
            </div>
          );
        }
      },
      filters: [
        {
          text: "Activo",
          value: true,
        },
        {
          text: "Inactivo",
          value: false,
        },
      ],
      onFilter: (value, record) => record.status === value,
    },
    {
      key: "actions",
      width: 70,
      render(actionList, row) {
        return (
          <Restricted permission={"Producto"}>
            <Popover
              className="_more-btn"
              content={
                <>
                  {
                    row.actions !== undefined
                      ? actionList.actions.map((definition, index) => <div key={index} className="_ops-option" onClick={() => definition.handler(row)}>{definition.icon} {definition.label}</div>)
                      : null
                  }
                </>
              }
            >
              {
                <Button>
                  <AiOutlineMore size="20" />
                </Button>
              }
            </Popover>
          </Restricted>
        );
      },
    },
  ];

  return (
    <div>
      <Table
        locale={{
          triggerAsc: "Click para ordernar ascendente",
          triggerDesc: "Click para ordernar descendente",
          cancelSort: "Click para cancelar orden",
        }}
        onChange={() => formatToTable(columns)}
        dataSource={data}
        columns={formatToTable(columns)}
        scroll={{ x: 400 }}
        footer={() => {
          return (
            <>
              <Restricted permission={"Producto"}>
                <ImportData
                  columns={formatImport(columns)}
                  tableName={"Warehouse"}
                />
              </Restricted>
              <ExportData
                columns={formatExport(columns)}
                data={data}
                tableName={"Warehouse"}
              />
            </>
          );
        }}
      />
    </div>
  );
}