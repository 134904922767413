import { message } from 'antd';
import { fetchWithoutToken } from '../helpers/fetch.js';
import {types} from '../types/types.js';
import { loading, uiLogout } from './ui.js';
import jwt_decode from "jwt-decode";
import Cookies from "universal-cookie";
import { goToLogin } from '../helpers/goToLogin.js';

const cookies = new Cookies();

export const login = (credentials) => {
    return async(dispatch) => {
        dispatch(loading(true))

        try{
            const req = await fetchWithoutToken('authorize', credentials, 'POST')
            const res = await req.json();

            if(res.accessToken){
                // localStorage.setItem('token', res.accessToken)
                cookies.set("token", res.accessToken, { path: "/" });
                dispatch(loading(false))
    
                const decoded = jwt_decode(res.accessToken);
                dispatch(setPermissions(decoded.permissions))
                dispatch(setRealm(decoded.realm))
                dispatch(setUsername(decoded.Name))
                dispatch(setBusinessName(decoded.customer_name))
            } else {
                dispatch(loading(false))
                message.error('Credenciales inválidas, favor verificar usuario o contraseña')
            }
        }
        catch(err){
            message.error('Credenciales inválidas, favor verificar usuario o contraseña')
            dispatch(loading(false))
        }
    }
}

export const setUsername = (name) => ({
    type: types.setUsername,
    payload: name
})

export const setPermissions = (value) => ({
 type: types.setPermissions,
 payload: value   
});

export const setRealm = (value) => ({
 type: types.setRealm,
 payload: value   
});

export const setBusinessName = (value) => ({
 type: types.setBusinessName,
 payload: value   
});


export const startLogout = () => {
    return dispatch => {
        dispatch(logout())
        dispatch(uiLogout())
    }
}

const logout = () => {
    cookies.remove("token", { path: "/" });
    // cookies.remove("usuario", { path: "/" });
    // cookies.remove("token", { path: "/" });
    //
    // goToLogin()
}