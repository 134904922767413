import { React, useEffect,useState } from "react";
import { Form, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getTableData } from "../../hooks/fetchHook";
import Cookies from "universal-cookie";
// import { refreshMyKey } from "../../actions/ui";
// import moment from "moment/moment";

const cookies = new Cookies();
// const { Option } = Select;

export const WarehouseListBox = ({
  endpoint = "warehousegroup",
  label = "Almacen",
  fk = false,
  handler,
  initialValue = "",
  name = "name",
}) => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const realm = useSelector((state) => state.auth.realm);
  // const refreshKey = useSelector((state) => state.ui.refreshKey);

  const handlerInput = (e) => {
    handler({
      target: {
        id: fk ? name + "Fk" : name,
        value: e,
      },
    });
  };

  useEffect(() => {
    if (cookies.get(endpoint) === undefined) {
      dispatch(getTableData(endpoint, realm))
        .then((response) => {
          // for (let prop of response) {
          //   prop.key = prop.id;
          // }
          // cookies.set(endpoint, response, {
          //   path: "/",
          //   expires: new Date(moment().add(1, "m").format()),
          // });
          // console.log('la cookie para '+endpoint+' contiene '+response.toString())
          // dispatch(refreshMyKey(refreshKey + 1));
          setData(response);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let children = [];
  const { Option } = Select;

  for (const prop of data) {
      children.push(<Option key={prop.name}>{prop.name}</Option>)
  };

  return (
    <Form.Item label={label} name={endpoint} initialValue={initialValue}>
      <Select showSearch id={endpoint} onChange={(e) => handlerInput(e)}>
        {/* {cookies.get(endpoint) !== undefined
          ? cookies.get(endpoint).map((mu) => {
              return (
                <Option key={mu.key} value={fk ? mu.key : mu.name}>
                  {mu.name}
                </Option>
              );
            })
          : null} */}
          {children}
      </Select>
    </Form.Item>
  );
};
