import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {login} from '../../actions/auth';
import anime from 'animejs/lib/anime.es.js';
import HLogo from '../../resources/logos/handdoLogoNew3.png'
import Cookies from "universal-cookie";

const cookies = new Cookies();


export const LoginScreen = () => {

    const dispatch = useDispatch();
    const [formValues, setFormValues] = useState()

    if (process.env.NODE_ENV === 'development') {
        // Código que solo se ejecutará en modo desarrollo
        console.log("Estamos en modo desarrollo");
        // cookies.set("token", "eyJhbGciOiJIUzI1NiIsImtpZCI6IjZiMWE5MDU4LTgyZDQtNGU1Ny1iMWI0LTBiYjMzYWI1OTRiZCIsInR5cCI6IkJlYXJlciJ9.eyJzdWIiOiI0OTI4ZThiOS00ODVlLTRiNTUtOTc1NC1mMzY0NDBmMjY5NDkiLCJqdGkiOiJmYjYxNGNiZC04NWY3LTQ5MTYtOTE5Ny1hZGQxMzc0MTI5OTAiLCJjdXN0b21lciI6IjgwMWM0MzNlLWYzNmItMTQxMC04MGQ4LTAwZmZmZmZmZmZmZiIsImN1c3RvbWVyX25hbWUiOiJTb2xnYXMiLCJyZWFsbSI6IjgwMWM0MzNlLWYzNmItMTQxMC04MGQ4LTAwZmZmZmZmZmZmZiIsIk5hbWUiOiJTb2xnYXMgQWRtaW4iLCJwZXJtaXNzaW9ucyI6eyJEb2N1bWVudCI6MSwiRGVsaXZlcnlzIjoxLCJVbmlkYWRlcyBkZSBNZWRpZGEiOjEsIkRlc2N1ZW50byI6MSwiVXN1YXJpb3MiOjEsIkhpc3RvcmljbyBkZSBwcmVjaW8iOjEsIkltcHVlc3RvcyI6MSwiTcOpdG9kb3MgZGUgUGFnbyI6MSwiQ3VzdG9tZXIiOjEsIlByb2R1Y3RvIjoxLCJOQ0YiOjEsIk1hbmVqYWRvcmVzIGRlIFByb2R1Y3RvIjoxfSwibmJmIjoxNzIwNTQzMDg2LCJleHAiOjE3MjMxMzUwODYsImlhdCI6MTcyMDU0MzA4NiwiaXNzIjoiQmlmcm9zdCIsImF1ZCI6IkhhbmRkbyBNYW5hZ2VycyJ9.4ZzwfYl-BoNYRVGfmpAnkpKdc2WKDUItul0wCGzoUvM", {path: "/"});
    }


    const handleInputchange = ({target}) => {
        setFormValues({
            ...formValues,
            [target.id]: target.value
        })
    };

    const handdleLogin = async (e) => {
        e.preventDefault();
        try {
            dispatch(login(formValues))
        } catch (err) {
        }
    }


    useEffect(() => {


        var current = null;
        document.querySelector('#UserId').addEventListener('focus', function (e) {
            if (current) current.pause();
            current = anime({
                targets: 'path',
                strokeDashoffset: {
                    value: 0,
                    duration: 700,
                    easing: 'easeOutQuart'
                },
                strokeDasharray: {
                    value: '240 1386',
                    duration: 700,
                    easing: 'easeOutQuart'
                }
            });
        });


        document.querySelector('#Password').addEventListener('focus', function (e) {
            if (current) current.pause();
            current = anime({
                targets: 'path',
                strokeDashoffset: {
                    value: -336,
                    duration: 700,
                    easing: 'easeOutQuart'
                },
                strokeDasharray: {
                    value: '240 1386',
                    duration: 700,
                    easing: 'easeOutQuart'
                }
            });
        });
        document.querySelector('#submit').addEventListener('focus', function (e) {
            if (current) current.pause();
            current = anime({
                targets: 'path',
                strokeDashoffset: {
                    value: -730,
                    duration: 700,
                    easing: 'easeOutQuart'
                },
                strokeDasharray: {
                    value: '530 1386',
                    duration: 700,
                    easing: 'easeOutQuart'
                }
            });
        });


        document.addEventListener('keypress', function (event) {

            if (event.keyCode === 13) {

                let inputValue = document.getElementById("UserId").value;
                let inputValue2 = document.getElementById("Password").value;

                if (inputValue === "" || inputValue2 === "") {

                } else {
                    if (current) current.pause();
                    current = anime({
                        targets: 'path',
                        strokeDashoffset: {
                            value: -730,
                            duration: 700,
                            easing: 'easeOutQuart'
                        },
                        strokeDasharray: {
                            value: '530 1386',
                            duration: 700,
                            easing: 'easeOutQuart'
                        }
                    });

                }
            }
        });

    }, [])

    return (
        <div className="page">
            <div className='left-square'></div>
            <div className='right-square'></div>
            <div className="container">
                <div className="right">
                    <div className='img-container'><img className='handdo-img' alt="HLogo" src={HLogo}/></div>
                    <svg className='login-svg' viewBox="0 0 320 300">
                        <defs>
                            <linearGradient
                                id="linearGradient"
                                x1="13"
                                y1="193.49992"
                                x2="307"
                                y2="193.49992"
                                gradientUnits="userSpaceOnUse">
                                <stop
                                    className='stop1'
                                    offset="0"
                                    id="stop876"/>
                                <stop
                                    className='stop2'
                                    offset="1"
                                    id="stop878"/>
                            </linearGradient>
                        </defs>
                        <path className='login-path'
                              d="m 40,120.00016 239.99984,-3.2e-4 c 0,0 24.99263,0.79932 25.00016,35.00016 0.008,34.20084 -25.00016,35 -25.00016,35 h -239.99984 c 0,-0.0205 -25,4.01348 -25,38.5 0,34.48652 25,38.5 25,38.5 h 215 c 0,0 20,-0.99604 20,-25 0,-24.00396 -20,-25 -20,-25 h -190 c 0,0 -20,1.71033 -20,25 0,24.00396 20,25 20,25 h 168.57143"/>
                    </svg>
                    <form onSubmit={handdleLogin}>
                        <div className="form">
                            <label htmlFor='UserId'>Usuario</label>
                            <input type="text" id="UserId" autoComplete="off" onChange={handleInputchange} required
                                   autoFocus></input>
                            <label htmlFor="Password">Contraseña</label>
                            <input type="password" autoComplete="off" id="Password" onChange={handleInputchange}
                                   required></input>
                            <input type="submit" id="submit" value="Ingresar"></input>

                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}