
import { Table } from 'antd';
import 'antd/dist/antd.css';
import { useSelector } from 'react-redux';




export const DocumentDetailsTable = () => {
    let documentDetails = useSelector(state => state.ui.documentDetails)

    for(let i = 0; i < documentDetails.details.length; i++){
        documentDetails.details[i].productName = documentDetails.details[i].product.name 
        documentDetails.details[i].key = i
    }

    const columns = [
        {
          title: 'Nombre de Producto',
          width: 100,
          fixed: 'left',
          dataIndex: 'productName',
          key: 'productName',
          sorter: (a, b) => a.productName.length - b.productName.length
        },
       
        {
            title: 'Precio',
            width: 90,
            dataIndex: 'price',
            key: 'price',
            sorter: (a, b) => a.price - b.price
        },
        {
            title: 'Unidad de Medida',
            width: 150,
            dataIndex: 'measurementUnit',
            key: 'measurementUnit',
            sorter: (a, b) => a.measurementUnit.length - b.measurementUnit.length
        },
        {
            title: 'Cantidad',
            width: 130,
            dataIndex: 'quantity',
            key: 'quantity',
            sorter: (a, b) => a.quantity - b.quantity,
        },
        {
            title: 'Monto',
            width: 120,
            dataIndex: 'amount',
            key: 'amount',
            sorter: (a, b) => a.amount - b.amount,
        },
        {
            title: 'Descuento',
            width: 130,
            dataIndex: 'discount',
            key: 'discount',
            sorter: (a, b) => a.discount - b.discount
        },
        {
            title: 'Descuento Efectivo',
            width: 140,
            dataIndex: 'effectiveDiscount',
            key: 'effectiveDiscount',
            sorter: (a, b) => a.effectiveDiscount - b.effectiveDiscount
        },
    ];

    return (
        <div>
            <Table
                
                locale={{
                    triggerAsc: 'Click para ordernar ascendente',
                    triggerDesc: 'Click para ordernar descendente',
                    cancelSort: 'Click para cancelar orden',
                }}
                dataSource={documentDetails.details}
                scroll={{ x: 400 }}
                columns={columns}
            />
        </div>
    )
}