import React from "react";

import {
    AiOutlineMobile,
    AiOutlineUser,
    AiOutlineCustomerService,
    AiOutlineFileDone,
    AiOutlineCreditCard,
    AiOutlineQrcode,
    AiOutlineCalculator,
    AiOutlineShoppingCart,
  } from "react-icons/ai";
import { HiOutlineReceiptTax } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { closeCurrentScreen, openScreen } from "../../actions/ui";

export const Dashboard = () => {
  const dispatch = useDispatch();

  const isSidebarActive = useSelector((state) => state.ui.sidebar);

  const itemsGenerales = [
    {
      key: 1,
      items: [
        {
          key: "uiOpenListDevice",
          title: "Dispositivos",
          description: "Administra y visualiza todos los dispositivos conectados al sistema, asegurando el control y monitoreo en tiempo real.",
          class1: "_OCS-devices-card",
          class2: "_devices-card",
          icon: AiOutlineMobile
        },
        {
          key: "uiOpenListUser",
          title: "Usuarios",
          description: "Gestiona la lista de usuarios registrados, permite la creación, edición y eliminación de perfiles de usuario.",
          class1: "_OCS-customers-card",
          class2: "_customers-card",
          icon: AiOutlineUser
        },
        {
          key: "uiOpenListDelivery",
          title: "Deliverys",
          description: "Monitorea y coordina las entregas realizadas, optimizando la logística de los servicios de delivery.",
          class1: "_OCS-users-card",
          class2: "_users-card",
          icon: AiOutlineShoppingCart
        },
      ],
    },
    {
      key: 2,
      items: [
        {
          key: "uiOpenListCustomer",
          title: "Clientes",
          description: "Accede a la información detallada de cada cliente, incluyendo historial de compras y preferencias.",
          class1: "_OCS-devices-card",
          class2: "_devices-card",
          icon: AiOutlineCustomerService
        },
        {
          key: "uiOpenListDocument",
          title: "Documentos",
          description: "Gestiona y organiza todos los documentos importantes del sistema, como facturas y reportes.",
          class1: "_OCS-customers-card",
          class2: "_customers-card",
          icon: AiOutlineFileDone
        },
        {
          key: "uiOpenListNCFRange",
          title: "Rango NCF",
          description: "Controla los rangos de comprobantes fiscales (NCF), asegurando el cumplimiento de normativas fiscales.",
          class1: "_OCS-users-card",
          class2: "_users-card",
          icon: HiOutlineReceiptTax
        },
      ],
    },
    {
      key: 3,
      items: [
        {
          key: "uiOpenListPaymentMethod",
          title: "Mét. de Pago",
          description: "Configura y administra los métodos de pago disponibles para los usuarios, como tarjetas de crédito o transferencias.",
          class1: "_OCS-devices-card",
          class2: "_devices-card",
          icon: AiOutlineCreditCard
        },
        {
          key: "uiOpenListDiscount",
          title: "Descuentos",
          description: "Aplica y gestiona descuentos promocionales, facilitando su implementación en ventas o eventos especiales.",
          class1: "_OCS-customers-card",
          class2: "_customers-card",
          icon: AiOutlineCalculator
        },
        {
          key: "uiOpenListProduct",
          title: "Productos",
          description: "Gestiona el catálogo de productos, incluyendo descripciones, precios y códigos QR para facilitar las ventas.",
          class1: "_OCS-users-card",
          class2: "_users-card",
          icon: AiOutlineQrcode
        },
      ],
    },
  ];

  const handdleOpenScreen = (e) => {
    document.getElementById(e);
    console.log('holaa')
    dispatch(closeCurrentScreen());
    dispatch(openScreen(e.currentTarget.id));
  };

  return (
    <div>
      <div className={isSidebarActive ? "_OCS-overlay" : "_overlay"}></div>
      <div
        className={isSidebarActive ? "_OCS-screen-header" : "_screen-header"}
      ></div>
      <div
        className={isSidebarActive ? "_OCS-ant-card-body" : "_ant-card-body"}
      >
        <div className="grid-container">
        {itemsGenerales.map((a) => (
            <div key={a.key} className="grid-row">
            {a.items.map((b) => {
                return (
                <div
                    key={b.key}
                    className={isSidebarActive ? b.class1 : b.class2}
                    onClick={handdleOpenScreen}
                    id={b.key}
                    style={{cursor: 'pointer'}}
                >
                    <div className="_customer-card-data">
                    <h1 className="_customer-data">{b.title}</h1>
                    {<b.icon size={40} />}
                    </div>
                    <div className="_customers-card-footer">{b.description}</div>
                </div>
                );
            })}
            </div>
        ))}
        </div>

        <div
          className={
            isSidebarActive ? "_OCS-table-responsive" : "_table-responsive"
          }
        ></div>
      </div>
    </div>
  );
};
